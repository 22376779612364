import { useAuth } from 'auth/account';
import BigNumber from 'bignumber.js';
import {
    GFI_CONTRACT_ADDRESS,
    MARKET_CONTRACT_ADDRESS,
    NGL_CONTRACT_ADDRESS,
    MASK_CONTRACT_ADDRESS,
    MASK_BOX_CONTRACT_ADDRESS,
} from 'config';
import { useChain } from 'hooks/ChainContext';
import { useNglDecimalsQuery } from 'queries/ngl';
import { send } from 'queries/relayer';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { gfiContract, marketContract, nglContract, maskContract, maskBoxContract } from './contracts';

export enum NftTypes {
    GFMask = 'GFMask',
    GFMaskBox = 'GFMaskBox',
    GoldFeverItem = 'GoldFeverItem',
    GFCharacter = 'GoldFeverCharacter',
}

export const useCreateListingMutation = (): UseMutationResult<
    { nglAmount: BigNumber.Value; gfiId: string | number } | undefined,
    unknown,
    CreateListingTypes,
    unknown
> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    const { startLoading, stopLoading } = useChain();

    return useMutation(
        async ({ nftId, price, type }: CreateListingTypes) => {
            startLoading('isLoadingMarket');
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            switch (type) {
                case NftTypes.GFMask:
                    await send(
                        wallet,
                        MASK_CONTRACT_ADDRESS,
                        maskContract.methods.approve(MARKET_CONTRACT_ADDRESS, nftId)
                    );
                    await send(
                        wallet,
                        MARKET_CONTRACT_ADDRESS,
                        marketContract.methods.createListing(
                            MASK_CONTRACT_ADDRESS,
                            nftId,
                            decimals?.times(price).toFixed(0)
                        ),
                        'Failed to create listing !'
                    );

                    break;
                case NftTypes.GFMaskBox:
                    await send(
                        wallet,
                        MASK_BOX_CONTRACT_ADDRESS,
                        maskBoxContract.methods.approve(MARKET_CONTRACT_ADDRESS, nftId)
                    );
                    await send(
                        wallet,
                        MARKET_CONTRACT_ADDRESS,
                        marketContract.methods.createListing(
                            MASK_BOX_CONTRACT_ADDRESS,
                            nftId,
                            decimals?.times(price).toFixed(0)
                        ),
                        'Failed to create listing !'
                    );

                    break;

                case NftTypes.GoldFeverItem:
                default:
                    await send(
                        wallet,
                        GFI_CONTRACT_ADDRESS,
                        gfiContract.methods.approve(MARKET_CONTRACT_ADDRESS, nftId)
                    );
                    await send(
                        wallet,
                        MARKET_CONTRACT_ADDRESS,
                        marketContract.methods.createListing(
                            GFI_CONTRACT_ADDRESS,
                            nftId,
                            decimals?.times(price).toFixed(0)
                        ),
                        'Failed to create listing !'
                    );

                    break;
            }

            return { nglAmount: price, gfiId: nftId };
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to create listing !');
            },
            onSuccess: (data) => {
                toast.success(`Listed Item #${data?.gfiId} at ${data?.nglAmount} NGL !`);
            },
            onSettled: () => stopLoading('isLoadingMarket'),
        }
    );
};

type CreateListingTypes = {
    nftId: number | string;
    price: BigNumber.Value;
    type?: NftTypes;
};

export const useCancelListingMutation = (): UseMutationResult<
    { createdListingId: string | number } | undefined,
    unknown,
    CancelListingTypes,
    unknown
> => {
    const { wallet } = useAuth();
    const { startLoading, stopLoading } = useChain();

    return useMutation(
        async ({ listingId }: CancelListingTypes) => {
            startLoading('isLoadingMarket');
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            await send(
                wallet,
                MARKET_CONTRACT_ADDRESS,
                marketContract.methods.cancelListing(listingId),
                'Failed to cancel listing !'
            );

            return { createdListingId: listingId };
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to cancel listing !');
            },
            onSuccess: (data) => {
                toast.success(`Listing #${data?.createdListingId} has been canceled!`);
            },
            onSettled: () => stopLoading('isLoadingMarket'),
        }
    );
};

type CancelListingTypes = {
    listingId: number | string;
};

export const useBuyListingMutation = (): UseMutationResult<unknown, unknown, BuyListingTypes, unknown> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    const { startLoading, stopLoading } = useChain();

    return useMutation(
        async ({ listingId, price }: BuyListingTypes) => {
            startLoading('isLoadingMarket');
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            await send(
                wallet,
                NGL_CONTRACT_ADDRESS,
                nglContract.methods.approve(MARKET_CONTRACT_ADDRESS, decimals?.times(price).toFixed(0))
            );
            await send(
                wallet,
                MARKET_CONTRACT_ADDRESS,
                marketContract.methods.buyListing(listingId),
                'Failed to buy listing !'
            );
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to buy listing !');
            },
            onSuccess: () => {
                toast.success('Listing bought !');
            },
            onSettled: () => stopLoading('isLoadingMarket'),
        }
    );
};

type BuyListingTypes = {
    listingId: string | number;
    price: BigNumber.Value;
};
