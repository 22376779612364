import { backend, BaseResponse } from 'api';
import { AxiosResponse } from 'axios';
import { EstimateGasOptions, formatChainError } from 'queries/utils';
import { useMutation, UseMutationResult } from 'react-query';
import { Account } from 'web3-core';
import { TransactionReceipt } from 'web3-eth';

import { signMetaTxRequest } from './signer';

export interface RelayTransaction {
    account: Account;
    contractAddress: string;
    methodCall: {
        encodeABI(): string;
        estimateGas(options: EstimateGasOptions): Promise<number>;
    };
}

export type RelayTransactionResult = AxiosResponse<BaseResponse<null> & TransactionReceipt> | undefined;

export const useRelayTransactionMutation = (): UseMutationResult<
    RelayTransactionResult,
    unknown,
    RelayTransaction,
    unknown
> => {
    return useMutation(
        async ({ account, contractAddress, methodCall }) => {
            const gas = Math.round(
                1.5 *
                    (await methodCall.estimateGas({
                        from: account.address,
                        to: contractAddress,
                    }))
            );
            const { request, signature } = await signMetaTxRequest(account.privateKey, {
                from: account.address,
                to: contractAddress,
                data: methodCall.encodeABI(),
                gas,
            });
            return backend.post('/crypto/ue-adapter/relay', { request, signature });
        },
        {
            onSuccess: (res) => {
                if (!res?.data.status) {
                    if (res?.data.message) throw Error(res?.data.message);
                    else throw Error('Transaction failed !');
                }
            },
        }
    );
};

export const send = async (
    account: Account,
    contractAddress: string,
    methodCall: {
        encodeABI(): string;
        estimateGas(options: EstimateGasOptions): Promise<number>;
    },
    errorMessage = 'Transaction failed'
): Promise<RelayTransactionResult> => {
    try {
        const gas = await methodCall
            .estimateGas({
                from: account.address,
                to: contractAddress,
            })
            .then((estimatedGas) => Math.round(1.5 * estimatedGas))
            .catch((error) => {
                /**NOTE: although approve success, following txn estimateGas failed by these
                 * May because of delaying in mining approve ? So I make an exception below.
                 */
                if (
                    error.message.includes('ERC721: transfer caller is not owner nor approved') ||
                    error.message.includes('ERC20: transfer amount exceeds allowance')
                )
                    return 1e6;
                else throw error;
            });
        const { request, signature } = await signMetaTxRequest(account.privateKey, {
            from: account.address,
            to: contractAddress,
            data: methodCall.encodeABI(),
            gas,
        });
        const res = await backend.post('/crypto/ue-adapter/relay', { request, signature });
        if (res?.data.status) return res;
        else throw res?.data.message;
    } catch (error) {
        throw Error(formatChainError(error, errorMessage));
    }
};
