import { CButton, CModal, CModalBody, CRow, CLink } from '@coreui/react';
import React from 'react';
import CloseIcon from 'assets/images/closeIcon.png';

interface MerchantPopupProps {
    titleMerchant: string;
    onCancel: () => void;
    onSubmit: () => void;
    content: React.ReactNode;
    isOpen: boolean;
    isLoading?: boolean;
}

const MerchantPopup: React.FC<MerchantPopupProps> = ({
    titleMerchant,
    onCancel,
    onSubmit,
    content,
    isOpen,
    isLoading,
}) => {
    return (
        <CModal show={isOpen} centered /* size="lg" */ closeOnBackdrop={false}>
            <CModalBody className="px-3 signup-modal">
                <CRow className="mt-4 mx-0 justify-content-between">
                    <h5>{titleMerchant}</h5>
                    <button onClick={onCancel} className="bg-none">
                        <img src={CloseIcon} alt="a X icon" />
                    </button>
                </CRow>
                <hr className="mb-2 mt-0" />

                {content}
                <hr className="mt-5" />
                <div className="d-flex justify-content-center">
                    <CLink to="/rights/merchant">
                        <CButton color="primary" onClick={onSubmit} disabled={isLoading}>
                            {isLoading ? (
                                <div
                                    className="spinner-grow text-dark"
                                    role="status"
                                    style={{ width: '20px', height: '20px' }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                'Purchase Merchant'
                            )}
                        </CButton>
                    </CLink>
                </div>
            </CModalBody>
        </CModal>
    );
};

export default MerchantPopup;
