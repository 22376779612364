import { backend, BaseResponse } from 'api';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';

interface WalletUsernameType {
    userName: string;
    walletAddress: string;
}

interface SignTokenVariables {
    signature: string;
}

export const useSignTokenMutation = (): UseMutationResult<
    AxiosResponse<BaseResponse<WalletUsernameType>> | undefined,
    unknown,
    SignTokenVariables,
    unknown
> => {
    return useMutation(
        async (params: SignTokenVariables) => backend.put('/crypto/wallets/updateWalletAddress', params),
        {
            onSuccess: (res) => console.log(res),
            onError: (error) => console.log(error),
        }
    );
};

export const useGetWalletUsername = (
    walletAddress: string
): UseQueryResult<AxiosResponse<BaseResponse<WalletUsernameType>> | undefined, unknown> => {
    return useQuery(
        ['walletUsername', walletAddress],
        async () => backend.get(`/crypto/wallets/walletAddress/${walletAddress}`).catch(() => null),
        {
            onSuccess: (res) => console.log(res),
            onError: (error) => console.log(error),
        }
    );
};
