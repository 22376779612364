import React, { FC, createContext, useContext, useState, useMemo } from 'react';

type IsChainLoadingProps = {
    isLoadingAuction: boolean;
    isLoadingLease: boolean;
    isLoadingMarket: boolean;
    isLoadingMask: boolean;
    isLoadingMiningClaim: boolean;
    isLoadingNft: boolean;
    isLoadingNgl: boolean;
    isLoadingNpc: boolean;
    isLoadingPayments: boolean;
    isLoadingRight: boolean;
    isLoadingCollateral: boolean;
};
interface ChainContextProps extends IsChainLoadingProps {
    startLoading: (type: keyof IsChainLoadingProps) => void;
    stopLoading: (type: keyof IsChainLoadingProps) => void;
}

const IS_LOADING_DEFAULT: IsChainLoadingProps = {
    isLoadingAuction: false,
    isLoadingLease: false,
    isLoadingMarket: false,
    isLoadingMask: false,
    isLoadingMiningClaim: false,
    isLoadingNft: false,
    isLoadingNgl: false,
    isLoadingNpc: false,
    isLoadingPayments: false,
    isLoadingRight: false,
    isLoadingCollateral: false,
};

export const ChainContext = createContext<ChainContextProps>({
    ...IS_LOADING_DEFAULT,
    startLoading: () => null,
    stopLoading: () => null,
});

const ChainProvider: FC = ({ children }) => {
    const [isLoading, setIsLoading] = useState<IsChainLoadingProps>(IS_LOADING_DEFAULT);

    const value = useMemo(
        () => ({
            ...isLoading,
            startLoading: (type: keyof IsChainLoadingProps) =>
                setIsLoading((prevVal) => ({ ...prevVal, [type]: true })),
            stopLoading: (type: keyof IsChainLoadingProps) =>
                setTimeout(() => setIsLoading((prevVal) => ({ ...prevVal, [type]: false })), 3000),
        }),
        [isLoading]
    );

    return <ChainContext.Provider value={value}>{children}</ChainContext.Provider>;
};

export const useChain: () => ChainContextProps = () => useContext(ChainContext);

export default ChainProvider;
