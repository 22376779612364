import { adminApi, backend, BaseResponse } from 'api';
import { useAuth } from 'auth/account';
import { AxiosResponse } from 'axios';
import { NGL_CONTRACT_ADDRESS, RIGHT_CONTRACT_ADDRESS } from 'config';
import { useChain } from 'hooks/ChainContext';
import { useNglDecimalsQuery } from 'queries/ngl';
import { send } from 'queries/relayer';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { Account } from 'web3-core';

import { nglContract, rightContract } from './contracts';

export const usePurchaseRightOptionMutation = (): UseMutationResult<
    { rightOptionId: number | string } | undefined,
    unknown,
    { rightOptionId: number | string; price: number | string; addressBuyer?: Account },
    unknown
> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    const { startLoading, stopLoading } = useChain();

    return useMutation(
        async ({
            rightOptionId,
            price,
            addressBuyer,
        }: {
            rightOptionId: number | string;
            price: number | string;
            addressBuyer?: Account;
        }) => {
            startLoading('isLoadingRight');
            if (!wallet) {
                console.log('Missing Account');
                return;
            }

            await send(
                wallet,
                NGL_CONTRACT_ADDRESS,
                nglContract.methods.approve(RIGHT_CONTRACT_ADDRESS, decimals?.times(price).toFixed(0))
            );
            await send(
                wallet,
                RIGHT_CONTRACT_ADDRESS,
                rightContract.methods.purchaseRight(rightOptionId, addressBuyer?.address),
                'Failed to purchase right !'
            );
            return { rightOptionId };
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to purchase right !');
            },
            onSuccess: (data) => {
                console.log(data);
            },
            onSettled: () => stopLoading('isLoadingRight'),
        }
    );
};

export type FinalizeRightPurchaseTypes = { rightOptionId: number | string; rightPurchaseId: number | string };

export const useFinalizeRightPurchaseMutation = (): UseMutationResult<
    FinalizeRightPurchaseTypes | undefined,
    unknown,
    FinalizeRightPurchaseTypes,
    unknown
> => {
    const { wallet } = useAuth();
    const { startLoading, stopLoading } = useChain();

    return useMutation(
        async ({
            rightOptionId,
            rightPurchaseId,
        }: {
            rightOptionId: number | string;
            rightPurchaseId: number | string;
        }) => {
            startLoading('isLoadingRight');
            if (!wallet) {
                console.log('Missing Account');
                return;
            }

            await send(
                wallet,
                RIGHT_CONTRACT_ADDRESS,
                rightContract.methods.finalizeRightPurchase(rightOptionId, rightPurchaseId),
                'Failed to finalize right !'
            );
            return { rightOptionId, rightPurchaseId };
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to finalize right !');
            },
            onSuccess: (data) => {
                toast.success(`Right purchase ID#${data?.rightPurchaseId} finalized!`);
            },
            onSettled: () => stopLoading('isLoadingRight'),
        }
    );
};

export const useAutoRenewRightMutation = (): UseMutationResult<
    unknown,
    unknown,
    { rightOptionId: number | string },
    unknown
> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    return useMutation(
        async ({ rightOptionId }: { rightOptionId: number | string }) => {
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            await send(
                wallet,
                NGL_CONTRACT_ADDRESS,
                nglContract.methods.approve(RIGHT_CONTRACT_ADDRESS, decimals?.times(2 ** 180 - 1).toFixed(0))
            );
            const res: AxiosResponse<BaseResponse<{ rightPurchaseId: string }>> = await backend.post(
                '/crypto/automaticRenewalRights',
                { rightPurchaseId: rightOptionId }
            );

            if (res?.data.success) return { rightOptionId };
            else throw Error(res?.data.message ? res.data.message : 'Failed to turn on auto renew right !');
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to turn on auto renew right !');
            },
        }
    );
};

export const useOffAutoRenewRightMutation = (): UseMutationResult<
    unknown,
    unknown,
    { orderId: number | string },
    unknown
> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    return useMutation(
        async ({ orderId }: { orderId: number | string }) => {
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            await send(
                wallet,
                NGL_CONTRACT_ADDRESS,
                nglContract.methods.approve(RIGHT_CONTRACT_ADDRESS, decimals?.times(0).toFixed(0))
            );
            const res: AxiosResponse<BaseResponse<unknown>> = await backend.delete(
                `/crypto/automaticRenewalRights/${orderId}`
            );

            if (res?.data.success) return { orderId };
            else throw Error(res?.data.message ? res.data.message : 'Failed to turn off auto renew right !');
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to turn off auto renew right !');
            },
        }
    );
};

export const useGetRenewStatusRightOption = (
    rightOptionId: string | number
): UseQueryResult<{
    isTurnOn: boolean;
    id: string;
}> => {
    return useQuery(['statusAutoRenew', rightOptionId], async () => {
        const res = await backend.get(`/crypto/automaticRenewalRights/isTurnOn/${rightOptionId}`);
        return res.data.data;
    });
};

interface GetRightsParams {
    pageSize?: number;
    page?: number;
}

export const useGetRightsQuery = (params?: GetRightsParams): UseQueryResult<RightData[]> => {
    return useQuery('rights', async () => {
        const res = await adminApi.get('/rights', {
            params,
        });
        return res.data.data;
    });
};

export enum RightCategory {
    DISCOUNT = 'discount',
    MERCHANT = 'merchant',
}

export interface RightData {
    id: string;
    lockNgl: number | null;
    burnNGL: number | null;
    lockTime: number | null;
    created_at: string;
    updated_at: string;
    title: null | string;
    name: string;
    description: RightCategory;
    category: string;
    rightId: string;
    data: Data;
    published_at: string;
    image: Cover;
    cover: Cover;
}

export interface Cover {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: Formats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: null;
    provider: string;
    provider_metadata: null;
    created_at: string;
    updated_at: string;
}

export interface Formats {
    thumbnail: Thumbnail;
}

export interface Thumbnail {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: null;
    size: number;
    width: number;
    height: number;
}

export interface Data {
    attributes: Attribute[];
    limit: any;
}

export interface Attribute {
    id: number;
    value: string;
    trait_type: string;
    display_type: string;
}

export interface RightDataExtended extends RightData {
    rightPurchaseId: string;
    rightOptionId: string;
    price: string;
    status: string;
    duration: string;
    expiry: string;
    rightType: string;
    stakeExpiry: string;
    stakeDuration: string;
}
