import React from 'react';

interface LoadingProps {
    width?: number;
}
const Loading: React.FC<LoadingProps> = ({ width = 30 }) => {
    return (
        <div className="text-center mt-3">
            <div
                className="spinner-grow text-warning"
                role="status"
                style={{ width: `${width}px`, height: `${width}px` }}
            >
                <span className="sr-only" />
            </div>
        </div>
    );
};

export default Loading;
