import { signTypedData as signTypedDataMetamask, SignTypedDataVersion } from '@metamask/eth-sig-util';
import { web3 } from 'queries/web3';
import { forwarderContract } from 'queries/contracts';
import { FORWARDER_CONTRACT_ADDRESS } from 'config';

export interface SignMetaTxData {
    from: string;
    to: string;
    data: string;
    gas: number;
}
export interface SignMetaTxResult {
    signature: string;
    request: {
        from: string;
        to: string;
        data: string;
        value: number;
        gas: number;
        nonce: string;
    };
}

const EIP712Domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
];
const ForwardRequest = [
    { name: 'from', type: 'address' },
    { name: 'to', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'gas', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'data', type: 'bytes' },
];

export const signMetaTxRequest = async (privateKey: string, input: SignMetaTxData): Promise<SignMetaTxResult> => {
    const nonceValue = await forwarderContract.methods
        .getNonce(input.from)
        .call()
        .then((nonce: string | number) => nonce.toString());
    const request = { value: 0, nonce: nonceValue, ...input };

    const chainId = await web3.eth.getChainId();
    const privateKeyBuffer = Buffer.from(privateKey.replace(/^0x/, ''), 'hex');
    const signature = signTypedDataMetamask({
        privateKey: privateKeyBuffer,
        data: {
            types: {
                EIP712Domain,
                ForwardRequest,
            },
            domain: {
                name: 'GoldFeverForwarder',
                version: '0.0.1',
                chainId,
                verifyingContract: FORWARDER_CONTRACT_ADDRESS,
            },
            primaryType: 'ForwardRequest',
            message: request,
        },
        version: SignTypedDataVersion.V4,
    });

    return { signature, request };
};
