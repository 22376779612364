import { formatDuration, fromUnixTime, intervalToDuration } from 'date-fns';

export const POLL_INTERVAL = 3000;
export const BUILDING_TYPE_IDs_WO_BUNGALOW = [
    '181010',
    '181011',
    '181012',
    '181110',
    '181111',
    '181112',
    '181210',
    '181211',
    '181212',
    '181310',
    '181311',
    '181312',
    '181410',
    '181411',
    '181412',
    '181510',
    '181511',
    '181512',
    '181610',
    '181611',
    '181612',
    '181710',
    '181711',
    '181712',
    '181713',
    '181714',
    '181810',
    '181811',
    '181812',
    '181910',
    '181911',
    '181912',
    '182010',
    '182011',
    '182012',
    '182110',
    '182111',
    '182112',
    '182210',
    '182211',
    '182212',
    '182310',
    '182311',
    '182312',
    // Bungalow
    // '182410',
    // '182411',
    // '182412',
    // '182413',
    // '182414',
    '182510',
    '182511',
    '182512',
    '182610',
    '182611',
    '182612',
    '182710',
    '182710',
    '182710',
    '182810',
    '182811',
    '182812',
    '182910',
    '182911',
    '182912',
    '183010',
    '183011',
    '183012',
    '183110',
    '183110',
    '183111',
    '183210',
    '183211',
    '183212',
    '183213',
    '183310',
    '183311',
    '183312',
    '183410',
];

export interface EstimateGasOptions {
    from?: string;
    gas?: number;
    to?: string;
    value?: number | string;
}

export const formatError = (error: string): string | undefined => {
    const regex = /execution reverted: (.*)/;
    const match = error.match(regex);
    if (match) {
        const exec = regex?.exec(error);
        return exec?.pop();
    }
    return error;
};

// NOTE: error messages from BE relayer have many \\u0000, this will to filter them out
export const formatChainError = (error: unknown, defaultMessage?: string): string | undefined => {
    if (error instanceof Error) return error.message.replace(/.*execution reverted: |\\.{5}|u00..|"/g, '');
    else if (typeof error === 'string') return error.replace(/.*execution reverted: |\\.{5}|u00..|"/g, '');
    else return defaultMessage;
};

const formatDistanceLocale: { [key: string]: string } = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
    xDays: '{{count}}d',
    xMonths: '{{count}}m',
    xYears: '{{count}}y',
};

export const shortLocale = {
    formatDistance: (token: string, count: string): string => formatDistanceLocale[token].replace('{{count}}', count),
};

export const formatPeriod = (seconds: number, option?: string[]): string => {
    try {
        return formatDuration(
            intervalToDuration({
                start: new Date(0),
                end: fromUnixTime(seconds),
            }),
            {
                locale: shortLocale,
                format: option,
            }
        );
    } catch (error) {
        return 'Invalid time';
    }
};

export const formatPeriodFromNow = (end: Date, option?: string[]): string => {
    return formatDuration(
        intervalToDuration({
            start: new Date(),
            end,
        }),
        {
            locale: shortLocale,
            format: option,
        }
    );
};

export const formatTransactionType = (transactionType: string): string => {
    switch (transactionType) {
        case 'NGL_RECEIVED':
            return 'NGL received';
        case 'NGL_SENT':
            return 'NGL sent';
        case 'ITEM_SENT':
            return 'Item sent';
        case 'ITEM_RECEIVED':
            return 'Item received';
        case 'MATIC_PURCHASED':
            return 'Matic purchased';
        case 'NGL_PURCHASED':
            return 'NGL purchased';
        default:
            return '';
    }
};

export const formatUTCDate = (date: Date): string => {
    return date.getUTCMonth() + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
};

export const formatUTCTime = (date: Date): string => {
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const hour = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const min = minutes < 10 ? '0' + minutes : minutes;
    const ampm = hours < 12 ? 'AM' : 'PM';
    const time = hour + ':' + min + ' ' + ampm;

    return time;
};

// ARCHIVE - direct call to contract, cost user's gas
// export const send = async (
//     account: Account,
//     contractAddress: string,
//     methodCall: {
//         encodeABI(): string;
//         estimateGas(options: EstimateGasOptions): Promise<number>;
//     }
// ): Promise<TransactionReceipt> => {
//     const hashedTxn = await account.signTransaction({
//         from: account.address,
//         to: contractAddress,
//         data: await methodCall.encodeABI(),
//         gas: Math.round(
//             1.5 *
//                 (await methodCall.estimateGas({
//                     from: account.address,
//                     to: contractAddress,
//                 }))
//         ),
//     });
//     const res = await web3.eth.sendSignedTransaction(hashedTxn.rawTransaction || '');
//     return res;
// };
