import MUMBAI_AUCTION_CONTRACT_ADDRESS from './contracts/GoldFeverAuction.mumbai.address';
import MUMBAI_GFI_CONTRACT_ADDRESS from './contracts/GoldFeverItem.mumbai.address';
import MUMBAI_ITEM_TIER_CONTRACT_ADDRESS from './contracts/GoldFeverItemTier.mumbai.address';
import MUMBAI_LEASING_CONTRACT_ADDRESS from './contracts/GoldFeverLeasing.mumbai.address';
import MUMBAI_MARKET_CONTRACT_ADDRESS from './contracts/GoldFeverMarket.mumbai.address';
import MUMBAI_NGL_CONTRACT_ADDRESS from './contracts/GoldFeverNativeGold.mumbai.address';
import MUMBAI_NPC_CONTRACT_ADDRESS from './contracts/GoldFeverNpc.mumbai.address';
import MUMBAI_RIGHT_CONTRACT_ADDRESS from './contracts/GoldFeverRight.mumbai.address';
import MUMBAI_FORWARDER_CONTRACT_ADDRESS from './contracts/GoldFeverForwarder.mumbai.address';
import MUMBAI_MINING_CLAIM_CONTRACT_ADDRESS from './contracts/GoldFeverMiningClaim.mumbai.address';
import MUMBAI_MASK_CONTRACT_ADDRESS from './contracts/GoldFeverMask.mumbai.address';
import MUMBAI_MASK_BOX_CONTRACT_ADDRESS from './contracts/GoldFeverMaskBox.mumbai.address';
import MUMBAI_CHARACTER_CONTRACT_ADDRESS from './contracts/GoldFeverCharacter.mumbai.address';
import MUMBAI_COLLATERAL_CONTRACT_ADDRESS from './contracts/GoldFeverCollateral.mumbai.address';

export const CHAIN = (process.env.REACT_APP_CHAIN as 'localhost' | 'mumbai' | 'polygon') || 'mumbai';

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL || `https://mumbai-api.goldfever.io`;
export const ADMIN_API_URL: string = process.env.REACT_APP_ADMIN_API_URL || 'https://admin.goldfever.io/';

export const SUBGRAPH_URL =
    CHAIN === 'mumbai'
        ? 'https://api.thegraph.com/subgraphs/name/cinoss/gold-fever-mumbai'
        : 'http://localhost:8000/subgraphs/name/gold-fever-web/gold-fever';
export const RPC_URL =
    CHAIN === 'mumbai'
        ? 'https://rpc-mumbai.maticvigil.com/' // 'https://polygon-mumbai.infura.io/v3/6f37278cbcab469ba8173a29ec48c8b6'
        : 'http://localhost:8545';

const CONTRACT_ADDRESSES =
    CHAIN === 'mumbai'
        ? {
              GFI_CONTRACT_ADDRESS: MUMBAI_GFI_CONTRACT_ADDRESS,
              NGL_CONTRACT_ADDRESS: MUMBAI_NGL_CONTRACT_ADDRESS,
              MARKET_CONTRACT_ADDRESS: MUMBAI_MARKET_CONTRACT_ADDRESS,
              LEASING_CONTRACT_ADDRESS: MUMBAI_LEASING_CONTRACT_ADDRESS,
              NPC_CONTRACT_ADDRESS: MUMBAI_NPC_CONTRACT_ADDRESS,
              ITEM_TIER_CONTRACT_ADDRESS: MUMBAI_ITEM_TIER_CONTRACT_ADDRESS,
              RIGHT_CONTRACT_ADDRESS: MUMBAI_RIGHT_CONTRACT_ADDRESS,
              AUCTION_CONTRACT_ADDRESS: MUMBAI_AUCTION_CONTRACT_ADDRESS,
              FORWARDER_CONTRACT_ADDRESS: MUMBAI_FORWARDER_CONTRACT_ADDRESS,
              MINING_CLAIM_CONTRACT_ADDRESS: MUMBAI_MINING_CLAIM_CONTRACT_ADDRESS,
              MASK_CONTRACT_ADDRESS: MUMBAI_MASK_CONTRACT_ADDRESS,
              MASK_BOX_CONTRACT_ADDRESS: MUMBAI_MASK_BOX_CONTRACT_ADDRESS,
              CHARACTER_CONTRACT_ADDRESS: MUMBAI_CHARACTER_CONTRACT_ADDRESS,
              COLLATERAL_CONTRACT_ADDRESS: MUMBAI_COLLATERAL_CONTRACT_ADDRESS,
          }
        : {
              GFI_CONTRACT_ADDRESS: require('./contracts/GoldFeverItem.localhost.address'),
              LEASING_CONTRACT_ADDRESS: require('./contracts/GoldFeverLeasing.localhost.address'),
              MARKET_CONTRACT_ADDRESS: require('./contracts/GoldFeverMarket.localhost.address'),
              NGL_CONTRACT_ADDRESS: require('./contracts/GoldFeverNativeGold.localhost.address'),
              NPC_CONTRACT_ADDRESS: require('./contracts/GoldFeverNpc.localhost.address'),
              ITEM_TIER_CONTRACT_ADDRESS: require('./contracts/GoldFeverItemTier.localhost.address'),
              RIGHT_CONTRACT_ADDRESS: require('./contracts/GoldFeverRight.localhost.address'),
              AUCTION_CONTRACT_ADDRESS: require('./contracts/GoldFeverAuction.localhost.address'),
              FORWARDER_CONTRACT_ADDRESS: require('./contracts/GoldFeverForwarder.localhost.address'),
              MINING_CLAIM_CONTRACT_ADDRESS: require('./contracts/GoldFeverMiningClaim.localhost.address'),
              MASK_CONTRACT_ADDRESS: require('./contracts/GoldFeverMask.localhost.address'),
              MASK_BOX_CONTRACT_ADDRESS: require('./contracts/GoldFeverMaskBox.localhost.address'),
              CHARACTER_CONTRACT_ADDRESS: require('./contracts/GoldFeverCharacter.localhost.address'),
              COLLATERAL_CONTRACT_ADDRESS: require('./contracts/GoldFeverCollateral.localhost.address'),
          };

export const {
    GFI_CONTRACT_ADDRESS,
    NGL_CONTRACT_ADDRESS,
    MARKET_CONTRACT_ADDRESS,
    LEASING_CONTRACT_ADDRESS,
    NPC_CONTRACT_ADDRESS,
    ITEM_TIER_CONTRACT_ADDRESS,
    RIGHT_CONTRACT_ADDRESS,
    AUCTION_CONTRACT_ADDRESS,
    FORWARDER_CONTRACT_ADDRESS,
    MINING_CLAIM_CONTRACT_ADDRESS,
    MASK_CONTRACT_ADDRESS,
    MASK_BOX_CONTRACT_ADDRESS,
    CHARACTER_CONTRACT_ADDRESS,
    COLLATERAL_CONTRACT_ADDRESS,
} = CONTRACT_ADDRESSES;
