import React, { Component } from 'react';

interface ErrorBoundaryState {
    error: any;
    errorInfo: any; // the error stack
    hasError: boolean;
}

const ERROR_MESSAGE = `We're sorry, but something went wrong.\nWe're working on it.`;

export default class ErrorBoundary extends Component<React.PropsWithChildren<any>, ErrorBoundaryState> {
    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            hasError: false,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log(`Error: ${error}`);
        this.setState({
            errorInfo,
        });
    }

    render() {
        const { hasError } = this.state;
        if (!hasError) {
            return this.props.children;
        }
        return (
            <div
                className="d-flex flex-column w-100 h-100 justify-content-center text-center"
                style={{ backgroundColor: '#0d0908' }}
            >
                <h2>Oops!</h2>
                <div className="mt-5" />
                {ERROR_MESSAGE.split('\n').map((t: string) => {
                    return <p key={t}>{t}</p>;
                })}
            </div>
        );
    }
}
