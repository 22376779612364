import { useAuth } from 'auth/account';
import BigNumber from 'bignumber.js';
import { NGL_CONTRACT_ADDRESS } from 'config';
import { useChain } from 'hooks/ChainContext';
import { send } from 'queries/relayer';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { Account } from 'web3-core';

import { nglContract } from './contracts';

export const useSendNglMutation = (): UseMutationResult<SendNGLTypes | undefined, unknown, SendNGLTypes, unknown> => {
    const { wallet } = useAuth();
    const { data: decimals } = useNglDecimalsQuery();
    const { startLoading, stopLoading } = useChain();
    const queryClient = useQueryClient();

    return useMutation(
        async ({ sendAddress, quantity }: SendNGLTypes) => {
            startLoading('isLoadingNgl');
            if (!decimals) return;
            const nglAmount = new BigNumber(quantity).times(decimals);
            if (!wallet) {
                console.log('Missing Account');
                return;
            }
            await send(
                wallet,
                NGL_CONTRACT_ADDRESS,
                nglContract.methods.transfer(sendAddress, nglAmount.toFixed(0)),
                'Failed to send NGL !'
            );
            return { sendAddress, quantity };
        },
        {
            onError: (error) => {
                if (error instanceof Error) toast.error(error.message);
                else toast.error(typeof error === 'string' ? error : 'Failed to send NGL !');
            },
            onSuccess: () => queryClient.refetchQueries(['nglBalance'], { active: true, exact: true }),
            onSettled: () => stopLoading('isLoadingNgl'),
        }
    );
};

export const useGetNglBalanceQuery = (): UseQueryResult<BigNumber> => {
    const { wallet } = useAuth();
    return useQuery(
        'nglBalance',
        async () => new BigNumber(await nglContract.methods.balanceOf(wallet?.address).call())
    );
};

export const useNglDecimalsQuery = (): UseQueryResult<BigNumber> => {
    return useQuery('nglDecimals', fetchDecimals);
};

const fetchDecimals = async () => {
    const decimals = await nglContract.methods.decimals().call();
    return new BigNumber(10).pow(decimals);
};

export type SendNGLTypes = {
    sendAddress: string;
    quantity: string;
};

export type GetNGLTypes = {
    wallet: Account | undefined;
    decimals: BigNumber;
};
