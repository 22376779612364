// MY INFURA_ID, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
// export const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';

// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
// export const ETHERSCAN_KEY = '';
import { NftTypes } from 'queries/market';
import {
    MASK_CONTRACT_ADDRESS,
    GFI_CONTRACT_ADDRESS,
    MASK_BOX_CONTRACT_ADDRESS,
    CHARACTER_CONTRACT_ADDRESS,
} from 'config';
import { characterContract, gfiContract, maskContract, maskBoxContract } from 'queries/contracts';

export interface Chain {
    name: string;
    chainId: number;
    color: string;
    blockExplorer: string;
    rpcUrl: string;
    price?: number;
    gasPrice?: number;
    faucet?: string;
}

export const NETWORKS: Record<string, Chain> = {
    localhost: {
        name: 'localhost',
        color: '#666666',
        chainId: 31337,
        blockExplorer: '',
        rpcUrl: 'http://' + window.location.hostname + ':8545',
    },
    polygon: {
        name: 'polygon',
        color: '#2bbdf7',
        chainId: 137,
        price: 1,
        gasPrice: 1000000000,
        rpcUrl: 'https://rpc-mainnet.maticvigil.com',
        faucet: 'https://faucet.matic.network/',
        blockExplorer: 'https://www.polygonscan.com/',
    },
    mumbai: {
        name: 'mumbai',
        color: '#92D9FA',
        chainId: 80001,
        price: 1,
        gasPrice: 1000000000,
        rpcUrl: 'https://rpc-mumbai.maticvigil.com',
        faucet: 'https://faucet.matic.network/',
        blockExplorer: 'https://mumbai.polygonscan.com/',
    },
};

export const NETWORK = (chainId: number) => {
    for (const n in NETWORKS) {
        if (NETWORKS[n].chainId === chainId) {
            return NETWORKS[n];
        }
    }
};

export const COORDINATES: any = {
    '20793377': 'A3A3',
    '20793393': 'A7A4A8A4A7A5A8A5',
    '20794977': 'A2A2',
    '20796577': 'A5A5',
    '20798177': 'A4A5',
    '37570593': 'A4A3',
    '37570609': 'A2B2A3B2A2B3A3B3',
    '37572193': 'A3A2',
    '37573793': 'A6A5',
    '37575393': 'A5A8',
    '54347809': 'A4A4',
    '54349409': 'A4A8',
    '54351009': 'A4A6',
    '54352609': 'A6A8',
    '71125025': 'A3A5',
    '71126625': 'A8A9',
    '71128225': 'A6A6',
    '71129825': 'A6A7',
    '87902241': 'A2A5',
    '87905441': 'A5A6',
    '104679457': 'A7A6',
    '104682657': 'A5A7',
    '121456673': 'A7A7',
    '121459873': 'A7A8',
    '138233889': 'A2B1',
    '138237089': 'A5A9',
    '155011105': 'A4B1',
    '155014305': 'A6A9',
    '171788321': 'A4B2',
};

export const BUILDING_BASE: Record<string, string> = {
    '19673377': 'Adventurer Base 3',
    '19673393': 'Adventurer Base 1',
    '19673409': 'Adventurer Base 2',
    '19674977': 'Adventurer Base 3',
    '19674993': 'Adventurer Base 2',
    '19675009': 'Adventurer Base 1',
    '19676577': 'Adventurer Base 1',
    '19676593': 'Adventurer Base 2',
    '19678177': 'Adventurer Base 2',
    '19678193': 'Adventurer Base 1',
    '19679777': 'Adventurer Base 3',
    '19679793': 'Adventurer Base 1',
    '19681377': 'Adventurer Base 1',
    '19681393': 'Adventurer Base 3',
    '19682993': 'Adventurer Base 1',
    '19683009': 'Adventurer Base 2',
    '19684577': 'Adventurer Base 3',
    '19684593': 'Adventurer Base 1',
    '19686177': 'Adventurer Base 1',
    '19686193': 'Adventurer Base 2',
    '19687777': 'Adventurer Base 1',
    '19689377': 'Adventurer Base 2',
    '19689393': 'Adventurer Base 1',
    '19690977': 'Adventurer Base 3',
    '19690993': 'Adventurer Base 1',
    '19692577': 'Adventurer Base 2',
    '19692593': 'Adventurer Base 1',
    '19694177': 'Adventurer Base 1',
    '19695777': 'Adventurer Base 2',
    '19695793': 'Adventurer Base 1',
    '19695809': 'Adventurer Base 1',
    '19695841': 'Adventurer Base 1',
    '19697377': 'Governor Base 1',
    '19698977': 'Adventurer Base 2',
    '19700577': 'Governor Base 1',
    '19702177': 'Adventurer Base 1',
    '19702193': 'Adventurer Base 2',
    '19703777': 'Adventurer Base 1',
    '19705377': 'Adventurer Base 1',
    '19705393': 'Adventurer Base 3',
    '19705409': 'Adventurer Base 2',
    '19706977': 'Governor Base 1',
    '19708577': 'Governor Base 1',
    '36453793': 'Adventurer Base 3',
    '36455409': 'Adventurer Base 3',
    '36457009': 'Adventurer Base 2',
    '36458593': 'Adventurer Base 2',
    '36460209': 'Adventurer Base 3',
    '36461809': 'Adventurer Base 2',
    '36463393': 'Adventurer Base 3',
    '36464993': 'Adventurer Base 2',
    '36466609': 'Adventurer Base 3',
    '36468209': 'Adventurer Base 2',
    '36469809': 'Adventurer Base 3',
    '36471393': 'Adventurer Base 2',
    '36472993': 'Adventurer Base 2',
    '36473009': 'Adventurer Base 1',
    '36473025': 'Adventurer Base 1',
    '36473057': 'Adventurer Base 2',
    '36476193': 'Adventurer Base 3',
    '36479393': 'Adventurer Base 3',
    '36480993': 'Adventurer Base 3',
    '53242209': 'Adventurer Base 3',
    '53248609': 'Adventurer Base 3',
    '53250209': 'Adventurer Base 3',
    '53250225': 'Adventurer Base 1',
    '53250241': 'Adventurer Base 1',
    '53250273': 'Adventurer Base 2',
    '53253409': 'Adventurer Base 1',
    '53258209': 'Adventurer Base 2',
    '70027425': 'Adventurer Base 3',
    '70027441': 'Adventurer Base 1',
    '70027457': 'Adventurer Base 1',
    '70027489': 'Adventurer Base 2',
    '86804641': 'Adventurer Base 3',
    '86804657': 'Adventurer Base 1',
    '86804673': 'Adventurer Base 2',
    '86804705': 'Adventurer Base 2',
    '103581857': 'Adventurer Base 3',
    '103581873': 'Tribal Base 2',
    '103581889': 'Adventurer Base 2',
    '103581921': 'Adventurer Base 3',
    '120359073': 'Adventurer Base 3',
    '120359089': 'Adventurer Base 2',
    '120359105': 'Adventurer Base 2',
    '120359137': 'Adventurer Base 3',
    '137136289': 'Adventurer Base 3',
    '137136305': 'Adventurer Base 2',
    '137136321': 'Adventurer Base 3',
    '137136353': 'Adventurer Base 3',
    '153913505': 'Adventurer Base 3',
    '153913521': 'Adventurer Base 2',
    '153913537': 'Adventurer Base 3',
    '153913569': 'Adventurer Base 3',
    '170690737': 'Adventurer Base 2',
    '170690753': 'Adventurer Base 3',
    '170690785': 'Adventurer Base 3',
    '187467953': 'Adventurer Base 2',
    '187467969': 'Adventurer Base 3',
    '187468001': 'Adventurer Base 3',
    '204245169': 'Adventurer Base 3',
    '204245185': 'Adventurer Base 3',
    '204245217': 'Adventurer Base 2',
    '221022385': 'Adventurer Base 3',
    '221022401': 'Adventurer Base 3',
    '237799601': 'Adventurer Base 3',
    '237799617': 'Adventurer Base 3',
    '254576817': 'Adventurer Base 3',
    '254576833': 'Adventurer Base 1',
    '271354033': 'Adventurer Base 3',
    '271354049': 'Adventurer Base 1',
    '288131249': 'Adventurer Base 3',
    '288131265': 'Adventurer Base 1',
    '304908465': 'Adventurer Base 3',
    '304908481': 'Adventurer Base 1',
    '321685681': 'Adventurer Base 3',
    '321685697': 'Adventurer Base 2',
    '338462897': 'Adventurer Base 3',
    '338462913': 'Adventurer Base 3',
    '355240113': 'Adventurer Base 3',
    '355240129': 'Adventurer Base 3',
    '372017329': 'Adventurer Base 3',
    '372017345': 'Adventurer Base 3',
    '388794545': 'Adventurer Base 3',
    '388794561': 'Adventurer Base 3',
    '405571777': 'Adventurer Base 3',
    '422348993': 'Adventurer Base 3',
    '439126209': 'Adventurer Base 3',
};

export const RIGHT_DISCOUNT_IDS = ['201', '202', '203', '204', '205', '206'];
export const RIGHT_MERCHANT_FIRST_IDS = ['501', '502', '503'];
export const RIGHT_MERCHANT_SECOND_IDS = ['504', '505', '506'];

export const DEFAULT_MERCHANT_RIGHT_LIMIT = {
    companion: 1,
    donkey: 1,
    horse: 1,
    dog: 1,
    boatAndPlane: 1,
    boat: 1,
    plane: 1,
    building: 1,
    similarBuilding: 1,
    sameBaseBuilding: 1,
};

export const COMPANION_TYPE_ID = 17;
export const BUILDING_TYPE_ID = 18;
export const BOAT_TYPE_ID = 20;
export const PLAN_TYPE_ID = 19;
export const HORSE_TYPE_ID = 1710;
export const DONKEY_TYPE_ID = 1711;
export const DOG_TYPE_ID = 1712;
export const BUNGALOW_TYPE_ID = 1824;

export const DATA_TYPE_ID_LIMIT = [
    { id: COMPANION_TYPE_ID, name: 'companion' },
    { id: BUILDING_TYPE_ID, name: 'building' },
    { id: PLAN_TYPE_ID, name: 'plane' },
    { id: BOAT_TYPE_ID, name: 'boat' },
];

export const boatAndPlaneIds = ['19', '20'];

export const companionIds = ['17'];

export const buildingIds = ['18'];

export const checkItemTypeLimit = (typeId: string): string => {
    const categoryId = Math.floor(Number(typeId) / 10000);
    const type = DATA_TYPE_ID_LIMIT.find((item) => item.id === categoryId);
    if (type) {
        return type.name;
    }
    return 'unknown';
};

export const variantCompanion = (typeId: string): string => {
    const variantId = Math.floor(Number(typeId) / 100);
    if (HORSE_TYPE_ID === variantId) {
        return 'horse';
    }
    if (DONKEY_TYPE_ID === variantId) {
        return 'donkey';
    }
    if (DOG_TYPE_ID === variantId) {
        return 'dog';
    }
    return 'unknown';
};

// Building Services: BUILDING_EXTRA_SERVICES[BUILDINGS_WITH_EXTRA_SERVICES.indexOf('Airfield')]
export const BUILDINGS_WITH_EXTRA_SERVICES = [
    'Airfield',
    'Bank',
    'Bar',
    'Blacksmith',
    'Butcher Shop',
    'Carpentry',
    'Docks',
    'Kennels',
    'Donkey Stable',
    'Fruit Market',
    'Tools Workshop',
    'Horse Stable',
    'Hotel',
    'Survival Market',
    'Distillery',
    'Hospital',
    'Watermill',
    'Tailoring Workshop',
    'Instrument Workshop',
    'Weapon Workshop',
    'Mine',
    'Crop Farm',
    'Livestock Farm',
];

export const BUILDING_EXTRA_SERVICES: BuildingExtraServices[] = [
    //'Airfield'
    {
        crafting: false,
        training: true,
        services: true,
        custody: true,
        maintenance: true,
        ownerSlots: true,
        service_b: 'Maintenance',
    },
    //'Bank'
    { crafting: false, training: false, services: true, custody: false, maintenance: false, service_b: 'Vault' },
    //'Bar'
    { crafting: true, training: true, services: true, custody: false, maintenance: false },
    //'Blacksmith'
    { crafting: true, training: false, services: false, custody: false, maintenance: false },
    //'Butcher Shop'
    {
        crafting: true,
        training: true,
        services: false,
        custody: false,
        maintenance: false,
        crafting_b: 'Carcass processing',
    },
    //'Carpentry'
    {
        crafting: true,
        training: true,
        services: false,
        custody: false,
        maintenance: false,
        crafting_b: 'Log processing',
    },
    //'Docks'
    { crafting: true, training: true, services: true, custody: true, maintenance: true, ownerSlots: true },
    //'Kennels'
    { crafting: true, training: true, services: true, custody: true, maintenance: false, ownerSlots: true },
    //'Donkey Stable'
    { crafting: true, training: true, services: true, custody: true, maintenance: false, ownerSlots: true },
    //'Fruit Market'
    { crafting: true, training: true, services: false, custody: false, maintenance: false },
    //'Tools Workshop'
    { crafting: true, training: true, services: false, custody: true, maintenance: false },
    //'Horse Stable'
    { crafting: true, training: true, services: true, custody: true, maintenance: false, ownerSlots: true },
    //'Hotel'
    { crafting: true, training: false, services: true, custody: false, maintenance: false, service_b: 'Storage' },
    //'Survival Market'
    { crafting: true, training: false, services: false, custody: true, maintenance: false },
    //'Distillery'
    { crafting: true, training: false, services: false, custody: false, maintenance: false },
    //'Hospital'
    { crafting: true, training: true, services: true, custody: false, maintenance: false },
    //'Watermill'
    { crafting: true, training: false, services: false, custody: false, maintenance: false },
    //'Tailoring Workshop'
    { crafting: true, training: true, services: false, custody: true, maintenance: true },
    //'Instrument Workshop'
    { crafting: true, training: true, services: false, custody: true, maintenance: false },
    //'Weapon Workshop'
    { crafting: true, training: true, services: true, custody: true, maintenance: true },
    //'Mine'
    { crafting: true, training: true, services: false, custody: false, maintenance: false, crafting_b: 'Refinement' },
    //'Crop Farm'
    { crafting: true, training: false, services: false, custody: false, maintenance: false },
    //'Livestock Farm'
    { crafting: true, training: false, services: false, custody: false, maintenance: false },
];

// TODO: is 'maintenance' different category or a tab of services ?
export type BuildingExtraServices = {
    crafting: boolean; // Crafting
    training: boolean; // Training
    services: boolean; // only bank will be Trading instead of Services
    custody: boolean; // true ~ has guestSlots but don't know if ownerSlots available

    maintenance: boolean;

    ownerSlots?: boolean;
    crafting_b?: 'Log processing' | 'Carcass processing' | 'Refinement';
    service_b?: 'Maintenance' | 'Storage' | 'Vault';
};

export const getItemNFTType = (id: string): NftTypes => {
    const address = id.split('/')[0];
    switch (address) {
        case MASK_CONTRACT_ADDRESS.toLowerCase():
            return NftTypes.GFMask;
        case MASK_BOX_CONTRACT_ADDRESS.toLowerCase():
            return NftTypes.GFMaskBox;
        case GFI_CONTRACT_ADDRESS.toLowerCase():
            return NftTypes.GoldFeverItem;
        case CHARACTER_CONTRACT_ADDRESS.toLowerCase():
            return NftTypes.GFCharacter;

        default:
            return NftTypes.GoldFeverItem;
    }
};

export const getItemNFTContractAddress = (nftType: NftTypes): string => {
    switch (nftType) {
        case NftTypes.GFMask:
            return MASK_CONTRACT_ADDRESS;
        case NftTypes.GFMaskBox:
            return MASK_BOX_CONTRACT_ADDRESS;
        case NftTypes.GoldFeverItem:
            return GFI_CONTRACT_ADDRESS;
        case NftTypes.GFCharacter:
            return CHARACTER_CONTRACT_ADDRESS;

        default:
            return GFI_CONTRACT_ADDRESS;
    }
};

export const getItemNFTContract = (nftType: NftTypes) => {
    switch (nftType) {
        case NftTypes.GFMask:
            return maskContract;
        case NftTypes.GFMaskBox:
            return maskBoxContract;
        case NftTypes.GoldFeverItem:
            return gfiContract;
        case NftTypes.GFCharacter:
            return characterContract;

        default:
            return gfiContract;
    }
};
