import {
    GFI_CONTRACT_ADDRESS,
    LEASING_CONTRACT_ADDRESS,
    MARKET_CONTRACT_ADDRESS,
    NGL_CONTRACT_ADDRESS,
    NPC_CONTRACT_ADDRESS,
    ITEM_TIER_CONTRACT_ADDRESS,
    RIGHT_CONTRACT_ADDRESS,
    AUCTION_CONTRACT_ADDRESS,
    FORWARDER_CONTRACT_ADDRESS,
    MINING_CLAIM_CONTRACT_ADDRESS,
    MASK_CONTRACT_ADDRESS,
    MASK_BOX_CONTRACT_ADDRESS,
    CHARACTER_CONTRACT_ADDRESS,
    COLLATERAL_CONTRACT_ADDRESS,
} from 'config';
import GoldFeverItemAbi from 'contracts/GoldFeverItem.abi';
import GoldFeverLeasingAbi from 'contracts/GoldFeverLeasing.abi';
import GoldFeverMarketAbi from 'contracts/GoldFeverMarket.abi';
import GoldFeverNativeGoldAbi from 'contracts/GoldFeverNativeGold.abi';
import GoldFeverNpcAbi from 'contracts/GoldFeverNpc.abi';
import GoldFeverItemTierAbi from 'contracts/GoldFeverItemTier.abi';
import GoldFeverRightAbi from 'contracts/GoldFeverRight.abi';
import GoldFeverAuctionAbi from 'contracts/GoldFeverAuction.abi';
import GoldFeverForwarderAbi from 'contracts/GoldFeverForwarder.abi';
import GoldFeverMiningClaimAbi from 'contracts/GoldFeverMiningClaim.abi';
import GoldFeverMaskBoxAbi from 'contracts/GoldFeverMaskBox.abi';
import GoldFeverMaskAbi from 'contracts/GoldFeverMask.abi';
import GoldFeverCharacterAbi from 'contracts/GoldFeverCharacter.abi';
import { AbiItem } from 'web3-utils';
import { web3 } from './web3';
import GoldFeverCollateralAbi from 'contracts/GoldFeverCollateral.abi';

export const gfiContract = new web3.eth.Contract(GoldFeverItemAbi as AbiItem[], GFI_CONTRACT_ADDRESS);
export const nglContract = new web3.eth.Contract(GoldFeverNativeGoldAbi as AbiItem[], NGL_CONTRACT_ADDRESS);
export const marketContract = new web3.eth.Contract(GoldFeverMarketAbi as AbiItem[], MARKET_CONTRACT_ADDRESS);
export const leasingContract = new web3.eth.Contract(GoldFeverLeasingAbi as AbiItem[], LEASING_CONTRACT_ADDRESS);
export const npcContract = new web3.eth.Contract(GoldFeverNpcAbi as AbiItem[], NPC_CONTRACT_ADDRESS);
export const itemTierContract = new web3.eth.Contract(GoldFeverItemTierAbi as AbiItem[], ITEM_TIER_CONTRACT_ADDRESS);
export const rightContract = new web3.eth.Contract(GoldFeverRightAbi as AbiItem[], RIGHT_CONTRACT_ADDRESS);
export const auctionContract = new web3.eth.Contract(GoldFeverAuctionAbi as AbiItem[], AUCTION_CONTRACT_ADDRESS);
export const forwarderContract = new web3.eth.Contract(GoldFeverForwarderAbi as AbiItem[], FORWARDER_CONTRACT_ADDRESS);
export const miningClaimContract = new web3.eth.Contract(
    GoldFeverMiningClaimAbi as AbiItem[],
    MINING_CLAIM_CONTRACT_ADDRESS
);
export const maskContract = new web3.eth.Contract(GoldFeverMaskAbi as AbiItem[], MASK_CONTRACT_ADDRESS);
export const maskBoxContract = new web3.eth.Contract(GoldFeverMaskBoxAbi as AbiItem[], MASK_BOX_CONTRACT_ADDRESS);

export const characterContract = new web3.eth.Contract(GoldFeverCharacterAbi as AbiItem[], CHARACTER_CONTRACT_ADDRESS);

export const collateralContract = new web3.eth.Contract(
    GoldFeverCollateralAbi as AbiItem[],
    COLLATERAL_CONTRACT_ADDRESS
);
