import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type Account = {
  __typename?: 'Account';
  ERC20approvalsOwner: Array<Erc20Approval>;
  ERC20approvalsSpender: Array<Erc20Approval>;
  ERC20balances: Array<Erc20Balance>;
  ERC20transferFromEvent: Array<Erc20Transfer>;
  ERC20transferToEvent: Array<Erc20Transfer>;
  ERC721operatorOperator: Array<Maybe<Erc721Operator>>;
  ERC721operatorOwner: Array<Maybe<Erc721Operator>>;
  ERC721tokens: Array<Maybe<Erc721Token>>;
  ERC721transferFromEvent: Array<Maybe<Erc721Transfer>>;
  ERC721transferToEvent: Array<Maybe<Erc721Transfer>>;
  asERC20?: Maybe<Erc20Contract>;
  asERC721?: Maybe<Erc721Contract>;
  attachItems: Array<AttachItem>;
  auctions: Array<Auction>;
  characters: Array<Character>;
  collateralOffer: Array<CollateralOffer>;
  hirings: Array<Hiring>;
  id: Scalars['ID'];
  inboundOffers: Array<Offer>;
  leases: Array<Lease>;
  listings: Array<Listing>;
  maskBoxes: Array<MaskBox>;
  masks: Array<Mask>;
  miningClaims: Array<MiningClaim>;
  outboundOffers: Array<Offer>;
  purchases: Array<Listing>;
  rentableItems: Array<RentableItem>;
  rents: Array<Lease>;
  rightPurchases: Array<RightPurchase>;
};


export type AccountErc20approvalsOwnerArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Approval_Filter>;
};


export type AccountErc20approvalsSpenderArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Approval_Filter>;
};


export type AccountErc20balancesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Balance_Filter>;
};


export type AccountErc20transferFromEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};


export type AccountErc20transferToEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};


export type AccountErc721operatorOperatorArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Operator_Filter>;
};


export type AccountErc721operatorOwnerArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Operator_Filter>;
};


export type AccountErc721tokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Token_Filter>;
};


export type AccountErc721transferFromEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};


export type AccountErc721transferToEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};


export type AccountAttachItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttachItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttachItem_Filter>;
};


export type AccountAuctionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Auction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Auction_Filter>;
};


export type AccountCharactersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Character_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Character_Filter>;
};


export type AccountCollateralOfferArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollateralOffer_Filter>;
};


export type AccountHiringsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Hiring_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Hiring_Filter>;
};


export type AccountInboundOffersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Offer_Filter>;
};


export type AccountLeasesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Lease_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Lease_Filter>;
};


export type AccountListingsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Listing_Filter>;
};


export type AccountMaskBoxesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MaskBox_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaskBox_Filter>;
};


export type AccountMasksArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Mask_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Mask_Filter>;
};


export type AccountMiningClaimsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MiningClaim_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MiningClaim_Filter>;
};


export type AccountOutboundOffersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Offer_Filter>;
};


export type AccountPurchasesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Listing_Filter>;
};


export type AccountRentableItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RentableItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentableItem_Filter>;
};


export type AccountRentsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Lease_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Lease_Filter>;
};


export type AccountRightPurchasesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightPurchase_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RightPurchase_Filter>;
};

export type Account_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  asERC20?: InputMaybe<Scalars['String']>;
  asERC20_contains?: InputMaybe<Scalars['String']>;
  asERC20_contains_nocase?: InputMaybe<Scalars['String']>;
  asERC20_ends_with?: InputMaybe<Scalars['String']>;
  asERC20_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asERC20_gt?: InputMaybe<Scalars['String']>;
  asERC20_gte?: InputMaybe<Scalars['String']>;
  asERC20_in?: InputMaybe<Array<Scalars['String']>>;
  asERC20_lt?: InputMaybe<Scalars['String']>;
  asERC20_lte?: InputMaybe<Scalars['String']>;
  asERC20_not?: InputMaybe<Scalars['String']>;
  asERC20_not_contains?: InputMaybe<Scalars['String']>;
  asERC20_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asERC20_not_ends_with?: InputMaybe<Scalars['String']>;
  asERC20_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asERC20_not_in?: InputMaybe<Array<Scalars['String']>>;
  asERC20_not_starts_with?: InputMaybe<Scalars['String']>;
  asERC20_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asERC20_starts_with?: InputMaybe<Scalars['String']>;
  asERC20_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asERC721?: InputMaybe<Scalars['String']>;
  asERC721_contains?: InputMaybe<Scalars['String']>;
  asERC721_contains_nocase?: InputMaybe<Scalars['String']>;
  asERC721_ends_with?: InputMaybe<Scalars['String']>;
  asERC721_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asERC721_gt?: InputMaybe<Scalars['String']>;
  asERC721_gte?: InputMaybe<Scalars['String']>;
  asERC721_in?: InputMaybe<Array<Scalars['String']>>;
  asERC721_lt?: InputMaybe<Scalars['String']>;
  asERC721_lte?: InputMaybe<Scalars['String']>;
  asERC721_not?: InputMaybe<Scalars['String']>;
  asERC721_not_contains?: InputMaybe<Scalars['String']>;
  asERC721_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asERC721_not_ends_with?: InputMaybe<Scalars['String']>;
  asERC721_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asERC721_not_in?: InputMaybe<Array<Scalars['String']>>;
  asERC721_not_starts_with?: InputMaybe<Scalars['String']>;
  asERC721_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asERC721_starts_with?: InputMaybe<Scalars['String']>;
  asERC721_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
};

export enum Account_OrderBy {
  Erc20approvalsOwner = 'ERC20approvalsOwner',
  Erc20approvalsSpender = 'ERC20approvalsSpender',
  Erc20balances = 'ERC20balances',
  Erc20transferFromEvent = 'ERC20transferFromEvent',
  Erc20transferToEvent = 'ERC20transferToEvent',
  Erc721operatorOperator = 'ERC721operatorOperator',
  Erc721operatorOwner = 'ERC721operatorOwner',
  Erc721tokens = 'ERC721tokens',
  Erc721transferFromEvent = 'ERC721transferFromEvent',
  Erc721transferToEvent = 'ERC721transferToEvent',
  AsErc20 = 'asERC20',
  AsErc721 = 'asERC721',
  AttachItems = 'attachItems',
  Auctions = 'auctions',
  Characters = 'characters',
  CollateralOffer = 'collateralOffer',
  Hirings = 'hirings',
  Id = 'id',
  InboundOffers = 'inboundOffers',
  Leases = 'leases',
  Listings = 'listings',
  MaskBoxes = 'maskBoxes',
  Masks = 'masks',
  MiningClaims = 'miningClaims',
  OutboundOffers = 'outboundOffers',
  Purchases = 'purchases',
  RentableItems = 'rentableItems',
  Rents = 'rents',
  RightPurchases = 'rightPurchases'
}

export type Arena = {
  __typename?: 'Arena';
  arenaId: Scalars['BigInt'];
  commissionRate: Scalars['BigInt'];
  duration: Scalars['BigInt'];
  expiry: Scalars['BigInt'];
  id: Scalars['ID'];
  miners: Array<Miner>;
  miningClaim: MiningClaim;
  numMinersInArena: Scalars['BigInt'];
  status: ArenaStatus;
  upfrontFee: Scalars['BigInt'];
};


export type ArenaMinersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Miner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Miner_Filter>;
};

export enum ArenaStatus {
  Closed = 'CLOSED',
  Started = 'STARTED'
}

export type Arena_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  arenaId?: InputMaybe<Scalars['BigInt']>;
  arenaId_gt?: InputMaybe<Scalars['BigInt']>;
  arenaId_gte?: InputMaybe<Scalars['BigInt']>;
  arenaId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  arenaId_lt?: InputMaybe<Scalars['BigInt']>;
  arenaId_lte?: InputMaybe<Scalars['BigInt']>;
  arenaId_not?: InputMaybe<Scalars['BigInt']>;
  arenaId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  commissionRate?: InputMaybe<Scalars['BigInt']>;
  commissionRate_gt?: InputMaybe<Scalars['BigInt']>;
  commissionRate_gte?: InputMaybe<Scalars['BigInt']>;
  commissionRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  commissionRate_lt?: InputMaybe<Scalars['BigInt']>;
  commissionRate_lte?: InputMaybe<Scalars['BigInt']>;
  commissionRate_not?: InputMaybe<Scalars['BigInt']>;
  commissionRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry?: InputMaybe<Scalars['BigInt']>;
  expiry_gt?: InputMaybe<Scalars['BigInt']>;
  expiry_gte?: InputMaybe<Scalars['BigInt']>;
  expiry_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry_lt?: InputMaybe<Scalars['BigInt']>;
  expiry_lte?: InputMaybe<Scalars['BigInt']>;
  expiry_not?: InputMaybe<Scalars['BigInt']>;
  expiry_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  miningClaim?: InputMaybe<Scalars['String']>;
  miningClaim_contains?: InputMaybe<Scalars['String']>;
  miningClaim_contains_nocase?: InputMaybe<Scalars['String']>;
  miningClaim_ends_with?: InputMaybe<Scalars['String']>;
  miningClaim_ends_with_nocase?: InputMaybe<Scalars['String']>;
  miningClaim_gt?: InputMaybe<Scalars['String']>;
  miningClaim_gte?: InputMaybe<Scalars['String']>;
  miningClaim_in?: InputMaybe<Array<Scalars['String']>>;
  miningClaim_lt?: InputMaybe<Scalars['String']>;
  miningClaim_lte?: InputMaybe<Scalars['String']>;
  miningClaim_not?: InputMaybe<Scalars['String']>;
  miningClaim_not_contains?: InputMaybe<Scalars['String']>;
  miningClaim_not_contains_nocase?: InputMaybe<Scalars['String']>;
  miningClaim_not_ends_with?: InputMaybe<Scalars['String']>;
  miningClaim_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  miningClaim_not_in?: InputMaybe<Array<Scalars['String']>>;
  miningClaim_not_starts_with?: InputMaybe<Scalars['String']>;
  miningClaim_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  miningClaim_starts_with?: InputMaybe<Scalars['String']>;
  miningClaim_starts_with_nocase?: InputMaybe<Scalars['String']>;
  numMinersInArena?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_gt?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_gte?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numMinersInArena_lt?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_lte?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_not?: InputMaybe<Scalars['BigInt']>;
  numMinersInArena_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<ArenaStatus>;
  status_in?: InputMaybe<Array<ArenaStatus>>;
  status_not?: InputMaybe<ArenaStatus>;
  status_not_in?: InputMaybe<Array<ArenaStatus>>;
  upfrontFee?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_gt?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_gte?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  upfrontFee_lt?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_lte?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_not?: InputMaybe<Scalars['BigInt']>;
  upfrontFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Arena_OrderBy {
  ArenaId = 'arenaId',
  CommissionRate = 'commissionRate',
  Duration = 'duration',
  Expiry = 'expiry',
  Id = 'id',
  Miners = 'miners',
  MiningClaim = 'miningClaim',
  NumMinersInArena = 'numMinersInArena',
  Status = 'status',
  UpfrontFee = 'upfrontFee'
}

export type AttachItem = {
  __typename?: 'AttachItem';
  characterId: Scalars['BigInt'];
  id: Scalars['ID'];
  owner: Account;
  status: Scalars['String'];
  token: Erc721Token;
};

export type AttachItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  characterId?: InputMaybe<Scalars['BigInt']>;
  characterId_gt?: InputMaybe<Scalars['BigInt']>;
  characterId_gte?: InputMaybe<Scalars['BigInt']>;
  characterId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  characterId_lt?: InputMaybe<Scalars['BigInt']>;
  characterId_lte?: InputMaybe<Scalars['BigInt']>;
  characterId_not?: InputMaybe<Scalars['BigInt']>;
  characterId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_contains?: InputMaybe<Scalars['String']>;
  status_contains_nocase?: InputMaybe<Scalars['String']>;
  status_ends_with?: InputMaybe<Scalars['String']>;
  status_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_gt?: InputMaybe<Scalars['String']>;
  status_gte?: InputMaybe<Scalars['String']>;
  status_in?: InputMaybe<Array<Scalars['String']>>;
  status_lt?: InputMaybe<Scalars['String']>;
  status_lte?: InputMaybe<Scalars['String']>;
  status_not?: InputMaybe<Scalars['String']>;
  status_not_contains?: InputMaybe<Scalars['String']>;
  status_not_contains_nocase?: InputMaybe<Scalars['String']>;
  status_not_ends_with?: InputMaybe<Scalars['String']>;
  status_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_not_in?: InputMaybe<Array<Scalars['String']>>;
  status_not_starts_with?: InputMaybe<Scalars['String']>;
  status_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status_starts_with?: InputMaybe<Scalars['String']>;
  status_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum AttachItem_OrderBy {
  CharacterId = 'characterId',
  Id = 'id',
  Owner = 'owner',
  Status = 'status',
  Token = 'token'
}

export type Auction = {
  __typename?: 'Auction';
  biddingStep: Scalars['BigInt'];
  endDate: Scalars['BigInt'];
  highestBidder?: Maybe<Account>;
  highestPrice: Scalars['BigInt'];
  id: Scalars['ID'];
  nft: Erc721Token;
  owner: Account;
  participations: Array<AuctionParticipation>;
  startDate: Scalars['BigInt'];
  status: AuctionStatus;
};


export type AuctionParticipationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionParticipation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuctionParticipation_Filter>;
};

export type AuctionParticipation = {
  __typename?: 'AuctionParticipation';
  auction: Auction;
  bidder: Account;
  id: Scalars['ID'];
  price: Scalars['BigInt'];
};

export type AuctionParticipation_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  auction?: InputMaybe<Scalars['String']>;
  auction_contains?: InputMaybe<Scalars['String']>;
  auction_contains_nocase?: InputMaybe<Scalars['String']>;
  auction_ends_with?: InputMaybe<Scalars['String']>;
  auction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  auction_gt?: InputMaybe<Scalars['String']>;
  auction_gte?: InputMaybe<Scalars['String']>;
  auction_in?: InputMaybe<Array<Scalars['String']>>;
  auction_lt?: InputMaybe<Scalars['String']>;
  auction_lte?: InputMaybe<Scalars['String']>;
  auction_not?: InputMaybe<Scalars['String']>;
  auction_not_contains?: InputMaybe<Scalars['String']>;
  auction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  auction_not_ends_with?: InputMaybe<Scalars['String']>;
  auction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  auction_not_in?: InputMaybe<Array<Scalars['String']>>;
  auction_not_starts_with?: InputMaybe<Scalars['String']>;
  auction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  auction_starts_with?: InputMaybe<Scalars['String']>;
  auction_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bidder?: InputMaybe<Scalars['String']>;
  bidder_contains?: InputMaybe<Scalars['String']>;
  bidder_contains_nocase?: InputMaybe<Scalars['String']>;
  bidder_ends_with?: InputMaybe<Scalars['String']>;
  bidder_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bidder_gt?: InputMaybe<Scalars['String']>;
  bidder_gte?: InputMaybe<Scalars['String']>;
  bidder_in?: InputMaybe<Array<Scalars['String']>>;
  bidder_lt?: InputMaybe<Scalars['String']>;
  bidder_lte?: InputMaybe<Scalars['String']>;
  bidder_not?: InputMaybe<Scalars['String']>;
  bidder_not_contains?: InputMaybe<Scalars['String']>;
  bidder_not_contains_nocase?: InputMaybe<Scalars['String']>;
  bidder_not_ends_with?: InputMaybe<Scalars['String']>;
  bidder_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bidder_not_in?: InputMaybe<Array<Scalars['String']>>;
  bidder_not_starts_with?: InputMaybe<Scalars['String']>;
  bidder_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bidder_starts_with?: InputMaybe<Scalars['String']>;
  bidder_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum AuctionParticipation_OrderBy {
  Auction = 'auction',
  Bidder = 'bidder',
  Id = 'id',
  Price = 'price'
}

export enum AuctionStatus {
  Bid = 'BID',
  Created = 'CREATED',
  Finished = 'FINISHED'
}

export type Auction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  biddingStep?: InputMaybe<Scalars['BigInt']>;
  biddingStep_gt?: InputMaybe<Scalars['BigInt']>;
  biddingStep_gte?: InputMaybe<Scalars['BigInt']>;
  biddingStep_in?: InputMaybe<Array<Scalars['BigInt']>>;
  biddingStep_lt?: InputMaybe<Scalars['BigInt']>;
  biddingStep_lte?: InputMaybe<Scalars['BigInt']>;
  biddingStep_not?: InputMaybe<Scalars['BigInt']>;
  biddingStep_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endDate?: InputMaybe<Scalars['BigInt']>;
  endDate_gt?: InputMaybe<Scalars['BigInt']>;
  endDate_gte?: InputMaybe<Scalars['BigInt']>;
  endDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endDate_lt?: InputMaybe<Scalars['BigInt']>;
  endDate_lte?: InputMaybe<Scalars['BigInt']>;
  endDate_not?: InputMaybe<Scalars['BigInt']>;
  endDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  highestBidder?: InputMaybe<Scalars['String']>;
  highestBidder_contains?: InputMaybe<Scalars['String']>;
  highestBidder_contains_nocase?: InputMaybe<Scalars['String']>;
  highestBidder_ends_with?: InputMaybe<Scalars['String']>;
  highestBidder_ends_with_nocase?: InputMaybe<Scalars['String']>;
  highestBidder_gt?: InputMaybe<Scalars['String']>;
  highestBidder_gte?: InputMaybe<Scalars['String']>;
  highestBidder_in?: InputMaybe<Array<Scalars['String']>>;
  highestBidder_lt?: InputMaybe<Scalars['String']>;
  highestBidder_lte?: InputMaybe<Scalars['String']>;
  highestBidder_not?: InputMaybe<Scalars['String']>;
  highestBidder_not_contains?: InputMaybe<Scalars['String']>;
  highestBidder_not_contains_nocase?: InputMaybe<Scalars['String']>;
  highestBidder_not_ends_with?: InputMaybe<Scalars['String']>;
  highestBidder_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  highestBidder_not_in?: InputMaybe<Array<Scalars['String']>>;
  highestBidder_not_starts_with?: InputMaybe<Scalars['String']>;
  highestBidder_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  highestBidder_starts_with?: InputMaybe<Scalars['String']>;
  highestBidder_starts_with_nocase?: InputMaybe<Scalars['String']>;
  highestPrice?: InputMaybe<Scalars['BigInt']>;
  highestPrice_gt?: InputMaybe<Scalars['BigInt']>;
  highestPrice_gte?: InputMaybe<Scalars['BigInt']>;
  highestPrice_in?: InputMaybe<Array<Scalars['BigInt']>>;
  highestPrice_lt?: InputMaybe<Scalars['BigInt']>;
  highestPrice_lte?: InputMaybe<Scalars['BigInt']>;
  highestPrice_not?: InputMaybe<Scalars['BigInt']>;
  highestPrice_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft?: InputMaybe<Scalars['String']>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['BigInt']>;
  startDate_gt?: InputMaybe<Scalars['BigInt']>;
  startDate_gte?: InputMaybe<Scalars['BigInt']>;
  startDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startDate_lt?: InputMaybe<Scalars['BigInt']>;
  startDate_lte?: InputMaybe<Scalars['BigInt']>;
  startDate_not?: InputMaybe<Scalars['BigInt']>;
  startDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<AuctionStatus>;
  status_in?: InputMaybe<Array<AuctionStatus>>;
  status_not?: InputMaybe<AuctionStatus>;
  status_not_in?: InputMaybe<Array<AuctionStatus>>;
};

export enum Auction_OrderBy {
  BiddingStep = 'biddingStep',
  EndDate = 'endDate',
  HighestBidder = 'highestBidder',
  HighestPrice = 'highestPrice',
  Id = 'id',
  Nft = 'nft',
  Owner = 'owner',
  Participations = 'participations',
  StartDate = 'startDate',
  Status = 'status'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type Character = {
  __typename?: 'Character';
  characterId: Scalars['BigInt'];
  id: Scalars['ID'];
  isFreeCharacter: Scalars['Boolean'];
  name: Scalars['String'];
  owner: Account;
};

export type Character_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  characterId?: InputMaybe<Scalars['BigInt']>;
  characterId_gt?: InputMaybe<Scalars['BigInt']>;
  characterId_gte?: InputMaybe<Scalars['BigInt']>;
  characterId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  characterId_lt?: InputMaybe<Scalars['BigInt']>;
  characterId_lte?: InputMaybe<Scalars['BigInt']>;
  characterId_not?: InputMaybe<Scalars['BigInt']>;
  characterId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isFreeCharacter?: InputMaybe<Scalars['Boolean']>;
  isFreeCharacter_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isFreeCharacter_not?: InputMaybe<Scalars['Boolean']>;
  isFreeCharacter_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Character_OrderBy {
  CharacterId = 'characterId',
  Id = 'id',
  IsFreeCharacter = 'isFreeCharacter',
  Name = 'name',
  Owner = 'owner'
}

export type CollateralOffer = {
  __typename?: 'CollateralOffer';
  counterOfferList: Array<CounterCollateralOffer>;
  duration: Scalars['BigInt'];
  expiry?: Maybe<Scalars['BigInt']>;
  fee: Scalars['BigInt'];
  id: Scalars['ID'];
  loanValue: Scalars['BigInt'];
  loanee: Account;
  loaner?: Maybe<Account>;
  status: CollateralOfferStatus;
  token: Erc721Token;
};


export type CollateralOfferCounterOfferListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CounterCollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CounterCollateralOffer_Filter>;
};

export enum CollateralOfferStatus {
  Canceled = 'CANCELED',
  Claimed = 'CLAIMED',
  Created = 'CREATED',
  Loaned = 'LOANED',
  Offered = 'OFFERED'
}

export type CollateralOffer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry?: InputMaybe<Scalars['BigInt']>;
  expiry_gt?: InputMaybe<Scalars['BigInt']>;
  expiry_gte?: InputMaybe<Scalars['BigInt']>;
  expiry_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry_lt?: InputMaybe<Scalars['BigInt']>;
  expiry_lte?: InputMaybe<Scalars['BigInt']>;
  expiry_not?: InputMaybe<Scalars['BigInt']>;
  expiry_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fee?: InputMaybe<Scalars['BigInt']>;
  fee_gt?: InputMaybe<Scalars['BigInt']>;
  fee_gte?: InputMaybe<Scalars['BigInt']>;
  fee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fee_lt?: InputMaybe<Scalars['BigInt']>;
  fee_lte?: InputMaybe<Scalars['BigInt']>;
  fee_not?: InputMaybe<Scalars['BigInt']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanValue?: InputMaybe<Scalars['BigInt']>;
  loanValue_gt?: InputMaybe<Scalars['BigInt']>;
  loanValue_gte?: InputMaybe<Scalars['BigInt']>;
  loanValue_in?: InputMaybe<Array<Scalars['BigInt']>>;
  loanValue_lt?: InputMaybe<Scalars['BigInt']>;
  loanValue_lte?: InputMaybe<Scalars['BigInt']>;
  loanValue_not?: InputMaybe<Scalars['BigInt']>;
  loanValue_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  loanee?: InputMaybe<Scalars['String']>;
  loanee_contains?: InputMaybe<Scalars['String']>;
  loanee_contains_nocase?: InputMaybe<Scalars['String']>;
  loanee_ends_with?: InputMaybe<Scalars['String']>;
  loanee_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loanee_gt?: InputMaybe<Scalars['String']>;
  loanee_gte?: InputMaybe<Scalars['String']>;
  loanee_in?: InputMaybe<Array<Scalars['String']>>;
  loanee_lt?: InputMaybe<Scalars['String']>;
  loanee_lte?: InputMaybe<Scalars['String']>;
  loanee_not?: InputMaybe<Scalars['String']>;
  loanee_not_contains?: InputMaybe<Scalars['String']>;
  loanee_not_contains_nocase?: InputMaybe<Scalars['String']>;
  loanee_not_ends_with?: InputMaybe<Scalars['String']>;
  loanee_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loanee_not_in?: InputMaybe<Array<Scalars['String']>>;
  loanee_not_starts_with?: InputMaybe<Scalars['String']>;
  loanee_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loanee_starts_with?: InputMaybe<Scalars['String']>;
  loanee_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loaner?: InputMaybe<Scalars['String']>;
  loaner_contains?: InputMaybe<Scalars['String']>;
  loaner_contains_nocase?: InputMaybe<Scalars['String']>;
  loaner_ends_with?: InputMaybe<Scalars['String']>;
  loaner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loaner_gt?: InputMaybe<Scalars['String']>;
  loaner_gte?: InputMaybe<Scalars['String']>;
  loaner_in?: InputMaybe<Array<Scalars['String']>>;
  loaner_lt?: InputMaybe<Scalars['String']>;
  loaner_lte?: InputMaybe<Scalars['String']>;
  loaner_not?: InputMaybe<Scalars['String']>;
  loaner_not_contains?: InputMaybe<Scalars['String']>;
  loaner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  loaner_not_ends_with?: InputMaybe<Scalars['String']>;
  loaner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loaner_not_in?: InputMaybe<Array<Scalars['String']>>;
  loaner_not_starts_with?: InputMaybe<Scalars['String']>;
  loaner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loaner_starts_with?: InputMaybe<Scalars['String']>;
  loaner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CollateralOfferStatus>;
  status_in?: InputMaybe<Array<CollateralOfferStatus>>;
  status_not?: InputMaybe<CollateralOfferStatus>;
  status_not_in?: InputMaybe<Array<CollateralOfferStatus>>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum CollateralOffer_OrderBy {
  CounterOfferList = 'counterOfferList',
  Duration = 'duration',
  Expiry = 'expiry',
  Fee = 'fee',
  Id = 'id',
  LoanValue = 'loanValue',
  Loanee = 'loanee',
  Loaner = 'loaner',
  Status = 'status',
  Token = 'token'
}

export type CounterCollateralOffer = {
  __typename?: 'CounterCollateralOffer';
  collateralOffer: CollateralOffer;
  fee: Scalars['BigInt'];
  id: Scalars['ID'];
  offerer: Account;
};

export type CounterCollateralOffer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  collateralOffer?: InputMaybe<Scalars['String']>;
  collateralOffer_contains?: InputMaybe<Scalars['String']>;
  collateralOffer_contains_nocase?: InputMaybe<Scalars['String']>;
  collateralOffer_ends_with?: InputMaybe<Scalars['String']>;
  collateralOffer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  collateralOffer_gt?: InputMaybe<Scalars['String']>;
  collateralOffer_gte?: InputMaybe<Scalars['String']>;
  collateralOffer_in?: InputMaybe<Array<Scalars['String']>>;
  collateralOffer_lt?: InputMaybe<Scalars['String']>;
  collateralOffer_lte?: InputMaybe<Scalars['String']>;
  collateralOffer_not?: InputMaybe<Scalars['String']>;
  collateralOffer_not_contains?: InputMaybe<Scalars['String']>;
  collateralOffer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  collateralOffer_not_ends_with?: InputMaybe<Scalars['String']>;
  collateralOffer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  collateralOffer_not_in?: InputMaybe<Array<Scalars['String']>>;
  collateralOffer_not_starts_with?: InputMaybe<Scalars['String']>;
  collateralOffer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  collateralOffer_starts_with?: InputMaybe<Scalars['String']>;
  collateralOffer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['BigInt']>;
  fee_gt?: InputMaybe<Scalars['BigInt']>;
  fee_gte?: InputMaybe<Scalars['BigInt']>;
  fee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fee_lt?: InputMaybe<Scalars['BigInt']>;
  fee_lte?: InputMaybe<Scalars['BigInt']>;
  fee_not?: InputMaybe<Scalars['BigInt']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offerer?: InputMaybe<Scalars['String']>;
  offerer_contains?: InputMaybe<Scalars['String']>;
  offerer_contains_nocase?: InputMaybe<Scalars['String']>;
  offerer_ends_with?: InputMaybe<Scalars['String']>;
  offerer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offerer_gt?: InputMaybe<Scalars['String']>;
  offerer_gte?: InputMaybe<Scalars['String']>;
  offerer_in?: InputMaybe<Array<Scalars['String']>>;
  offerer_lt?: InputMaybe<Scalars['String']>;
  offerer_lte?: InputMaybe<Scalars['String']>;
  offerer_not?: InputMaybe<Scalars['String']>;
  offerer_not_contains?: InputMaybe<Scalars['String']>;
  offerer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offerer_not_ends_with?: InputMaybe<Scalars['String']>;
  offerer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offerer_not_in?: InputMaybe<Array<Scalars['String']>>;
  offerer_not_starts_with?: InputMaybe<Scalars['String']>;
  offerer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offerer_starts_with?: InputMaybe<Scalars['String']>;
  offerer_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum CounterCollateralOffer_OrderBy {
  CollateralOffer = 'collateralOffer',
  Fee = 'fee',
  Id = 'id',
  Offerer = 'offerer'
}

export type Erc20Approval = {
  __typename?: 'ERC20Approval';
  contract: Erc20Contract;
  id: Scalars['ID'];
  owner: Account;
  spender: Account;
  value: Scalars['BigDecimal'];
  valueExact: Scalars['BigInt'];
};

export type Erc20Approval_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  spender?: InputMaybe<Scalars['String']>;
  spender_contains?: InputMaybe<Scalars['String']>;
  spender_contains_nocase?: InputMaybe<Scalars['String']>;
  spender_ends_with?: InputMaybe<Scalars['String']>;
  spender_ends_with_nocase?: InputMaybe<Scalars['String']>;
  spender_gt?: InputMaybe<Scalars['String']>;
  spender_gte?: InputMaybe<Scalars['String']>;
  spender_in?: InputMaybe<Array<Scalars['String']>>;
  spender_lt?: InputMaybe<Scalars['String']>;
  spender_lte?: InputMaybe<Scalars['String']>;
  spender_not?: InputMaybe<Scalars['String']>;
  spender_not_contains?: InputMaybe<Scalars['String']>;
  spender_not_contains_nocase?: InputMaybe<Scalars['String']>;
  spender_not_ends_with?: InputMaybe<Scalars['String']>;
  spender_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  spender_not_in?: InputMaybe<Array<Scalars['String']>>;
  spender_not_starts_with?: InputMaybe<Scalars['String']>;
  spender_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  spender_starts_with?: InputMaybe<Scalars['String']>;
  spender_starts_with_nocase?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  valueExact?: InputMaybe<Scalars['BigInt']>;
  valueExact_gt?: InputMaybe<Scalars['BigInt']>;
  valueExact_gte?: InputMaybe<Scalars['BigInt']>;
  valueExact_in?: InputMaybe<Array<Scalars['BigInt']>>;
  valueExact_lt?: InputMaybe<Scalars['BigInt']>;
  valueExact_lte?: InputMaybe<Scalars['BigInt']>;
  valueExact_not?: InputMaybe<Scalars['BigInt']>;
  valueExact_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Erc20Approval_OrderBy {
  Contract = 'contract',
  Id = 'id',
  Owner = 'owner',
  Spender = 'spender',
  Value = 'value',
  ValueExact = 'valueExact'
}

export type Erc20Balance = {
  __typename?: 'ERC20Balance';
  account?: Maybe<Account>;
  contract: Erc20Contract;
  id: Scalars['ID'];
  transferFromEvent: Array<Erc20Transfer>;
  transferToEvent: Array<Erc20Transfer>;
  value: Scalars['BigDecimal'];
  valueExact: Scalars['BigInt'];
};


export type Erc20BalanceTransferFromEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};


export type Erc20BalanceTransferToEventArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type Erc20Balance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  valueExact?: InputMaybe<Scalars['BigInt']>;
  valueExact_gt?: InputMaybe<Scalars['BigInt']>;
  valueExact_gte?: InputMaybe<Scalars['BigInt']>;
  valueExact_in?: InputMaybe<Array<Scalars['BigInt']>>;
  valueExact_lt?: InputMaybe<Scalars['BigInt']>;
  valueExact_lte?: InputMaybe<Scalars['BigInt']>;
  valueExact_not?: InputMaybe<Scalars['BigInt']>;
  valueExact_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Erc20Balance_OrderBy {
  Account = 'account',
  Contract = 'contract',
  Id = 'id',
  TransferFromEvent = 'transferFromEvent',
  TransferToEvent = 'transferToEvent',
  Value = 'value',
  ValueExact = 'valueExact'
}

export type Erc20Contract = {
  __typename?: 'ERC20Contract';
  approvals: Array<Erc20Approval>;
  asAccount: Account;
  balances: Array<Erc20Balance>;
  decimals: Scalars['Int'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  totalSupply: Erc20Balance;
  transfers: Array<Erc20Transfer>;
};


export type Erc20ContractApprovalsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Approval_Filter>;
};


export type Erc20ContractBalancesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Balance_Filter>;
};


export type Erc20ContractTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type Erc20Contract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  asAccount?: InputMaybe<Scalars['String']>;
  asAccount_contains?: InputMaybe<Scalars['String']>;
  asAccount_contains_nocase?: InputMaybe<Scalars['String']>;
  asAccount_ends_with?: InputMaybe<Scalars['String']>;
  asAccount_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_gt?: InputMaybe<Scalars['String']>;
  asAccount_gte?: InputMaybe<Scalars['String']>;
  asAccount_in?: InputMaybe<Array<Scalars['String']>>;
  asAccount_lt?: InputMaybe<Scalars['String']>;
  asAccount_lte?: InputMaybe<Scalars['String']>;
  asAccount_not?: InputMaybe<Scalars['String']>;
  asAccount_not_contains?: InputMaybe<Scalars['String']>;
  asAccount_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asAccount_not_ends_with?: InputMaybe<Scalars['String']>;
  asAccount_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_not_in?: InputMaybe<Array<Scalars['String']>>;
  asAccount_not_starts_with?: InputMaybe<Scalars['String']>;
  asAccount_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_starts_with?: InputMaybe<Scalars['String']>;
  asAccount_starts_with_nocase?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['String']>;
  totalSupply_contains?: InputMaybe<Scalars['String']>;
  totalSupply_contains_nocase?: InputMaybe<Scalars['String']>;
  totalSupply_ends_with?: InputMaybe<Scalars['String']>;
  totalSupply_ends_with_nocase?: InputMaybe<Scalars['String']>;
  totalSupply_gt?: InputMaybe<Scalars['String']>;
  totalSupply_gte?: InputMaybe<Scalars['String']>;
  totalSupply_in?: InputMaybe<Array<Scalars['String']>>;
  totalSupply_lt?: InputMaybe<Scalars['String']>;
  totalSupply_lte?: InputMaybe<Scalars['String']>;
  totalSupply_not?: InputMaybe<Scalars['String']>;
  totalSupply_not_contains?: InputMaybe<Scalars['String']>;
  totalSupply_not_contains_nocase?: InputMaybe<Scalars['String']>;
  totalSupply_not_ends_with?: InputMaybe<Scalars['String']>;
  totalSupply_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['String']>>;
  totalSupply_not_starts_with?: InputMaybe<Scalars['String']>;
  totalSupply_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  totalSupply_starts_with?: InputMaybe<Scalars['String']>;
  totalSupply_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Erc20Contract_OrderBy {
  Approvals = 'approvals',
  AsAccount = 'asAccount',
  Balances = 'balances',
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Symbol = 'symbol',
  TotalSupply = 'totalSupply',
  Transfers = 'transfers'
}

export type Erc20Transfer = Event & {
  __typename?: 'ERC20Transfer';
  contract: Erc20Contract;
  from?: Maybe<Account>;
  fromBalance?: Maybe<Erc20Balance>;
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  to?: Maybe<Account>;
  toBalance?: Maybe<Erc20Balance>;
  transaction: Transaction;
  value: Scalars['BigDecimal'];
  valueExact: Scalars['BigInt'];
};

export type Erc20Transfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  fromBalance?: InputMaybe<Scalars['String']>;
  fromBalance_contains?: InputMaybe<Scalars['String']>;
  fromBalance_contains_nocase?: InputMaybe<Scalars['String']>;
  fromBalance_ends_with?: InputMaybe<Scalars['String']>;
  fromBalance_ends_with_nocase?: InputMaybe<Scalars['String']>;
  fromBalance_gt?: InputMaybe<Scalars['String']>;
  fromBalance_gte?: InputMaybe<Scalars['String']>;
  fromBalance_in?: InputMaybe<Array<Scalars['String']>>;
  fromBalance_lt?: InputMaybe<Scalars['String']>;
  fromBalance_lte?: InputMaybe<Scalars['String']>;
  fromBalance_not?: InputMaybe<Scalars['String']>;
  fromBalance_not_contains?: InputMaybe<Scalars['String']>;
  fromBalance_not_contains_nocase?: InputMaybe<Scalars['String']>;
  fromBalance_not_ends_with?: InputMaybe<Scalars['String']>;
  fromBalance_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  fromBalance_not_in?: InputMaybe<Array<Scalars['String']>>;
  fromBalance_not_starts_with?: InputMaybe<Scalars['String']>;
  fromBalance_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  fromBalance_starts_with?: InputMaybe<Scalars['String']>;
  fromBalance_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from_contains?: InputMaybe<Scalars['String']>;
  from_contains_nocase?: InputMaybe<Scalars['String']>;
  from_ends_with?: InputMaybe<Scalars['String']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_gt?: InputMaybe<Scalars['String']>;
  from_gte?: InputMaybe<Scalars['String']>;
  from_in?: InputMaybe<Array<Scalars['String']>>;
  from_lt?: InputMaybe<Scalars['String']>;
  from_lte?: InputMaybe<Scalars['String']>;
  from_not?: InputMaybe<Scalars['String']>;
  from_not_contains?: InputMaybe<Scalars['String']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']>;
  from_not_ends_with?: InputMaybe<Scalars['String']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_not_in?: InputMaybe<Array<Scalars['String']>>;
  from_not_starts_with?: InputMaybe<Scalars['String']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from_starts_with?: InputMaybe<Scalars['String']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  to?: InputMaybe<Scalars['String']>;
  toBalance?: InputMaybe<Scalars['String']>;
  toBalance_contains?: InputMaybe<Scalars['String']>;
  toBalance_contains_nocase?: InputMaybe<Scalars['String']>;
  toBalance_ends_with?: InputMaybe<Scalars['String']>;
  toBalance_ends_with_nocase?: InputMaybe<Scalars['String']>;
  toBalance_gt?: InputMaybe<Scalars['String']>;
  toBalance_gte?: InputMaybe<Scalars['String']>;
  toBalance_in?: InputMaybe<Array<Scalars['String']>>;
  toBalance_lt?: InputMaybe<Scalars['String']>;
  toBalance_lte?: InputMaybe<Scalars['String']>;
  toBalance_not?: InputMaybe<Scalars['String']>;
  toBalance_not_contains?: InputMaybe<Scalars['String']>;
  toBalance_not_contains_nocase?: InputMaybe<Scalars['String']>;
  toBalance_not_ends_with?: InputMaybe<Scalars['String']>;
  toBalance_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  toBalance_not_in?: InputMaybe<Array<Scalars['String']>>;
  toBalance_not_starts_with?: InputMaybe<Scalars['String']>;
  toBalance_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  toBalance_starts_with?: InputMaybe<Scalars['String']>;
  toBalance_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_contains_nocase?: InputMaybe<Scalars['String']>;
  to_ends_with?: InputMaybe<Scalars['String']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_not?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']>;
  to_not_ends_with?: InputMaybe<Scalars['String']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_starts_with?: InputMaybe<Scalars['String']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_starts_with?: InputMaybe<Scalars['String']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['String']>;
  transaction_contains?: InputMaybe<Scalars['String']>;
  transaction_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_ends_with?: InputMaybe<Scalars['String']>;
  transaction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_gt?: InputMaybe<Scalars['String']>;
  transaction_gte?: InputMaybe<Scalars['String']>;
  transaction_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_lt?: InputMaybe<Scalars['String']>;
  transaction_lte?: InputMaybe<Scalars['String']>;
  transaction_not?: InputMaybe<Scalars['String']>;
  transaction_not_contains?: InputMaybe<Scalars['String']>;
  transaction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_not_starts_with?: InputMaybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_starts_with?: InputMaybe<Scalars['String']>;
  transaction_starts_with_nocase?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['BigDecimal']>;
  valueExact?: InputMaybe<Scalars['BigInt']>;
  valueExact_gt?: InputMaybe<Scalars['BigInt']>;
  valueExact_gte?: InputMaybe<Scalars['BigInt']>;
  valueExact_in?: InputMaybe<Array<Scalars['BigInt']>>;
  valueExact_lt?: InputMaybe<Scalars['BigInt']>;
  valueExact_lte?: InputMaybe<Scalars['BigInt']>;
  valueExact_not?: InputMaybe<Scalars['BigInt']>;
  valueExact_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_gt?: InputMaybe<Scalars['BigDecimal']>;
  value_gte?: InputMaybe<Scalars['BigDecimal']>;
  value_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  value_lt?: InputMaybe<Scalars['BigDecimal']>;
  value_lte?: InputMaybe<Scalars['BigDecimal']>;
  value_not?: InputMaybe<Scalars['BigDecimal']>;
  value_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Erc20Transfer_OrderBy {
  Contract = 'contract',
  From = 'from',
  FromBalance = 'fromBalance',
  Id = 'id',
  Timestamp = 'timestamp',
  To = 'to',
  ToBalance = 'toBalance',
  Transaction = 'transaction',
  Value = 'value',
  ValueExact = 'valueExact'
}

export type Erc721Contract = {
  __typename?: 'ERC721Contract';
  asAccount: Account;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  operators: Array<Erc721Operator>;
  supportsMetadata?: Maybe<Scalars['Boolean']>;
  symbol?: Maybe<Scalars['String']>;
  tokens: Array<Erc721Token>;
  transfers: Array<Erc721Transfer>;
};


export type Erc721ContractOperatorsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Operator_Filter>;
};


export type Erc721ContractTokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Token_Filter>;
};


export type Erc721ContractTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Contract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  asAccount?: InputMaybe<Scalars['String']>;
  asAccount_contains?: InputMaybe<Scalars['String']>;
  asAccount_contains_nocase?: InputMaybe<Scalars['String']>;
  asAccount_ends_with?: InputMaybe<Scalars['String']>;
  asAccount_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_gt?: InputMaybe<Scalars['String']>;
  asAccount_gte?: InputMaybe<Scalars['String']>;
  asAccount_in?: InputMaybe<Array<Scalars['String']>>;
  asAccount_lt?: InputMaybe<Scalars['String']>;
  asAccount_lte?: InputMaybe<Scalars['String']>;
  asAccount_not?: InputMaybe<Scalars['String']>;
  asAccount_not_contains?: InputMaybe<Scalars['String']>;
  asAccount_not_contains_nocase?: InputMaybe<Scalars['String']>;
  asAccount_not_ends_with?: InputMaybe<Scalars['String']>;
  asAccount_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_not_in?: InputMaybe<Array<Scalars['String']>>;
  asAccount_not_starts_with?: InputMaybe<Scalars['String']>;
  asAccount_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  asAccount_starts_with?: InputMaybe<Scalars['String']>;
  asAccount_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  supportsMetadata?: InputMaybe<Scalars['Boolean']>;
  supportsMetadata_in?: InputMaybe<Array<Scalars['Boolean']>>;
  supportsMetadata_not?: InputMaybe<Scalars['Boolean']>;
  supportsMetadata_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Erc721Contract_OrderBy {
  AsAccount = 'asAccount',
  Id = 'id',
  Name = 'name',
  Operators = 'operators',
  SupportsMetadata = 'supportsMetadata',
  Symbol = 'symbol',
  Tokens = 'tokens',
  Transfers = 'transfers'
}

export type Erc721Operator = {
  __typename?: 'ERC721Operator';
  approved: Scalars['Boolean'];
  contract: Erc721Contract;
  id: Scalars['ID'];
  operator: Account;
  owner: Account;
};

export type Erc721Operator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  approved?: InputMaybe<Scalars['Boolean']>;
  approved_in?: InputMaybe<Array<Scalars['Boolean']>>;
  approved_not?: InputMaybe<Scalars['Boolean']>;
  approved_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  operator?: InputMaybe<Scalars['String']>;
  operator_contains?: InputMaybe<Scalars['String']>;
  operator_contains_nocase?: InputMaybe<Scalars['String']>;
  operator_ends_with?: InputMaybe<Scalars['String']>;
  operator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  operator_gt?: InputMaybe<Scalars['String']>;
  operator_gte?: InputMaybe<Scalars['String']>;
  operator_in?: InputMaybe<Array<Scalars['String']>>;
  operator_lt?: InputMaybe<Scalars['String']>;
  operator_lte?: InputMaybe<Scalars['String']>;
  operator_not?: InputMaybe<Scalars['String']>;
  operator_not_contains?: InputMaybe<Scalars['String']>;
  operator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  operator_not_ends_with?: InputMaybe<Scalars['String']>;
  operator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  operator_not_in?: InputMaybe<Array<Scalars['String']>>;
  operator_not_starts_with?: InputMaybe<Scalars['String']>;
  operator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  operator_starts_with?: InputMaybe<Scalars['String']>;
  operator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Erc721Operator_OrderBy {
  Approved = 'approved',
  Contract = 'contract',
  Id = 'id',
  Operator = 'operator',
  Owner = 'owner'
}

export type Erc721Token = {
  __typename?: 'ERC721Token';
  approval: Account;
  contract: Erc721Contract;
  id: Scalars['ID'];
  identifier: Scalars['BigInt'];
  itemTypeId?: Maybe<Scalars['BigInt']>;
  owner: Account;
  transfers: Array<Erc721Transfer>;
  uri?: Maybe<Scalars['String']>;
};


export type Erc721TokenTransfersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  approval?: InputMaybe<Scalars['String']>;
  approval_contains?: InputMaybe<Scalars['String']>;
  approval_contains_nocase?: InputMaybe<Scalars['String']>;
  approval_ends_with?: InputMaybe<Scalars['String']>;
  approval_ends_with_nocase?: InputMaybe<Scalars['String']>;
  approval_gt?: InputMaybe<Scalars['String']>;
  approval_gte?: InputMaybe<Scalars['String']>;
  approval_in?: InputMaybe<Array<Scalars['String']>>;
  approval_lt?: InputMaybe<Scalars['String']>;
  approval_lte?: InputMaybe<Scalars['String']>;
  approval_not?: InputMaybe<Scalars['String']>;
  approval_not_contains?: InputMaybe<Scalars['String']>;
  approval_not_contains_nocase?: InputMaybe<Scalars['String']>;
  approval_not_ends_with?: InputMaybe<Scalars['String']>;
  approval_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  approval_not_in?: InputMaybe<Array<Scalars['String']>>;
  approval_not_starts_with?: InputMaybe<Scalars['String']>;
  approval_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  approval_starts_with?: InputMaybe<Scalars['String']>;
  approval_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  identifier?: InputMaybe<Scalars['BigInt']>;
  identifier_gt?: InputMaybe<Scalars['BigInt']>;
  identifier_gte?: InputMaybe<Scalars['BigInt']>;
  identifier_in?: InputMaybe<Array<Scalars['BigInt']>>;
  identifier_lt?: InputMaybe<Scalars['BigInt']>;
  identifier_lte?: InputMaybe<Scalars['BigInt']>;
  identifier_not?: InputMaybe<Scalars['BigInt']>;
  identifier_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  itemTypeId?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  itemTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
  uri_contains?: InputMaybe<Scalars['String']>;
  uri_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_ends_with?: InputMaybe<Scalars['String']>;
  uri_ends_with_nocase?: InputMaybe<Scalars['String']>;
  uri_gt?: InputMaybe<Scalars['String']>;
  uri_gte?: InputMaybe<Scalars['String']>;
  uri_in?: InputMaybe<Array<Scalars['String']>>;
  uri_lt?: InputMaybe<Scalars['String']>;
  uri_lte?: InputMaybe<Scalars['String']>;
  uri_not?: InputMaybe<Scalars['String']>;
  uri_not_contains?: InputMaybe<Scalars['String']>;
  uri_not_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_not_ends_with?: InputMaybe<Scalars['String']>;
  uri_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  uri_not_in?: InputMaybe<Array<Scalars['String']>>;
  uri_not_starts_with?: InputMaybe<Scalars['String']>;
  uri_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri_starts_with?: InputMaybe<Scalars['String']>;
  uri_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Erc721Token_OrderBy {
  Approval = 'approval',
  Contract = 'contract',
  Id = 'id',
  Identifier = 'identifier',
  ItemTypeId = 'itemTypeId',
  Owner = 'owner',
  Transfers = 'transfers',
  Uri = 'uri'
}

export type Erc721Transfer = Event & {
  __typename?: 'ERC721Transfer';
  contract: Erc721Contract;
  from: Account;
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  to: Account;
  token: Erc721Token;
  transaction: Transaction;
};

export type Erc721Transfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  contract?: InputMaybe<Scalars['String']>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  from_contains?: InputMaybe<Scalars['String']>;
  from_contains_nocase?: InputMaybe<Scalars['String']>;
  from_ends_with?: InputMaybe<Scalars['String']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_gt?: InputMaybe<Scalars['String']>;
  from_gte?: InputMaybe<Scalars['String']>;
  from_in?: InputMaybe<Array<Scalars['String']>>;
  from_lt?: InputMaybe<Scalars['String']>;
  from_lte?: InputMaybe<Scalars['String']>;
  from_not?: InputMaybe<Scalars['String']>;
  from_not_contains?: InputMaybe<Scalars['String']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']>;
  from_not_ends_with?: InputMaybe<Scalars['String']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_not_in?: InputMaybe<Array<Scalars['String']>>;
  from_not_starts_with?: InputMaybe<Scalars['String']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from_starts_with?: InputMaybe<Scalars['String']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  to?: InputMaybe<Scalars['String']>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_contains_nocase?: InputMaybe<Scalars['String']>;
  to_ends_with?: InputMaybe<Scalars['String']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_not?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']>;
  to_not_ends_with?: InputMaybe<Scalars['String']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_starts_with?: InputMaybe<Scalars['String']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_starts_with?: InputMaybe<Scalars['String']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['String']>;
  transaction_contains?: InputMaybe<Scalars['String']>;
  transaction_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_ends_with?: InputMaybe<Scalars['String']>;
  transaction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_gt?: InputMaybe<Scalars['String']>;
  transaction_gte?: InputMaybe<Scalars['String']>;
  transaction_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_lt?: InputMaybe<Scalars['String']>;
  transaction_lte?: InputMaybe<Scalars['String']>;
  transaction_not?: InputMaybe<Scalars['String']>;
  transaction_not_contains?: InputMaybe<Scalars['String']>;
  transaction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_not_starts_with?: InputMaybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_starts_with?: InputMaybe<Scalars['String']>;
  transaction_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Erc721Transfer_OrderBy {
  Contract = 'contract',
  From = 'from',
  Id = 'id',
  Timestamp = 'timestamp',
  To = 'to',
  Token = 'token',
  Transaction = 'transaction'
}

export type Event = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  transaction: Transaction;
};

export type Event_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transaction?: InputMaybe<Scalars['String']>;
  transaction_contains?: InputMaybe<Scalars['String']>;
  transaction_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_ends_with?: InputMaybe<Scalars['String']>;
  transaction_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_gt?: InputMaybe<Scalars['String']>;
  transaction_gte?: InputMaybe<Scalars['String']>;
  transaction_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_lt?: InputMaybe<Scalars['String']>;
  transaction_lte?: InputMaybe<Scalars['String']>;
  transaction_not?: InputMaybe<Scalars['String']>;
  transaction_not_contains?: InputMaybe<Scalars['String']>;
  transaction_not_contains_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with?: InputMaybe<Scalars['String']>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_not_in?: InputMaybe<Array<Scalars['String']>>;
  transaction_not_starts_with?: InputMaybe<Scalars['String']>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transaction_starts_with?: InputMaybe<Scalars['String']>;
  transaction_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Event_OrderBy {
  Id = 'id',
  Timestamp = 'timestamp',
  Transaction = 'transaction'
}

export type Hiring = {
  __typename?: 'Hiring';
  buildingItem: Erc721Token;
  buildingOwner: Account;
  id: Scalars['ID'];
  rentPercentage: Scalars['BigInt'];
  rentableItems: Array<RentableItem>;
  status: HiringStatus;
  ticketPercentage: Scalars['BigInt'];
};


export type HiringRentableItemsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RentableItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RentableItem_Filter>;
};

export enum HiringStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED'
}

export type Hiring_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  buildingItem?: InputMaybe<Scalars['String']>;
  buildingItem_contains?: InputMaybe<Scalars['String']>;
  buildingItem_contains_nocase?: InputMaybe<Scalars['String']>;
  buildingItem_ends_with?: InputMaybe<Scalars['String']>;
  buildingItem_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buildingItem_gt?: InputMaybe<Scalars['String']>;
  buildingItem_gte?: InputMaybe<Scalars['String']>;
  buildingItem_in?: InputMaybe<Array<Scalars['String']>>;
  buildingItem_lt?: InputMaybe<Scalars['String']>;
  buildingItem_lte?: InputMaybe<Scalars['String']>;
  buildingItem_not?: InputMaybe<Scalars['String']>;
  buildingItem_not_contains?: InputMaybe<Scalars['String']>;
  buildingItem_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buildingItem_not_ends_with?: InputMaybe<Scalars['String']>;
  buildingItem_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buildingItem_not_in?: InputMaybe<Array<Scalars['String']>>;
  buildingItem_not_starts_with?: InputMaybe<Scalars['String']>;
  buildingItem_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buildingItem_starts_with?: InputMaybe<Scalars['String']>;
  buildingItem_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner?: InputMaybe<Scalars['String']>;
  buildingOwner_contains?: InputMaybe<Scalars['String']>;
  buildingOwner_contains_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner_ends_with?: InputMaybe<Scalars['String']>;
  buildingOwner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner_gt?: InputMaybe<Scalars['String']>;
  buildingOwner_gte?: InputMaybe<Scalars['String']>;
  buildingOwner_in?: InputMaybe<Array<Scalars['String']>>;
  buildingOwner_lt?: InputMaybe<Scalars['String']>;
  buildingOwner_lte?: InputMaybe<Scalars['String']>;
  buildingOwner_not?: InputMaybe<Scalars['String']>;
  buildingOwner_not_contains?: InputMaybe<Scalars['String']>;
  buildingOwner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner_not_ends_with?: InputMaybe<Scalars['String']>;
  buildingOwner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner_not_in?: InputMaybe<Array<Scalars['String']>>;
  buildingOwner_not_starts_with?: InputMaybe<Scalars['String']>;
  buildingOwner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buildingOwner_starts_with?: InputMaybe<Scalars['String']>;
  buildingOwner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  rentPercentage?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_gt?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_gte?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rentPercentage_lt?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_lte?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_not?: InputMaybe<Scalars['BigInt']>;
  rentPercentage_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<HiringStatus>;
  status_in?: InputMaybe<Array<HiringStatus>>;
  status_not?: InputMaybe<HiringStatus>;
  status_not_in?: InputMaybe<Array<HiringStatus>>;
  ticketPercentage?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_gt?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_gte?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ticketPercentage_lt?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_lte?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_not?: InputMaybe<Scalars['BigInt']>;
  ticketPercentage_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Hiring_OrderBy {
  BuildingItem = 'buildingItem',
  BuildingOwner = 'buildingOwner',
  Id = 'id',
  RentPercentage = 'rentPercentage',
  RentableItems = 'rentableItems',
  Status = 'status',
  TicketPercentage = 'ticketPercentage'
}

export type ItemType = {
  __typename?: 'ItemType';
  id: Scalars['ID'];
  itemTypeId: Scalars['BigInt'];
  token: Erc721Token;
};

export type ItemType_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  itemTypeId?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  itemTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum ItemType_OrderBy {
  Id = 'id',
  ItemTypeId = 'itemTypeId',
  Token = 'token'
}

export type Lease = {
  __typename?: 'Lease';
  duration: Scalars['BigInt'];
  expiry?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  owner: Account;
  price: Scalars['BigInt'];
  renter?: Maybe<Account>;
  status: LeaseStatus;
  token: Erc721Token;
};

export enum LeaseStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Finished = 'FINISHED',
  Rent = 'RENT'
}

export type Lease_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry?: InputMaybe<Scalars['BigInt']>;
  expiry_gt?: InputMaybe<Scalars['BigInt']>;
  expiry_gte?: InputMaybe<Scalars['BigInt']>;
  expiry_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry_lt?: InputMaybe<Scalars['BigInt']>;
  expiry_lte?: InputMaybe<Scalars['BigInt']>;
  expiry_not?: InputMaybe<Scalars['BigInt']>;
  expiry_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  renter?: InputMaybe<Scalars['String']>;
  renter_contains?: InputMaybe<Scalars['String']>;
  renter_contains_nocase?: InputMaybe<Scalars['String']>;
  renter_ends_with?: InputMaybe<Scalars['String']>;
  renter_ends_with_nocase?: InputMaybe<Scalars['String']>;
  renter_gt?: InputMaybe<Scalars['String']>;
  renter_gte?: InputMaybe<Scalars['String']>;
  renter_in?: InputMaybe<Array<Scalars['String']>>;
  renter_lt?: InputMaybe<Scalars['String']>;
  renter_lte?: InputMaybe<Scalars['String']>;
  renter_not?: InputMaybe<Scalars['String']>;
  renter_not_contains?: InputMaybe<Scalars['String']>;
  renter_not_contains_nocase?: InputMaybe<Scalars['String']>;
  renter_not_ends_with?: InputMaybe<Scalars['String']>;
  renter_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  renter_not_in?: InputMaybe<Array<Scalars['String']>>;
  renter_not_starts_with?: InputMaybe<Scalars['String']>;
  renter_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  renter_starts_with?: InputMaybe<Scalars['String']>;
  renter_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LeaseStatus>;
  status_in?: InputMaybe<Array<LeaseStatus>>;
  status_not?: InputMaybe<LeaseStatus>;
  status_not_in?: InputMaybe<Array<LeaseStatus>>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Lease_OrderBy {
  Duration = 'duration',
  Expiry = 'expiry',
  Id = 'id',
  Owner = 'owner',
  Price = 'price',
  Renter = 'renter',
  Status = 'status',
  Token = 'token'
}

export type Listing = {
  __typename?: 'Listing';
  buyer?: Maybe<Account>;
  id: Scalars['ID'];
  price: Scalars['BigInt'];
  seller: Account;
  status: ListingStatus;
  token: Erc721Token;
};

export enum ListingStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Sold = 'SOLD'
}

export type Listing_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  buyer?: InputMaybe<Scalars['String']>;
  buyer_contains?: InputMaybe<Scalars['String']>;
  buyer_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_ends_with?: InputMaybe<Scalars['String']>;
  buyer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_gt?: InputMaybe<Scalars['String']>;
  buyer_gte?: InputMaybe<Scalars['String']>;
  buyer_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_lt?: InputMaybe<Scalars['String']>;
  buyer_lte?: InputMaybe<Scalars['String']>;
  buyer_not?: InputMaybe<Scalars['String']>;
  buyer_not_contains?: InputMaybe<Scalars['String']>;
  buyer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_starts_with?: InputMaybe<Scalars['String']>;
  buyer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  seller?: InputMaybe<Scalars['String']>;
  seller_contains?: InputMaybe<Scalars['String']>;
  seller_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_ends_with?: InputMaybe<Scalars['String']>;
  seller_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_gt?: InputMaybe<Scalars['String']>;
  seller_gte?: InputMaybe<Scalars['String']>;
  seller_in?: InputMaybe<Array<Scalars['String']>>;
  seller_lt?: InputMaybe<Scalars['String']>;
  seller_lte?: InputMaybe<Scalars['String']>;
  seller_not?: InputMaybe<Scalars['String']>;
  seller_not_contains?: InputMaybe<Scalars['String']>;
  seller_not_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_not_ends_with?: InputMaybe<Scalars['String']>;
  seller_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_not_in?: InputMaybe<Array<Scalars['String']>>;
  seller_not_starts_with?: InputMaybe<Scalars['String']>;
  seller_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_starts_with?: InputMaybe<Scalars['String']>;
  seller_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ListingStatus>;
  status_in?: InputMaybe<Array<ListingStatus>>;
  status_not?: InputMaybe<ListingStatus>;
  status_not_in?: InputMaybe<Array<ListingStatus>>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Listing_OrderBy {
  Buyer = 'buyer',
  Id = 'id',
  Price = 'price',
  Seller = 'seller',
  Status = 'status',
  Token = 'token'
}

export type Mask = {
  __typename?: 'Mask';
  frontElementTypeId: Scalars['BigInt'];
  id: Scalars['ID'];
  itemTypeId?: Maybe<Scalars['BigInt']>;
  maskId: Scalars['BigInt'];
  maskMaterialTypeId: Scalars['BigInt'];
  maskShapeTypeId: Scalars['BigInt'];
  owner: Account;
  paintOverTypeId: Scalars['BigInt'];
  scratchesTypeId: Scalars['BigInt'];
  status: Scalars['String'];
  topElementTypeId: Scalars['BigInt'];
};

export type MaskBox = {
  __typename?: 'MaskBox';
  allMaskPartIds?: Maybe<Array<Scalars['BigInt']>>;
  boxId: Scalars['BigInt'];
  id: Scalars['ID'];
  owner: Account;
  status: MaskBoxStatus;
};

export enum MaskBoxStatus {
  Created = 'CREATED',
  Opened = 'OPENED'
}

export type MaskBox_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  allMaskPartIds?: InputMaybe<Array<Scalars['BigInt']>>;
  allMaskPartIds_contains?: InputMaybe<Array<Scalars['BigInt']>>;
  allMaskPartIds_contains_nocase?: InputMaybe<Array<Scalars['BigInt']>>;
  allMaskPartIds_not?: InputMaybe<Array<Scalars['BigInt']>>;
  allMaskPartIds_not_contains?: InputMaybe<Array<Scalars['BigInt']>>;
  allMaskPartIds_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']>>;
  boxId?: InputMaybe<Scalars['BigInt']>;
  boxId_gt?: InputMaybe<Scalars['BigInt']>;
  boxId_gte?: InputMaybe<Scalars['BigInt']>;
  boxId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  boxId_lt?: InputMaybe<Scalars['BigInt']>;
  boxId_lte?: InputMaybe<Scalars['BigInt']>;
  boxId_not?: InputMaybe<Scalars['BigInt']>;
  boxId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MaskBoxStatus>;
  status_in?: InputMaybe<Array<MaskBoxStatus>>;
  status_not?: InputMaybe<MaskBoxStatus>;
  status_not_in?: InputMaybe<Array<MaskBoxStatus>>;
};

export enum MaskBox_OrderBy {
  AllMaskPartIds = 'allMaskPartIds',
  BoxId = 'boxId',
  Id = 'id',
  Owner = 'owner',
  Status = 'status'
}

export type Mask_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  frontElementTypeId?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  frontElementTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_not?: InputMaybe<Scalars['BigInt']>;
  frontElementTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  itemTypeId?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  itemTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not?: InputMaybe<Scalars['BigInt']>;
  itemTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskId?: InputMaybe<Scalars['BigInt']>;
  maskId_gt?: InputMaybe<Scalars['BigInt']>;
  maskId_gte?: InputMaybe<Scalars['BigInt']>;
  maskId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskId_lt?: InputMaybe<Scalars['BigInt']>;
  maskId_lte?: InputMaybe<Scalars['BigInt']>;
  maskId_not?: InputMaybe<Scalars['BigInt']>;
  maskId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskMaterialTypeId?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskMaterialTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_not?: InputMaybe<Scalars['BigInt']>;
  maskMaterialTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskShapeTypeId?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maskShapeTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_not?: InputMaybe<Scalars['BigInt']>;
  maskShapeTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  paintOverTypeId?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  paintOverTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_not?: InputMaybe<Scalars['BigInt']>;
  paintOverTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  scratchesTypeId?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  scratchesTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_not?: InputMaybe<Scalars['BigInt']>;
  scratchesTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<Scalars['String']>;
  status_contains?: InputMaybe<Scalars['String']>;
  status_contains_nocase?: InputMaybe<Scalars['String']>;
  status_ends_with?: InputMaybe<Scalars['String']>;
  status_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_gt?: InputMaybe<Scalars['String']>;
  status_gte?: InputMaybe<Scalars['String']>;
  status_in?: InputMaybe<Array<Scalars['String']>>;
  status_lt?: InputMaybe<Scalars['String']>;
  status_lte?: InputMaybe<Scalars['String']>;
  status_not?: InputMaybe<Scalars['String']>;
  status_not_contains?: InputMaybe<Scalars['String']>;
  status_not_contains_nocase?: InputMaybe<Scalars['String']>;
  status_not_ends_with?: InputMaybe<Scalars['String']>;
  status_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_not_in?: InputMaybe<Array<Scalars['String']>>;
  status_not_starts_with?: InputMaybe<Scalars['String']>;
  status_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status_starts_with?: InputMaybe<Scalars['String']>;
  status_starts_with_nocase?: InputMaybe<Scalars['String']>;
  topElementTypeId?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_gt?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_gte?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  topElementTypeId_lt?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_lte?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_not?: InputMaybe<Scalars['BigInt']>;
  topElementTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Mask_OrderBy {
  FrontElementTypeId = 'frontElementTypeId',
  Id = 'id',
  ItemTypeId = 'itemTypeId',
  MaskId = 'maskId',
  MaskMaterialTypeId = 'maskMaterialTypeId',
  MaskShapeTypeId = 'maskShapeTypeId',
  Owner = 'owner',
  PaintOverTypeId = 'paintOverTypeId',
  ScratchesTypeId = 'scratchesTypeId',
  Status = 'status',
  TopElementTypeId = 'topElementTypeId'
}

export type MerchantLimit = {
  __typename?: 'MerchantLimit';
  boat: Scalars['BigInt'];
  boatAndPlane: Scalars['BigInt'];
  building: Scalars['BigInt'];
  companion: Scalars['BigInt'];
  dog: Scalars['BigInt'];
  donkey: Scalars['BigInt'];
  horse: Scalars['BigInt'];
  id: Scalars['ID'];
  plane: Scalars['BigInt'];
  rightId: Scalars['BigInt'];
  sameBaseBuilding: Scalars['BigInt'];
  sameBoatAndPlaneLimit: Scalars['Boolean'];
  sameCompanionsLimit: Scalars['Boolean'];
  similarBuilding: Scalars['BigInt'];
};

export type MerchantLimit_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  boat?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_gt?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_gte?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_in?: InputMaybe<Array<Scalars['BigInt']>>;
  boatAndPlane_lt?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_lte?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_not?: InputMaybe<Scalars['BigInt']>;
  boatAndPlane_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  boat_gt?: InputMaybe<Scalars['BigInt']>;
  boat_gte?: InputMaybe<Scalars['BigInt']>;
  boat_in?: InputMaybe<Array<Scalars['BigInt']>>;
  boat_lt?: InputMaybe<Scalars['BigInt']>;
  boat_lte?: InputMaybe<Scalars['BigInt']>;
  boat_not?: InputMaybe<Scalars['BigInt']>;
  boat_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  building?: InputMaybe<Scalars['BigInt']>;
  building_gt?: InputMaybe<Scalars['BigInt']>;
  building_gte?: InputMaybe<Scalars['BigInt']>;
  building_in?: InputMaybe<Array<Scalars['BigInt']>>;
  building_lt?: InputMaybe<Scalars['BigInt']>;
  building_lte?: InputMaybe<Scalars['BigInt']>;
  building_not?: InputMaybe<Scalars['BigInt']>;
  building_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  companion?: InputMaybe<Scalars['BigInt']>;
  companion_gt?: InputMaybe<Scalars['BigInt']>;
  companion_gte?: InputMaybe<Scalars['BigInt']>;
  companion_in?: InputMaybe<Array<Scalars['BigInt']>>;
  companion_lt?: InputMaybe<Scalars['BigInt']>;
  companion_lte?: InputMaybe<Scalars['BigInt']>;
  companion_not?: InputMaybe<Scalars['BigInt']>;
  companion_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  dog?: InputMaybe<Scalars['BigInt']>;
  dog_gt?: InputMaybe<Scalars['BigInt']>;
  dog_gte?: InputMaybe<Scalars['BigInt']>;
  dog_in?: InputMaybe<Array<Scalars['BigInt']>>;
  dog_lt?: InputMaybe<Scalars['BigInt']>;
  dog_lte?: InputMaybe<Scalars['BigInt']>;
  dog_not?: InputMaybe<Scalars['BigInt']>;
  dog_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  donkey?: InputMaybe<Scalars['BigInt']>;
  donkey_gt?: InputMaybe<Scalars['BigInt']>;
  donkey_gte?: InputMaybe<Scalars['BigInt']>;
  donkey_in?: InputMaybe<Array<Scalars['BigInt']>>;
  donkey_lt?: InputMaybe<Scalars['BigInt']>;
  donkey_lte?: InputMaybe<Scalars['BigInt']>;
  donkey_not?: InputMaybe<Scalars['BigInt']>;
  donkey_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  horse?: InputMaybe<Scalars['BigInt']>;
  horse_gt?: InputMaybe<Scalars['BigInt']>;
  horse_gte?: InputMaybe<Scalars['BigInt']>;
  horse_in?: InputMaybe<Array<Scalars['BigInt']>>;
  horse_lt?: InputMaybe<Scalars['BigInt']>;
  horse_lte?: InputMaybe<Scalars['BigInt']>;
  horse_not?: InputMaybe<Scalars['BigInt']>;
  horse_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  plane?: InputMaybe<Scalars['BigInt']>;
  plane_gt?: InputMaybe<Scalars['BigInt']>;
  plane_gte?: InputMaybe<Scalars['BigInt']>;
  plane_in?: InputMaybe<Array<Scalars['BigInt']>>;
  plane_lt?: InputMaybe<Scalars['BigInt']>;
  plane_lte?: InputMaybe<Scalars['BigInt']>;
  plane_not?: InputMaybe<Scalars['BigInt']>;
  plane_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightId?: InputMaybe<Scalars['BigInt']>;
  rightId_gt?: InputMaybe<Scalars['BigInt']>;
  rightId_gte?: InputMaybe<Scalars['BigInt']>;
  rightId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightId_lt?: InputMaybe<Scalars['BigInt']>;
  rightId_lte?: InputMaybe<Scalars['BigInt']>;
  rightId_not?: InputMaybe<Scalars['BigInt']>;
  rightId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  sameBaseBuilding?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_gt?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_gte?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_in?: InputMaybe<Array<Scalars['BigInt']>>;
  sameBaseBuilding_lt?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_lte?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_not?: InputMaybe<Scalars['BigInt']>;
  sameBaseBuilding_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  sameBoatAndPlaneLimit?: InputMaybe<Scalars['Boolean']>;
  sameBoatAndPlaneLimit_in?: InputMaybe<Array<Scalars['Boolean']>>;
  sameBoatAndPlaneLimit_not?: InputMaybe<Scalars['Boolean']>;
  sameBoatAndPlaneLimit_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  sameCompanionsLimit?: InputMaybe<Scalars['Boolean']>;
  sameCompanionsLimit_in?: InputMaybe<Array<Scalars['Boolean']>>;
  sameCompanionsLimit_not?: InputMaybe<Scalars['Boolean']>;
  sameCompanionsLimit_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  similarBuilding?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_gt?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_gte?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_in?: InputMaybe<Array<Scalars['BigInt']>>;
  similarBuilding_lt?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_lte?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_not?: InputMaybe<Scalars['BigInt']>;
  similarBuilding_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum MerchantLimit_OrderBy {
  Boat = 'boat',
  BoatAndPlane = 'boatAndPlane',
  Building = 'building',
  Companion = 'companion',
  Dog = 'dog',
  Donkey = 'donkey',
  Horse = 'horse',
  Id = 'id',
  Plane = 'plane',
  RightId = 'rightId',
  SameBaseBuilding = 'sameBaseBuilding',
  SameBoatAndPlaneLimit = 'sameBoatAndPlaneLimit',
  SameCompanionsLimit = 'sameCompanionsLimit',
  SimilarBuilding = 'similarBuilding'
}

export type Miner = {
  __typename?: 'Miner';
  arena: Arena;
  id: Scalars['ID'];
  minerAddress: Account;
  status: MinerStatus;
};

export enum MinerStatus {
  Entered = 'ENTERED',
  Registered = 'REGISTERED'
}

export type Miner_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  arena?: InputMaybe<Scalars['String']>;
  arena_contains?: InputMaybe<Scalars['String']>;
  arena_contains_nocase?: InputMaybe<Scalars['String']>;
  arena_ends_with?: InputMaybe<Scalars['String']>;
  arena_ends_with_nocase?: InputMaybe<Scalars['String']>;
  arena_gt?: InputMaybe<Scalars['String']>;
  arena_gte?: InputMaybe<Scalars['String']>;
  arena_in?: InputMaybe<Array<Scalars['String']>>;
  arena_lt?: InputMaybe<Scalars['String']>;
  arena_lte?: InputMaybe<Scalars['String']>;
  arena_not?: InputMaybe<Scalars['String']>;
  arena_not_contains?: InputMaybe<Scalars['String']>;
  arena_not_contains_nocase?: InputMaybe<Scalars['String']>;
  arena_not_ends_with?: InputMaybe<Scalars['String']>;
  arena_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  arena_not_in?: InputMaybe<Array<Scalars['String']>>;
  arena_not_starts_with?: InputMaybe<Scalars['String']>;
  arena_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  arena_starts_with?: InputMaybe<Scalars['String']>;
  arena_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  minerAddress?: InputMaybe<Scalars['String']>;
  minerAddress_contains?: InputMaybe<Scalars['String']>;
  minerAddress_contains_nocase?: InputMaybe<Scalars['String']>;
  minerAddress_ends_with?: InputMaybe<Scalars['String']>;
  minerAddress_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minerAddress_gt?: InputMaybe<Scalars['String']>;
  minerAddress_gte?: InputMaybe<Scalars['String']>;
  minerAddress_in?: InputMaybe<Array<Scalars['String']>>;
  minerAddress_lt?: InputMaybe<Scalars['String']>;
  minerAddress_lte?: InputMaybe<Scalars['String']>;
  minerAddress_not?: InputMaybe<Scalars['String']>;
  minerAddress_not_contains?: InputMaybe<Scalars['String']>;
  minerAddress_not_contains_nocase?: InputMaybe<Scalars['String']>;
  minerAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  minerAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minerAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  minerAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  minerAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  minerAddress_starts_with?: InputMaybe<Scalars['String']>;
  minerAddress_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MinerStatus>;
  status_in?: InputMaybe<Array<MinerStatus>>;
  status_not?: InputMaybe<MinerStatus>;
  status_not_in?: InputMaybe<Array<MinerStatus>>;
};

export enum Miner_OrderBy {
  Arena = 'arena',
  Id = 'id',
  MinerAddress = 'minerAddress',
  Status = 'status'
}

export type MiningClaim = {
  __typename?: 'MiningClaim';
  arenaHour: Scalars['BigInt'];
  arenas: Array<Arena>;
  contractAddress: Account;
  id: Scalars['ID'];
  maxMiners: Scalars['BigInt'];
  miningClaimId: Scalars['BigInt'];
  nft: Erc721Token;
  nglAmount: Scalars['BigInt'];
  owner: Account;
};


export type MiningClaimArenasArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Arena_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Arena_Filter>;
};

export type MiningClaim_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  arenaHour?: InputMaybe<Scalars['BigInt']>;
  arenaHour_gt?: InputMaybe<Scalars['BigInt']>;
  arenaHour_gte?: InputMaybe<Scalars['BigInt']>;
  arenaHour_in?: InputMaybe<Array<Scalars['BigInt']>>;
  arenaHour_lt?: InputMaybe<Scalars['BigInt']>;
  arenaHour_lte?: InputMaybe<Scalars['BigInt']>;
  arenaHour_not?: InputMaybe<Scalars['BigInt']>;
  arenaHour_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  contractAddress?: InputMaybe<Scalars['String']>;
  contractAddress_contains?: InputMaybe<Scalars['String']>;
  contractAddress_contains_nocase?: InputMaybe<Scalars['String']>;
  contractAddress_ends_with?: InputMaybe<Scalars['String']>;
  contractAddress_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contractAddress_gt?: InputMaybe<Scalars['String']>;
  contractAddress_gte?: InputMaybe<Scalars['String']>;
  contractAddress_in?: InputMaybe<Array<Scalars['String']>>;
  contractAddress_lt?: InputMaybe<Scalars['String']>;
  contractAddress_lte?: InputMaybe<Scalars['String']>;
  contractAddress_not?: InputMaybe<Scalars['String']>;
  contractAddress_not_contains?: InputMaybe<Scalars['String']>;
  contractAddress_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contractAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  contractAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contractAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  contractAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  contractAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contractAddress_starts_with?: InputMaybe<Scalars['String']>;
  contractAddress_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  maxMiners?: InputMaybe<Scalars['BigInt']>;
  maxMiners_gt?: InputMaybe<Scalars['BigInt']>;
  maxMiners_gte?: InputMaybe<Scalars['BigInt']>;
  maxMiners_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxMiners_lt?: InputMaybe<Scalars['BigInt']>;
  maxMiners_lte?: InputMaybe<Scalars['BigInt']>;
  maxMiners_not?: InputMaybe<Scalars['BigInt']>;
  maxMiners_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  miningClaimId?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_gt?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_gte?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  miningClaimId_lt?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_lte?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_not?: InputMaybe<Scalars['BigInt']>;
  miningClaimId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft?: InputMaybe<Scalars['String']>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nglAmount?: InputMaybe<Scalars['BigInt']>;
  nglAmount_gt?: InputMaybe<Scalars['BigInt']>;
  nglAmount_gte?: InputMaybe<Scalars['BigInt']>;
  nglAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nglAmount_lt?: InputMaybe<Scalars['BigInt']>;
  nglAmount_lte?: InputMaybe<Scalars['BigInt']>;
  nglAmount_not?: InputMaybe<Scalars['BigInt']>;
  nglAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum MiningClaim_OrderBy {
  ArenaHour = 'arenaHour',
  Arenas = 'arenas',
  ContractAddress = 'contractAddress',
  Id = 'id',
  MaxMiners = 'maxMiners',
  MiningClaimId = 'miningClaimId',
  Nft = 'nft',
  NglAmount = 'nglAmount',
  Owner = 'owner'
}

export type Offer = {
  __typename?: 'Offer';
  fromAddress: Account;
  fromNfts: Array<OfferFromItem>;
  fromNglAmount: Scalars['BigInt'];
  id: Scalars['ID'];
  status: OfferStatus;
  toAddress?: Maybe<Account>;
  toNfts: Array<OfferToItem>;
  toNglAmount: Scalars['BigInt'];
};


export type OfferFromNftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferFromItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferFromItem_Filter>;
};


export type OfferToNftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferToItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferToItem_Filter>;
};

export type OfferFromItem = {
  __typename?: 'OfferFromItem';
  id: Scalars['ID'];
  offer: Offer;
  token: Erc721Token;
};

export type OfferFromItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offer?: InputMaybe<Scalars['String']>;
  offer_contains?: InputMaybe<Scalars['String']>;
  offer_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_ends_with?: InputMaybe<Scalars['String']>;
  offer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_gt?: InputMaybe<Scalars['String']>;
  offer_gte?: InputMaybe<Scalars['String']>;
  offer_in?: InputMaybe<Array<Scalars['String']>>;
  offer_lt?: InputMaybe<Scalars['String']>;
  offer_lte?: InputMaybe<Scalars['String']>;
  offer_not?: InputMaybe<Scalars['String']>;
  offer_not_contains?: InputMaybe<Scalars['String']>;
  offer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_starts_with?: InputMaybe<Scalars['String']>;
  offer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum OfferFromItem_OrderBy {
  Id = 'id',
  Offer = 'offer',
  Token = 'token'
}

export enum OfferStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Rejected = 'REJECTED',
  Swapped = 'SWAPPED'
}

export type OfferToItem = {
  __typename?: 'OfferToItem';
  id: Scalars['ID'];
  offer: Offer;
  token: Erc721Token;
};

export type OfferToItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offer?: InputMaybe<Scalars['String']>;
  offer_contains?: InputMaybe<Scalars['String']>;
  offer_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_ends_with?: InputMaybe<Scalars['String']>;
  offer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_gt?: InputMaybe<Scalars['String']>;
  offer_gte?: InputMaybe<Scalars['String']>;
  offer_in?: InputMaybe<Array<Scalars['String']>>;
  offer_lt?: InputMaybe<Scalars['String']>;
  offer_lte?: InputMaybe<Scalars['String']>;
  offer_not?: InputMaybe<Scalars['String']>;
  offer_not_contains?: InputMaybe<Scalars['String']>;
  offer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_starts_with?: InputMaybe<Scalars['String']>;
  offer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum OfferToItem_OrderBy {
  Id = 'id',
  Offer = 'offer',
  Token = 'token'
}

export type Offer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  fromAddress?: InputMaybe<Scalars['String']>;
  fromAddress_contains?: InputMaybe<Scalars['String']>;
  fromAddress_contains_nocase?: InputMaybe<Scalars['String']>;
  fromAddress_ends_with?: InputMaybe<Scalars['String']>;
  fromAddress_ends_with_nocase?: InputMaybe<Scalars['String']>;
  fromAddress_gt?: InputMaybe<Scalars['String']>;
  fromAddress_gte?: InputMaybe<Scalars['String']>;
  fromAddress_in?: InputMaybe<Array<Scalars['String']>>;
  fromAddress_lt?: InputMaybe<Scalars['String']>;
  fromAddress_lte?: InputMaybe<Scalars['String']>;
  fromAddress_not?: InputMaybe<Scalars['String']>;
  fromAddress_not_contains?: InputMaybe<Scalars['String']>;
  fromAddress_not_contains_nocase?: InputMaybe<Scalars['String']>;
  fromAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  fromAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  fromAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  fromAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  fromAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  fromAddress_starts_with?: InputMaybe<Scalars['String']>;
  fromAddress_starts_with_nocase?: InputMaybe<Scalars['String']>;
  fromNglAmount?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_gt?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_gte?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fromNglAmount_lt?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_lte?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_not?: InputMaybe<Scalars['BigInt']>;
  fromNglAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<OfferStatus>;
  status_in?: InputMaybe<Array<OfferStatus>>;
  status_not?: InputMaybe<OfferStatus>;
  status_not_in?: InputMaybe<Array<OfferStatus>>;
  toAddress?: InputMaybe<Scalars['String']>;
  toAddress_contains?: InputMaybe<Scalars['String']>;
  toAddress_contains_nocase?: InputMaybe<Scalars['String']>;
  toAddress_ends_with?: InputMaybe<Scalars['String']>;
  toAddress_ends_with_nocase?: InputMaybe<Scalars['String']>;
  toAddress_gt?: InputMaybe<Scalars['String']>;
  toAddress_gte?: InputMaybe<Scalars['String']>;
  toAddress_in?: InputMaybe<Array<Scalars['String']>>;
  toAddress_lt?: InputMaybe<Scalars['String']>;
  toAddress_lte?: InputMaybe<Scalars['String']>;
  toAddress_not?: InputMaybe<Scalars['String']>;
  toAddress_not_contains?: InputMaybe<Scalars['String']>;
  toAddress_not_contains_nocase?: InputMaybe<Scalars['String']>;
  toAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  toAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  toAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  toAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  toAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  toAddress_starts_with?: InputMaybe<Scalars['String']>;
  toAddress_starts_with_nocase?: InputMaybe<Scalars['String']>;
  toNglAmount?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_gt?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_gte?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  toNglAmount_lt?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_lte?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_not?: InputMaybe<Scalars['BigInt']>;
  toNglAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Offer_OrderBy {
  FromAddress = 'fromAddress',
  FromNfts = 'fromNfts',
  FromNglAmount = 'fromNglAmount',
  Id = 'id',
  Status = 'status',
  ToAddress = 'toAddress',
  ToNfts = 'toNfts',
  ToNglAmount = 'toNglAmount'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  arena?: Maybe<Arena>;
  arenas: Array<Arena>;
  attachItem?: Maybe<AttachItem>;
  attachItems: Array<AttachItem>;
  auction?: Maybe<Auction>;
  auctionParticipation?: Maybe<AuctionParticipation>;
  auctionParticipations: Array<AuctionParticipation>;
  auctions: Array<Auction>;
  character?: Maybe<Character>;
  characters: Array<Character>;
  collateralOffer?: Maybe<CollateralOffer>;
  collateralOffers: Array<CollateralOffer>;
  counterCollateralOffer?: Maybe<CounterCollateralOffer>;
  counterCollateralOffers: Array<CounterCollateralOffer>;
  erc20Approval?: Maybe<Erc20Approval>;
  erc20Approvals: Array<Erc20Approval>;
  erc20Balance?: Maybe<Erc20Balance>;
  erc20Balances: Array<Erc20Balance>;
  erc20Contract?: Maybe<Erc20Contract>;
  erc20Contracts: Array<Erc20Contract>;
  erc20Transfer?: Maybe<Erc20Transfer>;
  erc20Transfers: Array<Erc20Transfer>;
  erc721Contract?: Maybe<Erc721Contract>;
  erc721Contracts: Array<Erc721Contract>;
  erc721Operator?: Maybe<Erc721Operator>;
  erc721Operators: Array<Erc721Operator>;
  erc721Token?: Maybe<Erc721Token>;
  erc721Tokens: Array<Erc721Token>;
  erc721Transfer?: Maybe<Erc721Transfer>;
  erc721Transfers: Array<Erc721Transfer>;
  event?: Maybe<Event>;
  events: Array<Event>;
  hiring?: Maybe<Hiring>;
  hirings: Array<Hiring>;
  itemType?: Maybe<ItemType>;
  itemTypes: Array<ItemType>;
  lease?: Maybe<Lease>;
  leases: Array<Lease>;
  listing?: Maybe<Listing>;
  listings: Array<Listing>;
  mask?: Maybe<Mask>;
  maskBox?: Maybe<MaskBox>;
  maskBoxes: Array<MaskBox>;
  masks: Array<Mask>;
  merchantLimit?: Maybe<MerchantLimit>;
  merchantLimits: Array<MerchantLimit>;
  miner?: Maybe<Miner>;
  miners: Array<Miner>;
  miningClaim?: Maybe<MiningClaim>;
  miningClaims: Array<MiningClaim>;
  offer?: Maybe<Offer>;
  offerFromItem?: Maybe<OfferFromItem>;
  offerFromItems: Array<OfferFromItem>;
  offerToItem?: Maybe<OfferToItem>;
  offerToItems: Array<OfferToItem>;
  offers: Array<Offer>;
  rentableItem?: Maybe<RentableItem>;
  rentableItems: Array<RentableItem>;
  rightOption?: Maybe<RightOption>;
  rightOptions: Array<RightOption>;
  rightPurchase?: Maybe<RightPurchase>;
  rightPurchases: Array<RightPurchase>;
  transaction?: Maybe<Transaction>;
  transactionRecord?: Maybe<TransactionRecord>;
  transactionRecords: Array<TransactionRecord>;
  transactions: Array<Transaction>;
  withdrawRequest?: Maybe<WithdrawRequest>;
  withdrawRequests: Array<WithdrawRequest>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type QueryArenaArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryArenasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Arena_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Arena_Filter>;
};


export type QueryAttachItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAttachItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttachItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AttachItem_Filter>;
};


export type QueryAuctionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAuctionParticipationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAuctionParticipationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionParticipation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AuctionParticipation_Filter>;
};


export type QueryAuctionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Auction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Auction_Filter>;
};


export type QueryCharacterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCharactersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Character_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Character_Filter>;
};


export type QueryCollateralOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCollateralOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CollateralOffer_Filter>;
};


export type QueryCounterCollateralOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCounterCollateralOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CounterCollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CounterCollateralOffer_Filter>;
};


export type QueryErc20ApprovalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc20ApprovalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Approval_Filter>;
};


export type QueryErc20BalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc20BalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Balance_Filter>;
};


export type QueryErc20ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc20ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Contract_Filter>;
};


export type QueryErc20TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc20TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Transfer_Filter>;
};


export type QueryErc721ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc721ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};


export type QueryErc721OperatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc721OperatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};


export type QueryErc721TokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc721TokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};


export type QueryErc721TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryErc721TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};


export type QueryEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Event_Filter>;
};


export type QueryHiringArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryHiringsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Hiring_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Hiring_Filter>;
};


export type QueryItemTypeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryItemTypesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ItemType_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ItemType_Filter>;
};


export type QueryLeaseArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLeasesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Lease_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Lease_Filter>;
};


export type QueryListingArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryListingsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Listing_Filter>;
};


export type QueryMaskArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMaskBoxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMaskBoxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MaskBox_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MaskBox_Filter>;
};


export type QueryMasksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Mask_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Mask_Filter>;
};


export type QueryMerchantLimitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMerchantLimitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MerchantLimit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MerchantLimit_Filter>;
};


export type QueryMinerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMinersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Miner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Miner_Filter>;
};


export type QueryMiningClaimArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMiningClaimsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MiningClaim_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MiningClaim_Filter>;
};


export type QueryOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferFromItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferFromItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferFromItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferFromItem_Filter>;
};


export type QueryOfferToItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOfferToItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferToItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferToItem_Filter>;
};


export type QueryOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Offer_Filter>;
};


export type QueryRentableItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRentableItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RentableItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentableItem_Filter>;
};


export type QueryRightOptionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRightOptionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightOption_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RightOption_Filter>;
};


export type QueryRightPurchaseArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRightPurchasesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightPurchase_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RightPurchase_Filter>;
};


export type QueryTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransactionRecordArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransactionRecordsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionRecord_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionRecord_Filter>;
};


export type QueryTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Transaction_Filter>;
};


export type QueryWithdrawRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryWithdrawRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WithdrawRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WithdrawRequest_Filter>;
};

export type RentableItem = {
  __typename?: 'RentableItem';
  hiring: Hiring;
  id: Scalars['ID'];
  itemOwner: Account;
  token: Erc721Token;
};

export type RentableItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  hiring?: InputMaybe<Scalars['String']>;
  hiring_contains?: InputMaybe<Scalars['String']>;
  hiring_contains_nocase?: InputMaybe<Scalars['String']>;
  hiring_ends_with?: InputMaybe<Scalars['String']>;
  hiring_ends_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_gt?: InputMaybe<Scalars['String']>;
  hiring_gte?: InputMaybe<Scalars['String']>;
  hiring_in?: InputMaybe<Array<Scalars['String']>>;
  hiring_lt?: InputMaybe<Scalars['String']>;
  hiring_lte?: InputMaybe<Scalars['String']>;
  hiring_not?: InputMaybe<Scalars['String']>;
  hiring_not_contains?: InputMaybe<Scalars['String']>;
  hiring_not_contains_nocase?: InputMaybe<Scalars['String']>;
  hiring_not_ends_with?: InputMaybe<Scalars['String']>;
  hiring_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_not_in?: InputMaybe<Array<Scalars['String']>>;
  hiring_not_starts_with?: InputMaybe<Scalars['String']>;
  hiring_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_starts_with?: InputMaybe<Scalars['String']>;
  hiring_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  itemOwner?: InputMaybe<Scalars['String']>;
  itemOwner_contains?: InputMaybe<Scalars['String']>;
  itemOwner_contains_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_ends_with?: InputMaybe<Scalars['String']>;
  itemOwner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_gt?: InputMaybe<Scalars['String']>;
  itemOwner_gte?: InputMaybe<Scalars['String']>;
  itemOwner_in?: InputMaybe<Array<Scalars['String']>>;
  itemOwner_lt?: InputMaybe<Scalars['String']>;
  itemOwner_lte?: InputMaybe<Scalars['String']>;
  itemOwner_not?: InputMaybe<Scalars['String']>;
  itemOwner_not_contains?: InputMaybe<Scalars['String']>;
  itemOwner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_not_ends_with?: InputMaybe<Scalars['String']>;
  itemOwner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_not_in?: InputMaybe<Array<Scalars['String']>>;
  itemOwner_not_starts_with?: InputMaybe<Scalars['String']>;
  itemOwner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_starts_with?: InputMaybe<Scalars['String']>;
  itemOwner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum RentableItem_OrderBy {
  Hiring = 'hiring',
  Id = 'id',
  ItemOwner = 'itemOwner',
  Token = 'token'
}

export type RightOption = {
  __typename?: 'RightOption';
  duration: Scalars['BigInt'];
  id: Scalars['ID'];
  price: Scalars['BigInt'];
  rightId?: Maybe<Scalars['BigInt']>;
  rightPurchases: Array<RightPurchase>;
  rightType?: Maybe<Scalars['BigInt']>;
  stakeDuration: Scalars['BigInt'];
};


export type RightOptionRightPurchasesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightPurchase_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RightPurchase_Filter>;
};

export enum RightOptionStatus {
  Burned = 'BURNED',
  Finalized = 'FINALIZED',
  Staked = 'STAKED'
}

export type RightOption_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightId?: InputMaybe<Scalars['BigInt']>;
  rightId_gt?: InputMaybe<Scalars['BigInt']>;
  rightId_gte?: InputMaybe<Scalars['BigInt']>;
  rightId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightId_lt?: InputMaybe<Scalars['BigInt']>;
  rightId_lte?: InputMaybe<Scalars['BigInt']>;
  rightId_not?: InputMaybe<Scalars['BigInt']>;
  rightId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightType?: InputMaybe<Scalars['BigInt']>;
  rightType_gt?: InputMaybe<Scalars['BigInt']>;
  rightType_gte?: InputMaybe<Scalars['BigInt']>;
  rightType_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightType_lt?: InputMaybe<Scalars['BigInt']>;
  rightType_lte?: InputMaybe<Scalars['BigInt']>;
  rightType_not?: InputMaybe<Scalars['BigInt']>;
  rightType_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeDuration?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_gt?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_gte?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeDuration_lt?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_lte?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_not?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum RightOption_OrderBy {
  Duration = 'duration',
  Id = 'id',
  Price = 'price',
  RightId = 'rightId',
  RightPurchases = 'rightPurchases',
  RightType = 'rightType',
  StakeDuration = 'stakeDuration'
}

export type RightPurchase = {
  __typename?: 'RightPurchase';
  buyer?: Maybe<Account>;
  duration: Scalars['BigInt'];
  expiry?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['BigInt']>;
  rightOption: RightOption;
  rightOptionId?: Maybe<Scalars['BigInt']>;
  stakeDuration: Scalars['BigInt'];
  stakeExpiry: Scalars['BigInt'];
  status: RightOptionStatus;
};

export type RightPurchase_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  buyer?: InputMaybe<Scalars['String']>;
  buyer_contains?: InputMaybe<Scalars['String']>;
  buyer_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_ends_with?: InputMaybe<Scalars['String']>;
  buyer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_gt?: InputMaybe<Scalars['String']>;
  buyer_gte?: InputMaybe<Scalars['String']>;
  buyer_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_lt?: InputMaybe<Scalars['String']>;
  buyer_lte?: InputMaybe<Scalars['String']>;
  buyer_not?: InputMaybe<Scalars['String']>;
  buyer_not_contains?: InputMaybe<Scalars['String']>;
  buyer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_starts_with?: InputMaybe<Scalars['String']>;
  buyer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['BigInt']>;
  duration_gt?: InputMaybe<Scalars['BigInt']>;
  duration_gte?: InputMaybe<Scalars['BigInt']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  duration_lt?: InputMaybe<Scalars['BigInt']>;
  duration_lte?: InputMaybe<Scalars['BigInt']>;
  duration_not?: InputMaybe<Scalars['BigInt']>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry?: InputMaybe<Scalars['BigInt']>;
  expiry_gt?: InputMaybe<Scalars['BigInt']>;
  expiry_gte?: InputMaybe<Scalars['BigInt']>;
  expiry_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expiry_lt?: InputMaybe<Scalars['BigInt']>;
  expiry_lte?: InputMaybe<Scalars['BigInt']>;
  expiry_not?: InputMaybe<Scalars['BigInt']>;
  expiry_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightOption?: InputMaybe<Scalars['String']>;
  rightOptionId?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_gt?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_gte?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightOptionId_lt?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_lte?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_not?: InputMaybe<Scalars['BigInt']>;
  rightOptionId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rightOption_contains?: InputMaybe<Scalars['String']>;
  rightOption_contains_nocase?: InputMaybe<Scalars['String']>;
  rightOption_ends_with?: InputMaybe<Scalars['String']>;
  rightOption_ends_with_nocase?: InputMaybe<Scalars['String']>;
  rightOption_gt?: InputMaybe<Scalars['String']>;
  rightOption_gte?: InputMaybe<Scalars['String']>;
  rightOption_in?: InputMaybe<Array<Scalars['String']>>;
  rightOption_lt?: InputMaybe<Scalars['String']>;
  rightOption_lte?: InputMaybe<Scalars['String']>;
  rightOption_not?: InputMaybe<Scalars['String']>;
  rightOption_not_contains?: InputMaybe<Scalars['String']>;
  rightOption_not_contains_nocase?: InputMaybe<Scalars['String']>;
  rightOption_not_ends_with?: InputMaybe<Scalars['String']>;
  rightOption_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  rightOption_not_in?: InputMaybe<Array<Scalars['String']>>;
  rightOption_not_starts_with?: InputMaybe<Scalars['String']>;
  rightOption_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rightOption_starts_with?: InputMaybe<Scalars['String']>;
  rightOption_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stakeDuration?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_gt?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_gte?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeDuration_lt?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_lte?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_not?: InputMaybe<Scalars['BigInt']>;
  stakeDuration_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeExpiry?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_gt?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_gte?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeExpiry_lt?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_lte?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_not?: InputMaybe<Scalars['BigInt']>;
  stakeExpiry_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status?: InputMaybe<RightOptionStatus>;
  status_in?: InputMaybe<Array<RightOptionStatus>>;
  status_not?: InputMaybe<RightOptionStatus>;
  status_not_in?: InputMaybe<Array<RightOptionStatus>>;
};

export enum RightPurchase_OrderBy {
  Buyer = 'buyer',
  Duration = 'duration',
  Expiry = 'expiry',
  Id = 'id',
  Price = 'price',
  RightOption = 'rightOption',
  RightOptionId = 'rightOptionId',
  StakeDuration = 'stakeDuration',
  StakeExpiry = 'stakeExpiry',
  Status = 'status'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  arena?: Maybe<Arena>;
  arenas: Array<Arena>;
  attachItem?: Maybe<AttachItem>;
  attachItems: Array<AttachItem>;
  auction?: Maybe<Auction>;
  auctionParticipation?: Maybe<AuctionParticipation>;
  auctionParticipations: Array<AuctionParticipation>;
  auctions: Array<Auction>;
  character?: Maybe<Character>;
  characters: Array<Character>;
  collateralOffer?: Maybe<CollateralOffer>;
  collateralOffers: Array<CollateralOffer>;
  counterCollateralOffer?: Maybe<CounterCollateralOffer>;
  counterCollateralOffers: Array<CounterCollateralOffer>;
  erc20Approval?: Maybe<Erc20Approval>;
  erc20Approvals: Array<Erc20Approval>;
  erc20Balance?: Maybe<Erc20Balance>;
  erc20Balances: Array<Erc20Balance>;
  erc20Contract?: Maybe<Erc20Contract>;
  erc20Contracts: Array<Erc20Contract>;
  erc20Transfer?: Maybe<Erc20Transfer>;
  erc20Transfers: Array<Erc20Transfer>;
  erc721Contract?: Maybe<Erc721Contract>;
  erc721Contracts: Array<Erc721Contract>;
  erc721Operator?: Maybe<Erc721Operator>;
  erc721Operators: Array<Erc721Operator>;
  erc721Token?: Maybe<Erc721Token>;
  erc721Tokens: Array<Erc721Token>;
  erc721Transfer?: Maybe<Erc721Transfer>;
  erc721Transfers: Array<Erc721Transfer>;
  event?: Maybe<Event>;
  events: Array<Event>;
  hiring?: Maybe<Hiring>;
  hirings: Array<Hiring>;
  itemType?: Maybe<ItemType>;
  itemTypes: Array<ItemType>;
  lease?: Maybe<Lease>;
  leases: Array<Lease>;
  listing?: Maybe<Listing>;
  listings: Array<Listing>;
  mask?: Maybe<Mask>;
  maskBox?: Maybe<MaskBox>;
  maskBoxes: Array<MaskBox>;
  masks: Array<Mask>;
  merchantLimit?: Maybe<MerchantLimit>;
  merchantLimits: Array<MerchantLimit>;
  miner?: Maybe<Miner>;
  miners: Array<Miner>;
  miningClaim?: Maybe<MiningClaim>;
  miningClaims: Array<MiningClaim>;
  offer?: Maybe<Offer>;
  offerFromItem?: Maybe<OfferFromItem>;
  offerFromItems: Array<OfferFromItem>;
  offerToItem?: Maybe<OfferToItem>;
  offerToItems: Array<OfferToItem>;
  offers: Array<Offer>;
  rentableItem?: Maybe<RentableItem>;
  rentableItems: Array<RentableItem>;
  rightOption?: Maybe<RightOption>;
  rightOptions: Array<RightOption>;
  rightPurchase?: Maybe<RightPurchase>;
  rightPurchases: Array<RightPurchase>;
  transaction?: Maybe<Transaction>;
  transactionRecord?: Maybe<TransactionRecord>;
  transactionRecords: Array<TransactionRecord>;
  transactions: Array<Transaction>;
  withdrawRequest?: Maybe<WithdrawRequest>;
  withdrawRequests: Array<WithdrawRequest>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type SubscriptionArenaArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionArenasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Arena_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Arena_Filter>;
};


export type SubscriptionAttachItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAttachItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttachItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AttachItem_Filter>;
};


export type SubscriptionAuctionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAuctionParticipationArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAuctionParticipationsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionParticipation_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AuctionParticipation_Filter>;
};


export type SubscriptionAuctionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Auction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Auction_Filter>;
};


export type SubscriptionCharacterArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCharactersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Character_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Character_Filter>;
};


export type SubscriptionCollateralOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCollateralOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CollateralOffer_Filter>;
};


export type SubscriptionCounterCollateralOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCounterCollateralOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CounterCollateralOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CounterCollateralOffer_Filter>;
};


export type SubscriptionErc20ApprovalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc20ApprovalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Approval_Filter>;
};


export type SubscriptionErc20BalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc20BalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Balance_Filter>;
};


export type SubscriptionErc20ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc20ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Contract_Filter>;
};


export type SubscriptionErc20TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc20TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc20Transfer_Filter>;
};


export type SubscriptionErc721ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc721ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};


export type SubscriptionErc721OperatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc721OperatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};


export type SubscriptionErc721TokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc721TokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};


export type SubscriptionErc721TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionErc721TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};


export type SubscriptionEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Event_Filter>;
};


export type SubscriptionHiringArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionHiringsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Hiring_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Hiring_Filter>;
};


export type SubscriptionItemTypeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionItemTypesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ItemType_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ItemType_Filter>;
};


export type SubscriptionLeaseArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLeasesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Lease_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Lease_Filter>;
};


export type SubscriptionListingArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionListingsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Listing_Filter>;
};


export type SubscriptionMaskArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMaskBoxArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMaskBoxesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MaskBox_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MaskBox_Filter>;
};


export type SubscriptionMasksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Mask_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Mask_Filter>;
};


export type SubscriptionMerchantLimitArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMerchantLimitsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MerchantLimit_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MerchantLimit_Filter>;
};


export type SubscriptionMinerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMinersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Miner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Miner_Filter>;
};


export type SubscriptionMiningClaimArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMiningClaimsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MiningClaim_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MiningClaim_Filter>;
};


export type SubscriptionOfferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferFromItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferFromItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferFromItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferFromItem_Filter>;
};


export type SubscriptionOfferToItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOfferToItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferToItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OfferToItem_Filter>;
};


export type SubscriptionOffersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Offer_Filter>;
};


export type SubscriptionRentableItemArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRentableItemsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RentableItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentableItem_Filter>;
};


export type SubscriptionRightOptionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRightOptionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightOption_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RightOption_Filter>;
};


export type SubscriptionRightPurchaseArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRightPurchasesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RightPurchase_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RightPurchase_Filter>;
};


export type SubscriptionTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransactionRecordArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransactionRecordsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionRecord_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionRecord_Filter>;
};


export type SubscriptionTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Transaction_Filter>;
};


export type SubscriptionWithdrawRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionWithdrawRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WithdrawRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<WithdrawRequest_Filter>;
};

export type Transaction = {
  __typename?: 'Transaction';
  blockNumber: Scalars['BigInt'];
  events: Array<Event>;
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
};


export type TransactionEventsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Event_Filter>;
};

export type TransactionRecord = {
  __typename?: 'TransactionRecord';
  address: Account;
  counterAddress?: Maybe<Account>;
  id: Scalars['ID'];
  itemTransfer?: Maybe<Erc721Transfer>;
  nglTransfer?: Maybe<Erc20Transfer>;
  timestamp: Scalars['BigInt'];
  transactionType: TransactionType;
};

export type TransactionRecord_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_contains_nocase?: InputMaybe<Scalars['String']>;
  address_ends_with?: InputMaybe<Scalars['String']>;
  address_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_gt?: InputMaybe<Scalars['String']>;
  address_gte?: InputMaybe<Scalars['String']>;
  address_in?: InputMaybe<Array<Scalars['String']>>;
  address_lt?: InputMaybe<Scalars['String']>;
  address_lte?: InputMaybe<Scalars['String']>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_contains_nocase?: InputMaybe<Scalars['String']>;
  address_not_ends_with?: InputMaybe<Scalars['String']>;
  address_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<Scalars['String']>>;
  address_not_starts_with?: InputMaybe<Scalars['String']>;
  address_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  address_starts_with?: InputMaybe<Scalars['String']>;
  address_starts_with_nocase?: InputMaybe<Scalars['String']>;
  counterAddress?: InputMaybe<Scalars['String']>;
  counterAddress_contains?: InputMaybe<Scalars['String']>;
  counterAddress_contains_nocase?: InputMaybe<Scalars['String']>;
  counterAddress_ends_with?: InputMaybe<Scalars['String']>;
  counterAddress_ends_with_nocase?: InputMaybe<Scalars['String']>;
  counterAddress_gt?: InputMaybe<Scalars['String']>;
  counterAddress_gte?: InputMaybe<Scalars['String']>;
  counterAddress_in?: InputMaybe<Array<Scalars['String']>>;
  counterAddress_lt?: InputMaybe<Scalars['String']>;
  counterAddress_lte?: InputMaybe<Scalars['String']>;
  counterAddress_not?: InputMaybe<Scalars['String']>;
  counterAddress_not_contains?: InputMaybe<Scalars['String']>;
  counterAddress_not_contains_nocase?: InputMaybe<Scalars['String']>;
  counterAddress_not_ends_with?: InputMaybe<Scalars['String']>;
  counterAddress_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  counterAddress_not_in?: InputMaybe<Array<Scalars['String']>>;
  counterAddress_not_starts_with?: InputMaybe<Scalars['String']>;
  counterAddress_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  counterAddress_starts_with?: InputMaybe<Scalars['String']>;
  counterAddress_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  itemTransfer?: InputMaybe<Scalars['String']>;
  itemTransfer_contains?: InputMaybe<Scalars['String']>;
  itemTransfer_contains_nocase?: InputMaybe<Scalars['String']>;
  itemTransfer_ends_with?: InputMaybe<Scalars['String']>;
  itemTransfer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemTransfer_gt?: InputMaybe<Scalars['String']>;
  itemTransfer_gte?: InputMaybe<Scalars['String']>;
  itemTransfer_in?: InputMaybe<Array<Scalars['String']>>;
  itemTransfer_lt?: InputMaybe<Scalars['String']>;
  itemTransfer_lte?: InputMaybe<Scalars['String']>;
  itemTransfer_not?: InputMaybe<Scalars['String']>;
  itemTransfer_not_contains?: InputMaybe<Scalars['String']>;
  itemTransfer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  itemTransfer_not_ends_with?: InputMaybe<Scalars['String']>;
  itemTransfer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemTransfer_not_in?: InputMaybe<Array<Scalars['String']>>;
  itemTransfer_not_starts_with?: InputMaybe<Scalars['String']>;
  itemTransfer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  itemTransfer_starts_with?: InputMaybe<Scalars['String']>;
  itemTransfer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer?: InputMaybe<Scalars['String']>;
  nglTransfer_contains?: InputMaybe<Scalars['String']>;
  nglTransfer_contains_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer_ends_with?: InputMaybe<Scalars['String']>;
  nglTransfer_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer_gt?: InputMaybe<Scalars['String']>;
  nglTransfer_gte?: InputMaybe<Scalars['String']>;
  nglTransfer_in?: InputMaybe<Array<Scalars['String']>>;
  nglTransfer_lt?: InputMaybe<Scalars['String']>;
  nglTransfer_lte?: InputMaybe<Scalars['String']>;
  nglTransfer_not?: InputMaybe<Scalars['String']>;
  nglTransfer_not_contains?: InputMaybe<Scalars['String']>;
  nglTransfer_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer_not_ends_with?: InputMaybe<Scalars['String']>;
  nglTransfer_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer_not_in?: InputMaybe<Array<Scalars['String']>>;
  nglTransfer_not_starts_with?: InputMaybe<Scalars['String']>;
  nglTransfer_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nglTransfer_starts_with?: InputMaybe<Scalars['String']>;
  nglTransfer_starts_with_nocase?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_in?: InputMaybe<Array<TransactionType>>;
  transactionType_not?: InputMaybe<TransactionType>;
  transactionType_not_in?: InputMaybe<Array<TransactionType>>;
};

export enum TransactionRecord_OrderBy {
  Address = 'address',
  CounterAddress = 'counterAddress',
  Id = 'id',
  ItemTransfer = 'itemTransfer',
  NglTransfer = 'nglTransfer',
  Timestamp = 'timestamp',
  TransactionType = 'transactionType'
}

export enum TransactionType {
  ItemReceived = 'ITEM_RECEIVED',
  ItemSent = 'ITEM_SENT',
  MaticPurchased = 'MATIC_PURCHASED',
  NglPurchased = 'NGL_PURCHASED',
  NglReceived = 'NGL_RECEIVED',
  NglSent = 'NGL_SENT'
}

export type Transaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Transaction_OrderBy {
  BlockNumber = 'blockNumber',
  Events = 'events',
  Id = 'id',
  Timestamp = 'timestamp'
}

export type WithdrawRequest = {
  __typename?: 'WithdrawRequest';
  hiring: Hiring;
  id: Scalars['ID'];
  itemOwner: Account;
  status: WithdrawRequestStatus;
  tokenId?: Maybe<Scalars['BigInt']>;
};

export enum WithdrawRequestStatus {
  Requested = 'REQUESTED'
}

export type WithdrawRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  hiring?: InputMaybe<Scalars['String']>;
  hiring_contains?: InputMaybe<Scalars['String']>;
  hiring_contains_nocase?: InputMaybe<Scalars['String']>;
  hiring_ends_with?: InputMaybe<Scalars['String']>;
  hiring_ends_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_gt?: InputMaybe<Scalars['String']>;
  hiring_gte?: InputMaybe<Scalars['String']>;
  hiring_in?: InputMaybe<Array<Scalars['String']>>;
  hiring_lt?: InputMaybe<Scalars['String']>;
  hiring_lte?: InputMaybe<Scalars['String']>;
  hiring_not?: InputMaybe<Scalars['String']>;
  hiring_not_contains?: InputMaybe<Scalars['String']>;
  hiring_not_contains_nocase?: InputMaybe<Scalars['String']>;
  hiring_not_ends_with?: InputMaybe<Scalars['String']>;
  hiring_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_not_in?: InputMaybe<Array<Scalars['String']>>;
  hiring_not_starts_with?: InputMaybe<Scalars['String']>;
  hiring_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  hiring_starts_with?: InputMaybe<Scalars['String']>;
  hiring_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  itemOwner?: InputMaybe<Scalars['String']>;
  itemOwner_contains?: InputMaybe<Scalars['String']>;
  itemOwner_contains_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_ends_with?: InputMaybe<Scalars['String']>;
  itemOwner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_gt?: InputMaybe<Scalars['String']>;
  itemOwner_gte?: InputMaybe<Scalars['String']>;
  itemOwner_in?: InputMaybe<Array<Scalars['String']>>;
  itemOwner_lt?: InputMaybe<Scalars['String']>;
  itemOwner_lte?: InputMaybe<Scalars['String']>;
  itemOwner_not?: InputMaybe<Scalars['String']>;
  itemOwner_not_contains?: InputMaybe<Scalars['String']>;
  itemOwner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_not_ends_with?: InputMaybe<Scalars['String']>;
  itemOwner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_not_in?: InputMaybe<Array<Scalars['String']>>;
  itemOwner_not_starts_with?: InputMaybe<Scalars['String']>;
  itemOwner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  itemOwner_starts_with?: InputMaybe<Scalars['String']>;
  itemOwner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WithdrawRequestStatus>;
  status_in?: InputMaybe<Array<WithdrawRequestStatus>>;
  status_not?: InputMaybe<WithdrawRequestStatus>;
  status_not_in?: InputMaybe<Array<WithdrawRequestStatus>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum WithdrawRequest_OrderBy {
  Hiring = 'hiring',
  Id = 'id',
  ItemOwner = 'itemOwner',
  Status = 'status',
  TokenId = 'tokenId'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type GetAccountTokensQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type GetAccountTokensQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, ERC721tokens: Array<{ __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined, owner: { __typename?: 'Account', id: string } } | null | undefined> } | null | undefined };

export type GetListingsQueryVariables = Exact<{
  status: ListingStatus;
}>;


export type GetListingsQuery = { __typename?: 'Query', listings: Array<{ __typename?: 'Listing', id: string, price: any, status: ListingStatus, buyer?: { __typename?: 'Account', id: string } | null | undefined, seller: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> };

export type GetAccountListingsQueryVariables = Exact<{
  accountId: Scalars['ID'];
  status?: InputMaybe<ListingStatus>;
}>;


export type GetAccountListingsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, listings: Array<{ __typename?: 'Listing', id: string, price: any, status: ListingStatus, buyer?: { __typename?: 'Account', id: string } | null | undefined, seller: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> } | null | undefined };

export type GetAccountRentsQueryVariables = Exact<{
  accountId: Scalars['ID'];
  status: LeaseStatus;
}>;


export type GetAccountRentsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, rents: Array<{ __typename?: 'Lease', expiry?: any | null | undefined, id: string, price: any, status: LeaseStatus, duration: any, owner: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> } | null | undefined };

export type GetAccountLeasesQueryVariables = Exact<{
  accountId: Scalars['ID'];
  status?: InputMaybe<LeaseStatus>;
}>;


export type GetAccountLeasesQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, leases: Array<{ __typename?: 'Lease', expiry?: any | null | undefined, id: string, price: any, status: LeaseStatus, duration: any, renter?: { __typename?: 'Account', id: string } | null | undefined, owner: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> } | null | undefined };

export type GetLeasesQueryVariables = Exact<{
  status: LeaseStatus;
}>;


export type GetLeasesQuery = { __typename?: 'Query', leases: Array<{ __typename?: 'Lease', id: string, price: any, status: LeaseStatus, duration: any, owner: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> };

export type GetRightOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRightOptionsQuery = { __typename?: 'Query', rightOptions: Array<{ __typename?: 'RightOption', id: string, rightId?: any | null | undefined, rightType?: any | null | undefined, price: any, duration: any, stakeDuration: any }> };

export type GetUnexpiredRightPurchasesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUnexpiredRightPurchasesQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, rightPurchases: Array<{ __typename?: 'RightPurchase', id: string, status: RightOptionStatus, expiry?: any | null | undefined, duration: any, price?: any | null | undefined, rightOptionId?: any | null | undefined, stakeExpiry: any, stakeDuration: any, rightOption: { __typename?: 'RightOption', rightId?: any | null | undefined, rightType?: any | null | undefined } }> } | null | undefined };

export type GetBuildingsByAccountQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type GetBuildingsByAccountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, ERC721tokens: Array<{ __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined, owner: { __typename?: 'Account', id: string } } | null | undefined> } | null | undefined };

export type GetAccountTokensNotContainTypeIdsQueryVariables = Exact<{
  accountId: Scalars['ID'];
  itemTypeId_not_in?: InputMaybe<Array<Scalars['BigInt']> | Scalars['BigInt']>;
}>;


export type GetAccountTokensNotContainTypeIdsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', ERC721tokens: Array<{ __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined, owner: { __typename?: 'Account', id: string } } | null | undefined> } | null | undefined };

export type GetAccountHiringsQueryVariables = Exact<{
  accountId: Scalars['ID'];
  status: HiringStatus;
}>;


export type GetAccountHiringsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, hirings: Array<{ __typename?: 'Hiring', id: string, status: HiringStatus, rentPercentage: any, ticketPercentage: any, buildingItem: { __typename?: 'ERC721Token', id: string, identifier: any, itemTypeId?: any | null | undefined, uri?: string | null | undefined }, buildingOwner: { __typename?: 'Account', id: string }, rentableItems: Array<{ __typename?: 'RentableItem', id: string, itemOwner: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, identifier: any, itemTypeId?: any | null | undefined }, hiring: { __typename?: 'Hiring', id: string, status: HiringStatus } }> }> } | null | undefined };

export type GetAccountRentableItemsQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type GetAccountRentableItemsQuery = { __typename?: 'Query', account?: { __typename?: 'Account', rentableItems: Array<{ __typename?: 'RentableItem', id: string, itemOwner: { __typename?: 'Account', id: string }, token: { __typename?: 'ERC721Token', id: string, identifier: any, itemTypeId?: any | null | undefined }, hiring: { __typename?: 'Hiring', id: string, status: HiringStatus } }> } | null | undefined };

export type GetWalletHistoryQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type GetWalletHistoryQuery = { __typename?: 'Query', transactionRecords: Array<{ __typename?: 'TransactionRecord', id: string, transactionType: TransactionType, timestamp: any, address: { __typename?: 'Account', id: string }, counterAddress?: { __typename?: 'Account', id: string } | null | undefined, itemTransfer?: { __typename?: 'ERC721Transfer', token: { __typename?: 'ERC721Token', identifier: any, itemTypeId?: any | null | undefined, contract: { __typename?: 'ERC721Contract', name?: string | null | undefined } } } | null | undefined, nglTransfer?: { __typename?: 'ERC20Transfer', value: any } | null | undefined }> };

export type GetAllAuctionsQueryVariables = Exact<{
  orderBy: Auction_OrderBy;
  orderDirection: OrderDirection;
}>;


export type GetAllAuctionsQuery = { __typename?: 'Query', auctions: Array<{ __typename?: 'Auction', id: string, status: AuctionStatus, highestPrice: any, startDate: any, endDate: any, biddingStep: any, nft: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined }, highestBidder?: { __typename?: 'Account', id: string } | null | undefined }> };

export type GetMyAuctionsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetMyAuctionsQuery = { __typename?: 'Query', auctionParticipations: Array<{ __typename?: 'AuctionParticipation', auction: { __typename?: 'Auction', id: string, status: AuctionStatus, highestPrice: any, startDate: any, endDate: any, biddingStep: any, nft: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined }, highestBidder?: { __typename?: 'Account', id: string } | null | undefined } }> };

export type GetWithdrawRequestItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWithdrawRequestItemQuery = { __typename?: 'Query', withdrawRequest?: { __typename?: 'WithdrawRequest', id: string, status: WithdrawRequestStatus, itemOwner: { __typename?: 'Account', id: string }, hiring: { __typename?: 'Hiring', id: string, status: HiringStatus } } | null | undefined };

export type GetMaskBoxesQueryVariables = Exact<{
  accountId: Scalars['String'];
  status: MaskBoxStatus;
}>;


export type GetMaskBoxesQuery = { __typename?: 'Query', maskBoxes: Array<{ __typename?: 'MaskBox', boxId: any, status: MaskBoxStatus, allMaskPartIds?: Array<any> | null | undefined, owner: { __typename?: 'Account', id: string } }> };

export type GetMiningClaimQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetMiningClaimQuery = { __typename?: 'Query', miningClaims: Array<{ __typename?: 'MiningClaim', id: string, arenaHour: any, nglAmount: any, maxMiners: any, owner: { __typename?: 'Account', id: string }, arenas: Array<{ __typename?: 'Arena', arenaId: any, expiry: any, status: ArenaStatus }>, nft: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined } }> };

export type GetRightMerchantLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRightMerchantLimitQuery = { __typename?: 'Query', merchantLimits: Array<{ __typename?: 'MerchantLimit', id: string, rightId: any, companion: any, donkey: any, horse: any, dog: any, boatAndPlane: any, boat: any, plane: any, building: any, similarBuilding: any, sameBaseBuilding: any }> };

export type GetArenaByMiningClaimIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetArenaByMiningClaimIdQuery = { __typename?: 'Query', miningClaim?: { __typename?: 'MiningClaim', arenas: Array<{ __typename?: 'Arena', id: string, expiry: any, status: ArenaStatus, duration: any }> } | null | undefined };

export type GetMaskForgedQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetMaskForgedQuery = { __typename?: 'Query', masks: Array<{ __typename?: 'Mask', id: string, status: string, maskShapeTypeId: any, maskMaterialTypeId: any, topElementTypeId: any, frontElementTypeId: any, scratchesTypeId: any, paintOverTypeId: any, itemTypeId?: any | null | undefined }> };

export type GetMaskForgedByIdQueryVariables = Exact<{
  itemId: Scalars['ID'];
}>;


export type GetMaskForgedByIdQuery = { __typename?: 'Query', masks: Array<{ __typename?: 'Mask', id: string, status: string, maskShapeTypeId: any, maskMaterialTypeId: any, topElementTypeId: any, frontElementTypeId: any, scratchesTypeId: any, paintOverTypeId: any, itemTypeId?: any | null | undefined }> };

export type GetCharactersQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetCharactersQuery = { __typename?: 'Query', characters: Array<{ __typename?: 'Character', id: string, name: string, isFreeCharacter: boolean }> };

export type GetAttachItemsQueryVariables = Exact<{
  itemId: Scalars['BigInt'];
}>;


export type GetAttachItemsQuery = { __typename?: 'Query', attachItems: Array<{ __typename?: 'AttachItem', id: string, characterId: any, status: string, token: { __typename?: 'ERC721Token', id: string, itemTypeId?: any | null | undefined, identifier: any }, owner: { __typename?: 'Account', id: string } }> };

export type GetMyCollateralOffersQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetMyCollateralOffersQuery = { __typename?: 'Query', collateralOffers: Array<{ __typename?: 'CollateralOffer', id: string, expiry?: any | null | undefined, status: CollateralOfferStatus, loanValue: any, fee: any, duration: any, loaner?: { __typename?: 'Account', id: string } | null | undefined, token: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined }, loanee: { __typename?: 'Account', id: string }, counterOfferList: Array<{ __typename?: 'CounterCollateralOffer', id: string, fee: any, offerer: { __typename?: 'Account', id: string }, collateralOffer: { __typename?: 'CollateralOffer', id: string } }> }> };

export type GetAllCollateralOffersQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetAllCollateralOffersQuery = { __typename?: 'Query', collateralOffers: Array<{ __typename?: 'CollateralOffer', id: string, expiry?: any | null | undefined, status: CollateralOfferStatus, loanValue: any, fee: any, duration: any, loaner?: { __typename?: 'Account', id: string } | null | undefined, token: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined }, loanee: { __typename?: 'Account', id: string }, counterOfferList: Array<{ __typename?: 'CounterCollateralOffer', id: string, fee: any, offerer: { __typename?: 'Account', id: string }, collateralOffer: { __typename?: 'CollateralOffer', id: string } }> }> };

export type GetAllCollateralOffersExpiredByAccountQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;


export type GetAllCollateralOffersExpiredByAccountQuery = { __typename?: 'Query', collateralOffers: Array<{ __typename?: 'CollateralOffer', id: string, expiry?: any | null | undefined, status: CollateralOfferStatus, loanValue: any, fee: any, duration: any, loaner?: { __typename?: 'Account', id: string } | null | undefined, token: { __typename?: 'ERC721Token', id: string, identifier: any, uri?: string | null | undefined, itemTypeId?: any | null | undefined }, loanee: { __typename?: 'Account', id: string }, counterOfferList: Array<{ __typename?: 'CounterCollateralOffer', id: string, fee: any, offerer: { __typename?: 'Account', id: string }, collateralOffer: { __typename?: 'CollateralOffer', id: string } }> }> };


export const GetAccountTokensDocument = gql`
    query getAccountTokens($accountId: ID!) {
  account(id: $accountId) {
    id
    ERC721tokens(first: 1000) {
      id
      identifier
      uri
      itemTypeId
      owner {
        id
      }
    }
  }
}
    `;
export type GetAccountTokensComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTokensQuery, GetAccountTokensQueryVariables>, 'query'> & ({ variables: GetAccountTokensQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTokensComponent = (props: GetAccountTokensComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTokensQuery, GetAccountTokensQueryVariables> query={GetAccountTokensDocument} {...props} />
    );
    

/**
 * __useGetAccountTokensQuery__
 *
 * To run a query within a React component, call `useGetAccountTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTokensQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountTokensQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTokensQuery, GetAccountTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTokensQuery, GetAccountTokensQueryVariables>(GetAccountTokensDocument, options);
      }
export function useGetAccountTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTokensQuery, GetAccountTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTokensQuery, GetAccountTokensQueryVariables>(GetAccountTokensDocument, options);
        }
export type GetAccountTokensQueryHookResult = ReturnType<typeof useGetAccountTokensQuery>;
export type GetAccountTokensLazyQueryHookResult = ReturnType<typeof useGetAccountTokensLazyQuery>;
export type GetAccountTokensQueryResult = Apollo.QueryResult<GetAccountTokensQuery, GetAccountTokensQueryVariables>;
export const GetListingsDocument = gql`
    query getListings($status: ListingStatus!) {
  listings(where: {status: $status}) {
    id
    buyer {
      id
    }
    seller {
      id
    }
    price
    status
    token {
      id
      uri
      itemTypeId
    }
  }
}
    `;
export type GetListingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetListingsQuery, GetListingsQueryVariables>, 'query'> & ({ variables: GetListingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetListingsComponent = (props: GetListingsComponentProps) => (
      <ApolloReactComponents.Query<GetListingsQuery, GetListingsQueryVariables> query={GetListingsDocument} {...props} />
    );
    

/**
 * __useGetListingsQuery__
 *
 * To run a query within a React component, call `useGetListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetListingsQuery(baseOptions: Apollo.QueryHookOptions<GetListingsQuery, GetListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListingsQuery, GetListingsQueryVariables>(GetListingsDocument, options);
      }
export function useGetListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListingsQuery, GetListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListingsQuery, GetListingsQueryVariables>(GetListingsDocument, options);
        }
export type GetListingsQueryHookResult = ReturnType<typeof useGetListingsQuery>;
export type GetListingsLazyQueryHookResult = ReturnType<typeof useGetListingsLazyQuery>;
export type GetListingsQueryResult = Apollo.QueryResult<GetListingsQuery, GetListingsQueryVariables>;
export const GetAccountListingsDocument = gql`
    query getAccountListings($accountId: ID!, $status: ListingStatus) {
  account(id: $accountId) {
    id
    listings(where: {status: $status}) {
      id
      buyer {
        id
      }
      seller {
        id
      }
      price
      status
      token {
        id
        uri
        itemTypeId
      }
    }
  }
}
    `;
export type GetAccountListingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountListingsQuery, GetAccountListingsQueryVariables>, 'query'> & ({ variables: GetAccountListingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountListingsComponent = (props: GetAccountListingsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountListingsQuery, GetAccountListingsQueryVariables> query={GetAccountListingsDocument} {...props} />
    );
    

/**
 * __useGetAccountListingsQuery__
 *
 * To run a query within a React component, call `useGetAccountListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountListingsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAccountListingsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountListingsQuery, GetAccountListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountListingsQuery, GetAccountListingsQueryVariables>(GetAccountListingsDocument, options);
      }
export function useGetAccountListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountListingsQuery, GetAccountListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountListingsQuery, GetAccountListingsQueryVariables>(GetAccountListingsDocument, options);
        }
export type GetAccountListingsQueryHookResult = ReturnType<typeof useGetAccountListingsQuery>;
export type GetAccountListingsLazyQueryHookResult = ReturnType<typeof useGetAccountListingsLazyQuery>;
export type GetAccountListingsQueryResult = Apollo.QueryResult<GetAccountListingsQuery, GetAccountListingsQueryVariables>;
export const GetAccountRentsDocument = gql`
    query getAccountRents($accountId: ID!, $status: LeaseStatus!) {
  account(id: $accountId) {
    id
    rents(where: {status: $status}) {
      expiry
      id
      price
      status
      duration
      owner {
        id
      }
      token {
        id
        identifier
        uri
        itemTypeId
      }
    }
  }
}
    `;
export type GetAccountRentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountRentsQuery, GetAccountRentsQueryVariables>, 'query'> & ({ variables: GetAccountRentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountRentsComponent = (props: GetAccountRentsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountRentsQuery, GetAccountRentsQueryVariables> query={GetAccountRentsDocument} {...props} />
    );
    

/**
 * __useGetAccountRentsQuery__
 *
 * To run a query within a React component, call `useGetAccountRentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRentsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAccountRentsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountRentsQuery, GetAccountRentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountRentsQuery, GetAccountRentsQueryVariables>(GetAccountRentsDocument, options);
      }
export function useGetAccountRentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRentsQuery, GetAccountRentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountRentsQuery, GetAccountRentsQueryVariables>(GetAccountRentsDocument, options);
        }
export type GetAccountRentsQueryHookResult = ReturnType<typeof useGetAccountRentsQuery>;
export type GetAccountRentsLazyQueryHookResult = ReturnType<typeof useGetAccountRentsLazyQuery>;
export type GetAccountRentsQueryResult = Apollo.QueryResult<GetAccountRentsQuery, GetAccountRentsQueryVariables>;
export const GetAccountLeasesDocument = gql`
    query getAccountLeases($accountId: ID!, $status: LeaseStatus) {
  account(id: $accountId) {
    id
    leases(where: {status: $status}) {
      expiry
      id
      price
      status
      duration
      renter {
        id
      }
      owner {
        id
      }
      token {
        id
        identifier
        uri
        itemTypeId
      }
    }
  }
}
    `;
export type GetAccountLeasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>, 'query'> & ({ variables: GetAccountLeasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountLeasesComponent = (props: GetAccountLeasesComponentProps) => (
      <ApolloReactComponents.Query<GetAccountLeasesQuery, GetAccountLeasesQueryVariables> query={GetAccountLeasesDocument} {...props} />
    );
    

/**
 * __useGetAccountLeasesQuery__
 *
 * To run a query within a React component, call `useGetAccountLeasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLeasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLeasesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAccountLeasesQuery(baseOptions: Apollo.QueryHookOptions<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>(GetAccountLeasesDocument, options);
      }
export function useGetAccountLeasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>(GetAccountLeasesDocument, options);
        }
export type GetAccountLeasesQueryHookResult = ReturnType<typeof useGetAccountLeasesQuery>;
export type GetAccountLeasesLazyQueryHookResult = ReturnType<typeof useGetAccountLeasesLazyQuery>;
export type GetAccountLeasesQueryResult = Apollo.QueryResult<GetAccountLeasesQuery, GetAccountLeasesQueryVariables>;
export const GetLeasesDocument = gql`
    query getLeases($status: LeaseStatus!) {
  leases(where: {status: $status}) {
    id
    price
    status
    duration
    owner {
      id
    }
    token {
      id
      uri
      itemTypeId
    }
  }
}
    `;
export type GetLeasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLeasesQuery, GetLeasesQueryVariables>, 'query'> & ({ variables: GetLeasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLeasesComponent = (props: GetLeasesComponentProps) => (
      <ApolloReactComponents.Query<GetLeasesQuery, GetLeasesQueryVariables> query={GetLeasesDocument} {...props} />
    );
    

/**
 * __useGetLeasesQuery__
 *
 * To run a query within a React component, call `useGetLeasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeasesQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetLeasesQuery(baseOptions: Apollo.QueryHookOptions<GetLeasesQuery, GetLeasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeasesQuery, GetLeasesQueryVariables>(GetLeasesDocument, options);
      }
export function useGetLeasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeasesQuery, GetLeasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeasesQuery, GetLeasesQueryVariables>(GetLeasesDocument, options);
        }
export type GetLeasesQueryHookResult = ReturnType<typeof useGetLeasesQuery>;
export type GetLeasesLazyQueryHookResult = ReturnType<typeof useGetLeasesLazyQuery>;
export type GetLeasesQueryResult = Apollo.QueryResult<GetLeasesQuery, GetLeasesQueryVariables>;
export const GetRightOptionsDocument = gql`
    query getRightOptions {
  rightOptions {
    id
    rightId
    rightType
    price
    duration
    stakeDuration
  }
}
    `;
export type GetRightOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRightOptionsQuery, GetRightOptionsQueryVariables>, 'query'>;

    export const GetRightOptionsComponent = (props: GetRightOptionsComponentProps) => (
      <ApolloReactComponents.Query<GetRightOptionsQuery, GetRightOptionsQueryVariables> query={GetRightOptionsDocument} {...props} />
    );
    

/**
 * __useGetRightOptionsQuery__
 *
 * To run a query within a React component, call `useGetRightOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRightOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRightOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRightOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRightOptionsQuery, GetRightOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRightOptionsQuery, GetRightOptionsQueryVariables>(GetRightOptionsDocument, options);
      }
export function useGetRightOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRightOptionsQuery, GetRightOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRightOptionsQuery, GetRightOptionsQueryVariables>(GetRightOptionsDocument, options);
        }
export type GetRightOptionsQueryHookResult = ReturnType<typeof useGetRightOptionsQuery>;
export type GetRightOptionsLazyQueryHookResult = ReturnType<typeof useGetRightOptionsLazyQuery>;
export type GetRightOptionsQueryResult = Apollo.QueryResult<GetRightOptionsQuery, GetRightOptionsQueryVariables>;
export const GetUnexpiredRightPurchasesDocument = gql`
    query getUnexpiredRightPurchases($id: ID!) {
  account(id: $id) {
    id
    rightPurchases(where: {status_not: FINALIZED}) {
      id
      status
      expiry
      duration
      price
      rightOptionId
      stakeExpiry
      stakeDuration
      rightOption {
        rightId
        rightType
      }
    }
  }
}
    `;
export type GetUnexpiredRightPurchasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>, 'query'> & ({ variables: GetUnexpiredRightPurchasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnexpiredRightPurchasesComponent = (props: GetUnexpiredRightPurchasesComponentProps) => (
      <ApolloReactComponents.Query<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables> query={GetUnexpiredRightPurchasesDocument} {...props} />
    );
    

/**
 * __useGetUnexpiredRightPurchasesQuery__
 *
 * To run a query within a React component, call `useGetUnexpiredRightPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnexpiredRightPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnexpiredRightPurchasesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnexpiredRightPurchasesQuery(baseOptions: Apollo.QueryHookOptions<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>(GetUnexpiredRightPurchasesDocument, options);
      }
export function useGetUnexpiredRightPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>(GetUnexpiredRightPurchasesDocument, options);
        }
export type GetUnexpiredRightPurchasesQueryHookResult = ReturnType<typeof useGetUnexpiredRightPurchasesQuery>;
export type GetUnexpiredRightPurchasesLazyQueryHookResult = ReturnType<typeof useGetUnexpiredRightPurchasesLazyQuery>;
export type GetUnexpiredRightPurchasesQueryResult = Apollo.QueryResult<GetUnexpiredRightPurchasesQuery, GetUnexpiredRightPurchasesQueryVariables>;
export const GetBuildingsByAccountDocument = gql`
    query getBuildingsByAccount($accountId: ID!) {
  account(id: $accountId) {
    id
    ERC721tokens(where: {itemTypeId_gte: 181010, itemTypeId_lte: 183810}) {
      id
      identifier
      uri
      itemTypeId
      owner {
        id
      }
    }
  }
}
    `;
export type GetBuildingsByAccountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>, 'query'> & ({ variables: GetBuildingsByAccountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBuildingsByAccountComponent = (props: GetBuildingsByAccountComponentProps) => (
      <ApolloReactComponents.Query<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables> query={GetBuildingsByAccountDocument} {...props} />
    );
    

/**
 * __useGetBuildingsByAccountQuery__
 *
 * To run a query within a React component, call `useGetBuildingsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetBuildingsByAccountQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>(GetBuildingsByAccountDocument, options);
      }
export function useGetBuildingsByAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>(GetBuildingsByAccountDocument, options);
        }
export type GetBuildingsByAccountQueryHookResult = ReturnType<typeof useGetBuildingsByAccountQuery>;
export type GetBuildingsByAccountLazyQueryHookResult = ReturnType<typeof useGetBuildingsByAccountLazyQuery>;
export type GetBuildingsByAccountQueryResult = Apollo.QueryResult<GetBuildingsByAccountQuery, GetBuildingsByAccountQueryVariables>;
export const GetAccountTokensNotContainTypeIdsDocument = gql`
    query getAccountTokensNotContainTypeIds($accountId: ID!, $itemTypeId_not_in: [BigInt!]) {
  account(id: $accountId) {
    ERC721tokens(where: {itemTypeId_not_in: $itemTypeId_not_in}) {
      id
      identifier
      uri
      itemTypeId
      owner {
        id
      }
    }
  }
}
    `;
export type GetAccountTokensNotContainTypeIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>, 'query'> & ({ variables: GetAccountTokensNotContainTypeIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountTokensNotContainTypeIdsComponent = (props: GetAccountTokensNotContainTypeIdsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables> query={GetAccountTokensNotContainTypeIdsDocument} {...props} />
    );
    

/**
 * __useGetAccountTokensNotContainTypeIdsQuery__
 *
 * To run a query within a React component, call `useGetAccountTokensNotContainTypeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTokensNotContainTypeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTokensNotContainTypeIdsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      itemTypeId_not_in: // value for 'itemTypeId_not_in'
 *   },
 * });
 */
export function useGetAccountTokensNotContainTypeIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>(GetAccountTokensNotContainTypeIdsDocument, options);
      }
export function useGetAccountTokensNotContainTypeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>(GetAccountTokensNotContainTypeIdsDocument, options);
        }
export type GetAccountTokensNotContainTypeIdsQueryHookResult = ReturnType<typeof useGetAccountTokensNotContainTypeIdsQuery>;
export type GetAccountTokensNotContainTypeIdsLazyQueryHookResult = ReturnType<typeof useGetAccountTokensNotContainTypeIdsLazyQuery>;
export type GetAccountTokensNotContainTypeIdsQueryResult = Apollo.QueryResult<GetAccountTokensNotContainTypeIdsQuery, GetAccountTokensNotContainTypeIdsQueryVariables>;
export const GetAccountHiringsDocument = gql`
    query getAccountHirings($accountId: ID!, $status: HiringStatus!) {
  account(id: $accountId) {
    id
    hirings(where: {status: $status}) {
      id
      status
      rentPercentage
      ticketPercentage
      buildingItem {
        id
        identifier
        itemTypeId
        uri
      }
      buildingOwner {
        id
      }
      rentableItems {
        id
        itemOwner {
          id
        }
        token {
          id
          identifier
          itemTypeId
        }
        hiring {
          id
          status
        }
      }
    }
  }
}
    `;
export type GetAccountHiringsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>, 'query'> & ({ variables: GetAccountHiringsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountHiringsComponent = (props: GetAccountHiringsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountHiringsQuery, GetAccountHiringsQueryVariables> query={GetAccountHiringsDocument} {...props} />
    );
    

/**
 * __useGetAccountHiringsQuery__
 *
 * To run a query within a React component, call `useGetAccountHiringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountHiringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountHiringsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAccountHiringsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>(GetAccountHiringsDocument, options);
      }
export function useGetAccountHiringsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>(GetAccountHiringsDocument, options);
        }
export type GetAccountHiringsQueryHookResult = ReturnType<typeof useGetAccountHiringsQuery>;
export type GetAccountHiringsLazyQueryHookResult = ReturnType<typeof useGetAccountHiringsLazyQuery>;
export type GetAccountHiringsQueryResult = Apollo.QueryResult<GetAccountHiringsQuery, GetAccountHiringsQueryVariables>;
export const GetAccountRentableItemsDocument = gql`
    query getAccountRentableItems($accountId: ID!) {
  account(id: $accountId) {
    rentableItems {
      id
      itemOwner {
        id
      }
      token {
        id
        identifier
        itemTypeId
      }
      hiring {
        id
        status
      }
    }
  }
}
    `;
export type GetAccountRentableItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>, 'query'> & ({ variables: GetAccountRentableItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAccountRentableItemsComponent = (props: GetAccountRentableItemsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables> query={GetAccountRentableItemsDocument} {...props} />
    );
    

/**
 * __useGetAccountRentableItemsQuery__
 *
 * To run a query within a React component, call `useGetAccountRentableItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRentableItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRentableItemsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountRentableItemsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>(GetAccountRentableItemsDocument, options);
      }
export function useGetAccountRentableItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>(GetAccountRentableItemsDocument, options);
        }
export type GetAccountRentableItemsQueryHookResult = ReturnType<typeof useGetAccountRentableItemsQuery>;
export type GetAccountRentableItemsLazyQueryHookResult = ReturnType<typeof useGetAccountRentableItemsLazyQuery>;
export type GetAccountRentableItemsQueryResult = Apollo.QueryResult<GetAccountRentableItemsQuery, GetAccountRentableItemsQueryVariables>;
export const GetWalletHistoryDocument = gql`
    query getWalletHistory($address: String!) {
  transactionRecords(where: {address: $address}) {
    id
    address {
      id
    }
    counterAddress {
      id
    }
    transactionType
    timestamp
    itemTransfer {
      token {
        identifier
        itemTypeId
        contract {
          name
        }
      }
    }
    nglTransfer {
      value
    }
  }
}
    `;
export type GetWalletHistoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>, 'query'> & ({ variables: GetWalletHistoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWalletHistoryComponent = (props: GetWalletHistoryComponentProps) => (
      <ApolloReactComponents.Query<GetWalletHistoryQuery, GetWalletHistoryQueryVariables> query={GetWalletHistoryDocument} {...props} />
    );
    

/**
 * __useGetWalletHistoryQuery__
 *
 * To run a query within a React component, call `useGetWalletHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletHistoryQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGetWalletHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>(GetWalletHistoryDocument, options);
      }
export function useGetWalletHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>(GetWalletHistoryDocument, options);
        }
export type GetWalletHistoryQueryHookResult = ReturnType<typeof useGetWalletHistoryQuery>;
export type GetWalletHistoryLazyQueryHookResult = ReturnType<typeof useGetWalletHistoryLazyQuery>;
export type GetWalletHistoryQueryResult = Apollo.QueryResult<GetWalletHistoryQuery, GetWalletHistoryQueryVariables>;
export const GetAllAuctionsDocument = gql`
    query getAllAuctions($orderBy: Auction_orderBy!, $orderDirection: OrderDirection!) {
  auctions(
    where: {status_not: FINISHED}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    id
    nft {
      id
      identifier
      uri
      itemTypeId
    }
    status
    highestBidder {
      id
    }
    highestPrice
    startDate
    endDate
    biddingStep
  }
}
    `;
export type GetAllAuctionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>, 'query'> & ({ variables: GetAllAuctionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAllAuctionsComponent = (props: GetAllAuctionsComponentProps) => (
      <ApolloReactComponents.Query<GetAllAuctionsQuery, GetAllAuctionsQueryVariables> query={GetAllAuctionsDocument} {...props} />
    );
    

/**
 * __useGetAllAuctionsQuery__
 *
 * To run a query within a React component, call `useGetAllAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAuctionsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetAllAuctionsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>(GetAllAuctionsDocument, options);
      }
export function useGetAllAuctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>(GetAllAuctionsDocument, options);
        }
export type GetAllAuctionsQueryHookResult = ReturnType<typeof useGetAllAuctionsQuery>;
export type GetAllAuctionsLazyQueryHookResult = ReturnType<typeof useGetAllAuctionsLazyQuery>;
export type GetAllAuctionsQueryResult = Apollo.QueryResult<GetAllAuctionsQuery, GetAllAuctionsQueryVariables>;
export const GetMyAuctionsDocument = gql`
    query getMyAuctions($accountId: String!) {
  auctionParticipations(where: {bidder: $accountId}) {
    auction {
      id
      nft {
        id
        identifier
        uri
        itemTypeId
      }
      status
      highestBidder {
        id
      }
      highestPrice
      startDate
      endDate
      biddingStep
    }
  }
}
    `;
export type GetMyAuctionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>, 'query'> & ({ variables: GetMyAuctionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMyAuctionsComponent = (props: GetMyAuctionsComponentProps) => (
      <ApolloReactComponents.Query<GetMyAuctionsQuery, GetMyAuctionsQueryVariables> query={GetMyAuctionsDocument} {...props} />
    );
    

/**
 * __useGetMyAuctionsQuery__
 *
 * To run a query within a React component, call `useGetMyAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAuctionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetMyAuctionsQuery(baseOptions: Apollo.QueryHookOptions<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>(GetMyAuctionsDocument, options);
      }
export function useGetMyAuctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>(GetMyAuctionsDocument, options);
        }
export type GetMyAuctionsQueryHookResult = ReturnType<typeof useGetMyAuctionsQuery>;
export type GetMyAuctionsLazyQueryHookResult = ReturnType<typeof useGetMyAuctionsLazyQuery>;
export type GetMyAuctionsQueryResult = Apollo.QueryResult<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>;
export const GetWithdrawRequestItemDocument = gql`
    query getWithdrawRequestItem($id: ID!) {
  withdrawRequest(id: $id) {
    id
    itemOwner {
      id
    }
    hiring {
      id
      status
    }
    status
  }
}
    `;
export type GetWithdrawRequestItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>, 'query'> & ({ variables: GetWithdrawRequestItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWithdrawRequestItemComponent = (props: GetWithdrawRequestItemComponentProps) => (
      <ApolloReactComponents.Query<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables> query={GetWithdrawRequestItemDocument} {...props} />
    );
    

/**
 * __useGetWithdrawRequestItemQuery__
 *
 * To run a query within a React component, call `useGetWithdrawRequestItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawRequestItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawRequestItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWithdrawRequestItemQuery(baseOptions: Apollo.QueryHookOptions<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>(GetWithdrawRequestItemDocument, options);
      }
export function useGetWithdrawRequestItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>(GetWithdrawRequestItemDocument, options);
        }
export type GetWithdrawRequestItemQueryHookResult = ReturnType<typeof useGetWithdrawRequestItemQuery>;
export type GetWithdrawRequestItemLazyQueryHookResult = ReturnType<typeof useGetWithdrawRequestItemLazyQuery>;
export type GetWithdrawRequestItemQueryResult = Apollo.QueryResult<GetWithdrawRequestItemQuery, GetWithdrawRequestItemQueryVariables>;
export const GetMaskBoxesDocument = gql`
    query getMaskBoxes($accountId: String!, $status: MaskBoxStatus!) {
  maskBoxes(where: {owner: $accountId, status: $status}) {
    boxId
    owner {
      id
    }
    status
    allMaskPartIds
  }
}
    `;
export type GetMaskBoxesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>, 'query'> & ({ variables: GetMaskBoxesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMaskBoxesComponent = (props: GetMaskBoxesComponentProps) => (
      <ApolloReactComponents.Query<GetMaskBoxesQuery, GetMaskBoxesQueryVariables> query={GetMaskBoxesDocument} {...props} />
    );
    

/**
 * __useGetMaskBoxesQuery__
 *
 * To run a query within a React component, call `useGetMaskBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaskBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaskBoxesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMaskBoxesQuery(baseOptions: Apollo.QueryHookOptions<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>(GetMaskBoxesDocument, options);
      }
export function useGetMaskBoxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>(GetMaskBoxesDocument, options);
        }
export type GetMaskBoxesQueryHookResult = ReturnType<typeof useGetMaskBoxesQuery>;
export type GetMaskBoxesLazyQueryHookResult = ReturnType<typeof useGetMaskBoxesLazyQuery>;
export type GetMaskBoxesQueryResult = Apollo.QueryResult<GetMaskBoxesQuery, GetMaskBoxesQueryVariables>;
export const GetMiningClaimDocument = gql`
    query getMiningClaim($accountId: String!) {
  miningClaims(where: {owner: $accountId}) {
    id
    owner {
      id
    }
    arenaHour
    nglAmount
    maxMiners
    arenas {
      arenaId
      expiry
      status
    }
    nft {
      id
      identifier
      uri
      itemTypeId
    }
  }
}
    `;
export type GetMiningClaimComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMiningClaimQuery, GetMiningClaimQueryVariables>, 'query'> & ({ variables: GetMiningClaimQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMiningClaimComponent = (props: GetMiningClaimComponentProps) => (
      <ApolloReactComponents.Query<GetMiningClaimQuery, GetMiningClaimQueryVariables> query={GetMiningClaimDocument} {...props} />
    );
    

/**
 * __useGetMiningClaimQuery__
 *
 * To run a query within a React component, call `useGetMiningClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiningClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiningClaimQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetMiningClaimQuery(baseOptions: Apollo.QueryHookOptions<GetMiningClaimQuery, GetMiningClaimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMiningClaimQuery, GetMiningClaimQueryVariables>(GetMiningClaimDocument, options);
      }
export function useGetMiningClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMiningClaimQuery, GetMiningClaimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMiningClaimQuery, GetMiningClaimQueryVariables>(GetMiningClaimDocument, options);
        }
export type GetMiningClaimQueryHookResult = ReturnType<typeof useGetMiningClaimQuery>;
export type GetMiningClaimLazyQueryHookResult = ReturnType<typeof useGetMiningClaimLazyQuery>;
export type GetMiningClaimQueryResult = Apollo.QueryResult<GetMiningClaimQuery, GetMiningClaimQueryVariables>;
export const GetRightMerchantLimitDocument = gql`
    query getRightMerchantLimit {
  merchantLimits {
    id
    rightId
    companion
    donkey
    horse
    dog
    boatAndPlane
    boat
    plane
    building
    similarBuilding
    sameBaseBuilding
  }
}
    `;
export type GetRightMerchantLimitComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>, 'query'>;

    export const GetRightMerchantLimitComponent = (props: GetRightMerchantLimitComponentProps) => (
      <ApolloReactComponents.Query<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables> query={GetRightMerchantLimitDocument} {...props} />
    );
    

/**
 * __useGetRightMerchantLimitQuery__
 *
 * To run a query within a React component, call `useGetRightMerchantLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRightMerchantLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRightMerchantLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRightMerchantLimitQuery(baseOptions?: Apollo.QueryHookOptions<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>(GetRightMerchantLimitDocument, options);
      }
export function useGetRightMerchantLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>(GetRightMerchantLimitDocument, options);
        }
export type GetRightMerchantLimitQueryHookResult = ReturnType<typeof useGetRightMerchantLimitQuery>;
export type GetRightMerchantLimitLazyQueryHookResult = ReturnType<typeof useGetRightMerchantLimitLazyQuery>;
export type GetRightMerchantLimitQueryResult = Apollo.QueryResult<GetRightMerchantLimitQuery, GetRightMerchantLimitQueryVariables>;
export const GetArenaByMiningClaimIdDocument = gql`
    query getArenaByMiningClaimId($id: ID!) {
  miningClaim(id: $id) {
    arenas(where: {status: STARTED}) {
      id
      expiry
      status
      duration
    }
  }
}
    `;
export type GetArenaByMiningClaimIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>, 'query'> & ({ variables: GetArenaByMiningClaimIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetArenaByMiningClaimIdComponent = (props: GetArenaByMiningClaimIdComponentProps) => (
      <ApolloReactComponents.Query<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables> query={GetArenaByMiningClaimIdDocument} {...props} />
    );
    

/**
 * __useGetArenaByMiningClaimIdQuery__
 *
 * To run a query within a React component, call `useGetArenaByMiningClaimIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArenaByMiningClaimIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArenaByMiningClaimIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArenaByMiningClaimIdQuery(baseOptions: Apollo.QueryHookOptions<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>(GetArenaByMiningClaimIdDocument, options);
      }
export function useGetArenaByMiningClaimIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>(GetArenaByMiningClaimIdDocument, options);
        }
export type GetArenaByMiningClaimIdQueryHookResult = ReturnType<typeof useGetArenaByMiningClaimIdQuery>;
export type GetArenaByMiningClaimIdLazyQueryHookResult = ReturnType<typeof useGetArenaByMiningClaimIdLazyQuery>;
export type GetArenaByMiningClaimIdQueryResult = Apollo.QueryResult<GetArenaByMiningClaimIdQuery, GetArenaByMiningClaimIdQueryVariables>;
export const GetMaskForgedDocument = gql`
    query getMaskForged($accountId: String!) {
  masks(where: {owner: $accountId}) {
    id
    status
    maskShapeTypeId
    maskMaterialTypeId
    topElementTypeId
    frontElementTypeId
    scratchesTypeId
    paintOverTypeId
    itemTypeId
  }
}
    `;
export type GetMaskForgedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMaskForgedQuery, GetMaskForgedQueryVariables>, 'query'> & ({ variables: GetMaskForgedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMaskForgedComponent = (props: GetMaskForgedComponentProps) => (
      <ApolloReactComponents.Query<GetMaskForgedQuery, GetMaskForgedQueryVariables> query={GetMaskForgedDocument} {...props} />
    );
    

/**
 * __useGetMaskForgedQuery__
 *
 * To run a query within a React component, call `useGetMaskForgedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaskForgedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaskForgedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetMaskForgedQuery(baseOptions: Apollo.QueryHookOptions<GetMaskForgedQuery, GetMaskForgedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaskForgedQuery, GetMaskForgedQueryVariables>(GetMaskForgedDocument, options);
      }
export function useGetMaskForgedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaskForgedQuery, GetMaskForgedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaskForgedQuery, GetMaskForgedQueryVariables>(GetMaskForgedDocument, options);
        }
export type GetMaskForgedQueryHookResult = ReturnType<typeof useGetMaskForgedQuery>;
export type GetMaskForgedLazyQueryHookResult = ReturnType<typeof useGetMaskForgedLazyQuery>;
export type GetMaskForgedQueryResult = Apollo.QueryResult<GetMaskForgedQuery, GetMaskForgedQueryVariables>;
export const GetMaskForgedByIdDocument = gql`
    query getMaskForgedById($itemId: ID!) {
  masks(where: {id: $itemId}) {
    id
    status
    maskShapeTypeId
    maskMaterialTypeId
    topElementTypeId
    frontElementTypeId
    scratchesTypeId
    paintOverTypeId
    itemTypeId
  }
}
    `;
export type GetMaskForgedByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>, 'query'> & ({ variables: GetMaskForgedByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMaskForgedByIdComponent = (props: GetMaskForgedByIdComponentProps) => (
      <ApolloReactComponents.Query<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables> query={GetMaskForgedByIdDocument} {...props} />
    );
    

/**
 * __useGetMaskForgedByIdQuery__
 *
 * To run a query within a React component, call `useGetMaskForgedByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaskForgedByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaskForgedByIdQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetMaskForgedByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>(GetMaskForgedByIdDocument, options);
      }
export function useGetMaskForgedByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>(GetMaskForgedByIdDocument, options);
        }
export type GetMaskForgedByIdQueryHookResult = ReturnType<typeof useGetMaskForgedByIdQuery>;
export type GetMaskForgedByIdLazyQueryHookResult = ReturnType<typeof useGetMaskForgedByIdLazyQuery>;
export type GetMaskForgedByIdQueryResult = Apollo.QueryResult<GetMaskForgedByIdQuery, GetMaskForgedByIdQueryVariables>;
export const GetCharactersDocument = gql`
    query getCharacters($accountId: String!) {
  characters(where: {owner: $accountId}) {
    id
    name
    isFreeCharacter
  }
}
    `;
export type GetCharactersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCharactersQuery, GetCharactersQueryVariables>, 'query'> & ({ variables: GetCharactersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCharactersComponent = (props: GetCharactersComponentProps) => (
      <ApolloReactComponents.Query<GetCharactersQuery, GetCharactersQueryVariables> query={GetCharactersDocument} {...props} />
    );
    

/**
 * __useGetCharactersQuery__
 *
 * To run a query within a React component, call `useGetCharactersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCharactersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharactersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCharactersQuery(baseOptions: Apollo.QueryHookOptions<GetCharactersQuery, GetCharactersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCharactersQuery, GetCharactersQueryVariables>(GetCharactersDocument, options);
      }
export function useGetCharactersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCharactersQuery, GetCharactersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCharactersQuery, GetCharactersQueryVariables>(GetCharactersDocument, options);
        }
export type GetCharactersQueryHookResult = ReturnType<typeof useGetCharactersQuery>;
export type GetCharactersLazyQueryHookResult = ReturnType<typeof useGetCharactersLazyQuery>;
export type GetCharactersQueryResult = Apollo.QueryResult<GetCharactersQuery, GetCharactersQueryVariables>;
export const GetAttachItemsDocument = gql`
    query getAttachItems($itemId: BigInt!) {
  attachItems(where: {characterId: $itemId}) {
    id
    characterId
    status
    token {
      id
      itemTypeId
      identifier
    }
    owner {
      id
    }
  }
}
    `;
export type GetAttachItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttachItemsQuery, GetAttachItemsQueryVariables>, 'query'> & ({ variables: GetAttachItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttachItemsComponent = (props: GetAttachItemsComponentProps) => (
      <ApolloReactComponents.Query<GetAttachItemsQuery, GetAttachItemsQueryVariables> query={GetAttachItemsDocument} {...props} />
    );
    

/**
 * __useGetAttachItemsQuery__
 *
 * To run a query within a React component, call `useGetAttachItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachItemsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetAttachItemsQuery(baseOptions: Apollo.QueryHookOptions<GetAttachItemsQuery, GetAttachItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachItemsQuery, GetAttachItemsQueryVariables>(GetAttachItemsDocument, options);
      }
export function useGetAttachItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachItemsQuery, GetAttachItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachItemsQuery, GetAttachItemsQueryVariables>(GetAttachItemsDocument, options);
        }
export type GetAttachItemsQueryHookResult = ReturnType<typeof useGetAttachItemsQuery>;
export type GetAttachItemsLazyQueryHookResult = ReturnType<typeof useGetAttachItemsLazyQuery>;
export type GetAttachItemsQueryResult = Apollo.QueryResult<GetAttachItemsQuery, GetAttachItemsQueryVariables>;
export const GetMyCollateralOffersDocument = gql`
    query getMyCollateralOffers($accountId: String!) {
  collateralOffers(where: {loanee: $accountId}) {
    id
    loaner {
      id
    }
    expiry
    status
    token {
      id
      identifier
      uri
      itemTypeId
    }
    loanee {
      id
    }
    loanValue
    fee
    duration
    counterOfferList {
      id
      offerer {
        id
      }
      fee
      collateralOffer {
        id
      }
    }
  }
}
    `;
export type GetMyCollateralOffersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>, 'query'> & ({ variables: GetMyCollateralOffersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMyCollateralOffersComponent = (props: GetMyCollateralOffersComponentProps) => (
      <ApolloReactComponents.Query<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables> query={GetMyCollateralOffersDocument} {...props} />
    );
    

/**
 * __useGetMyCollateralOffersQuery__
 *
 * To run a query within a React component, call `useGetMyCollateralOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCollateralOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCollateralOffersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetMyCollateralOffersQuery(baseOptions: Apollo.QueryHookOptions<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>(GetMyCollateralOffersDocument, options);
      }
export function useGetMyCollateralOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>(GetMyCollateralOffersDocument, options);
        }
export type GetMyCollateralOffersQueryHookResult = ReturnType<typeof useGetMyCollateralOffersQuery>;
export type GetMyCollateralOffersLazyQueryHookResult = ReturnType<typeof useGetMyCollateralOffersLazyQuery>;
export type GetMyCollateralOffersQueryResult = Apollo.QueryResult<GetMyCollateralOffersQuery, GetMyCollateralOffersQueryVariables>;
export const GetAllCollateralOffersDocument = gql`
    query getAllCollateralOffers($accountId: String!) {
  collateralOffers(where: {loanee_not: $accountId}) {
    id
    loaner {
      id
    }
    expiry
    status
    token {
      id
      identifier
      uri
      itemTypeId
    }
    loanee {
      id
    }
    loanValue
    fee
    duration
    counterOfferList {
      id
      offerer {
        id
      }
      fee
      collateralOffer {
        id
      }
    }
  }
}
    `;
export type GetAllCollateralOffersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>, 'query'> & ({ variables: GetAllCollateralOffersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAllCollateralOffersComponent = (props: GetAllCollateralOffersComponentProps) => (
      <ApolloReactComponents.Query<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables> query={GetAllCollateralOffersDocument} {...props} />
    );
    

/**
 * __useGetAllCollateralOffersQuery__
 *
 * To run a query within a React component, call `useGetAllCollateralOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollateralOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollateralOffersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAllCollateralOffersQuery(baseOptions: Apollo.QueryHookOptions<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>(GetAllCollateralOffersDocument, options);
      }
export function useGetAllCollateralOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>(GetAllCollateralOffersDocument, options);
        }
export type GetAllCollateralOffersQueryHookResult = ReturnType<typeof useGetAllCollateralOffersQuery>;
export type GetAllCollateralOffersLazyQueryHookResult = ReturnType<typeof useGetAllCollateralOffersLazyQuery>;
export type GetAllCollateralOffersQueryResult = Apollo.QueryResult<GetAllCollateralOffersQuery, GetAllCollateralOffersQueryVariables>;
export const GetAllCollateralOffersExpiredByAccountDocument = gql`
    query getAllCollateralOffersExpiredByAccount($accountId: String!) {
  collateralOffers(where: {loaner: $accountId, status: LOANED}) {
    id
    loaner {
      id
    }
    expiry
    status
    token {
      id
      identifier
      uri
      itemTypeId
    }
    loanee {
      id
    }
    loanValue
    fee
    duration
    counterOfferList {
      id
      offerer {
        id
      }
      fee
      collateralOffer {
        id
      }
    }
  }
}
    `;
export type GetAllCollateralOffersExpiredByAccountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>, 'query'> & ({ variables: GetAllCollateralOffersExpiredByAccountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAllCollateralOffersExpiredByAccountComponent = (props: GetAllCollateralOffersExpiredByAccountComponentProps) => (
      <ApolloReactComponents.Query<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables> query={GetAllCollateralOffersExpiredByAccountDocument} {...props} />
    );
    

/**
 * __useGetAllCollateralOffersExpiredByAccountQuery__
 *
 * To run a query within a React component, call `useGetAllCollateralOffersExpiredByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollateralOffersExpiredByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollateralOffersExpiredByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAllCollateralOffersExpiredByAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>(GetAllCollateralOffersExpiredByAccountDocument, options);
      }
export function useGetAllCollateralOffersExpiredByAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>(GetAllCollateralOffersExpiredByAccountDocument, options);
        }
export type GetAllCollateralOffersExpiredByAccountQueryHookResult = ReturnType<typeof useGetAllCollateralOffersExpiredByAccountQuery>;
export type GetAllCollateralOffersExpiredByAccountLazyQueryHookResult = ReturnType<typeof useGetAllCollateralOffersExpiredByAccountLazyQuery>;
export type GetAllCollateralOffersExpiredByAccountQueryResult = Apollo.QueryResult<GetAllCollateralOffersExpiredByAccountQuery, GetAllCollateralOffersExpiredByAccountQueryVariables>;