import { ADMIN_API_URL, BACKEND_URL } from 'config';
import axios from 'axios';
import Pool from 'auth/userpool';
import { CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';

const backend = axios.create({
    baseURL: BACKEND_URL,
    headers: {
        'content-type': 'application/json',
    },
});

backend.interceptors.response.use(
    (response) => response,
    (err) => {
        const error = err.response;
        if (error?.status === 401 && !error.config._retry) {
            error.config._retry = true;
            const RefreshToken = localStorage.getItem('refreshToken');
            const currentUser = Pool.getCurrentUser();
            backend.interceptors.request.eject(0);
            if (RefreshToken && currentUser) {
                const refreshToken = new CognitoRefreshToken({ RefreshToken });
                return currentUser.refreshSession(refreshToken, (err, newSession: CognitoUserSession) => {
                    const jwtToken = newSession?.getIdToken().getJwtToken();
                    if (error && error.config.headers && jwtToken) {
                        localStorage.setItem('refreshToken', newSession?.getRefreshToken().getToken() ?? '');
                        backend.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
                        return backend(error.config);
                    }
                });
            }
        }
        let errorMessage = `[Error] Unknown error, status code: ${error.status ?? 0}`;
        try {
            const errJson = error.data;
            if (errJson && errJson.message) {
                errorMessage = errJson.message;
            }
        } catch (err) {
            console.log('Failed to decode error json response');
        }
        return Promise.reject(errorMessage);
    }
);

export { backend };
export interface BaseResponse<T> {
    success: boolean;
    message: string;
    data: T;
}
export interface Pagination {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

export const adminApi = axios.create({
    baseURL: ADMIN_API_URL,
    headers: {
        'content-type': 'application/json',
    },
});
