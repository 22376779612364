import TorusSdk from '@toruslabs/torus-direct-web-sdk';
import { AxiosRequestConfig } from 'axios';
import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserSession,
    UserData,
} from 'amazon-cognito-identity-js';
import { useSignTokenMutation } from 'queries/user';
import { web3 } from 'queries/web3';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { Account } from 'web3-core';
import Pool from './userpool';
import { backend } from 'api';

interface Auth {
    authenticate?: UseMutationResult<unknown, Error, { username: string; password: string }>;
    session?: CognitoUserSession;
    logout?: () => void;
    wallet?: Account;
    isAuthenticated?: boolean;
    currentUser?: CognitoUser | null;
    currentUserData?: UserData;
}

const AuthContext = createContext<Auth>({});
export const useAuth = (): Auth => useContext(AuthContext);

export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const signTokenMutation = useSignTokenMutation();
    const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
    const [currentUserData, setCurrentUserData] = useState<UserData | undefined>(undefined);
    const getSession = async () =>
        await new Promise<CognitoUserSession>((resolve, reject) => {
            const user = Pool.getCurrentUser();
            setCurrentUser(user);
            if (user) {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                        reject();
                    } else {
                        const RefreshToken = session?.getRefreshToken().getToken();

                        if (RefreshToken) {
                            const refreshToken = new CognitoRefreshToken({ RefreshToken });
                            user.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
                                resolve(session);
                            });
                            localStorage.setItem('refreshToken', RefreshToken);
                        }
                    }
                });
            } else {
                reject();
            }
        });

    const [session, setSession] = useState<CognitoUserSession | undefined>();
    useEffect(() => {
        getSession().then(setSession);
    }, []);
    useEffect(() => {
        if (currentUser) {
            currentUser.getUserData((err, userData) => {
                setCurrentUserData(userData);
            });
        } else {
            setCurrentUserData(undefined);
        }
    }, [currentUser]);
    const logout = async () => {
        const user = Pool.getCurrentUser();
        console.log(user);
        user?.signOut();
        setSession(undefined);
    };

    const authenticate = useMutation<CognitoUserSession, Error, { username: string; password: string }>(
        ({ username: Username, password: Password }) =>
            new Promise((resolve, reject) => {
                const user = new CognitoUser({
                    Username,
                    Pool,
                });

                const authDetails = new AuthenticationDetails({
                    Username,
                    Password,
                });

                user.authenticateUser(authDetails, {
                    onSuccess: (data) => {
                        console.log('onSuccess:', data);
                        setSession(data);
                        const RefreshToken = session?.getRefreshToken().getToken();
                        localStorage.setItem('refreshToken', RefreshToken ?? '');
                        setCurrentUser(user);
                        resolve(data);
                    },
                    onFailure: (err) => {
                        console.error('onFailure:', err);
                        reject(err);
                    },
                    newPasswordRequired: (data) => {
                        console.log('newPasswordRequired:', data);
                        resolve(data);
                    },
                });
            })
    );

    const isAuthenticated = !!session;
    const [wallet, setWallet] = useState<Account | undefined>();

    useEffect(() => {
        if (!session) {
            setWallet(undefined);
            return;
        }
        async function initTorus() {
            if (!session) return;
            try {
                const torusSdk = new TorusSdk({
                    baseUrl: `${window.location.origin}/`,
                    enableLogging: true,
                    network: 'testnet', // details for test net
                });

                await torusSdk.init({ skipSw: false });

                torusSdk
                    .getTorusKey(
                        'gold-fever-cognito-testnet',
                        session.getAccessToken().payload.username,
                        { verifier_id: session.getAccessToken().payload.username },
                        session.getAccessToken().getJwtToken()
                    )
                    .then(async function (res) {
                        const acc = web3.eth.accounts.privateKeyToAccount(res.privateKey);
                        const signToken = web3.eth.accounts.sign(session.getIdToken().getJwtToken(), res.privateKey);
                        signTokenMutation.mutate({ signature: signToken.signature });
                        setWallet(acc);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            } catch (error) {
                console.error(error, 'mounted caught');
            }
        }

        initTorus();
    }, [session, signTokenMutation]);

    const setTokenToAxios = (session: CognitoUserSession): number => {
        return backend.interceptors.request.use(
            (config: AxiosRequestConfig<string>) => {
                const jwtToken = session.getIdToken().getJwtToken();
                if (config && config.headers) config.headers['Authorization'] = `Bearer ${jwtToken}`;
                return config;
            },
            (error: string) => {
                throw error;
            }
        );
    };

    useEffect(() => {
        if (!session) {
            return;
        }
        let authInterceptor: number;
        if (session.isValid()) {
            authInterceptor = setTokenToAxios(session);
        } else {
            const RefreshToken = session?.getRefreshToken().getToken();
            if (RefreshToken && currentUser) {
                const refreshToken = new CognitoRefreshToken({ RefreshToken });
                currentUser.refreshSession(refreshToken, (err, newSession: CognitoUserSession) => {
                    authInterceptor = setTokenToAxios(newSession);
                });
            }
        }

        return () => {
            backend.interceptors.request.eject(authInterceptor);
        };
    }, [session]);
    return (
        <AuthContext.Provider
            value={{ authenticate, session, logout, wallet, isAuthenticated, currentUser, currentUserData }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
