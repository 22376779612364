import { backend, BaseResponse } from 'api';
import { useMutation, UseMutationResult, UseQueryResult, useQuery } from 'react-query';
import { toast } from 'react-toastify';

export const useCreateOrderMutation = (): UseMutationResult<
    { data: { id: string } },
    string,
    CreateOrderVariables,
    unknown
> => {
    return useMutation(
        async ({ items }: CreateOrderVariables) => {
            const res = await backend.post('/juls/orders', { items });
            return res.data;
        },
        {
            onError: (error) => {
                toast.error(error);
            },

            onSuccess: (data) => {
                toast.success(data.message);
            },
        }
    );
};

type CreateOrderVariables = {
    items: {
        julPackageId: string | undefined;
        qty: number;
    }[];
};

export const useCheckoutOrderMutation = (): UseMutationResult<
    { data: { qrCodeUrl: string; checkoutUrl: string } },
    string,
    CheckoutPaymentVariables,
    unknown
> => {
    return useMutation(
        async ({ id, unit }: CheckoutPaymentVariables) => {
            const res = await backend.post(`/juls/orders/${id}/checkout`, {
                currency: unit,
            });
            return res.data;
        },
        {
            onError: (error) => {
                toast.error(error);
            },

            onSuccess: (data) => {
                toast.success(data.message);
            },
        }
    );
};

type CheckoutPaymentVariables = {
    id: number | string;
    unit: number | string;
};

export interface OrderEntity {
    id: string;
    createdAt: string;
    updatedAt: string;
    userName: string;
    status: string;
    items: {
        identifier: string;
        createdAt: string;
        orderId: string;
        qty: number;
        id: string;
        itemTypeId: string;
        attempt: number;
        status: string;
        updatedAt: string;
    }[];
    amountJul: string;
    amountNgl: string;
    email: string;
    originalAmountJul: string;
    discountAmountJul: string;
}

export const useGetStoreOrderHistory = (): UseQueryResult<OrderEntity[]> => {
    return useQuery(
        'storeOrder',
        async () => {
            const res = await backend.get('/store/orders');
            return res.data.data;
        },
        {
            onSuccess: (res) => console.log(res),
            onError: (error) => console.log(error),
        }
    );
};
