import { useQuery, UseQueryResult, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { backend, adminApi, BaseResponse, Pagination } from 'api';
import { AxiosResponse } from 'axios';

export interface StoreItemType {
    id: string;
    level?: string;
    name: string;
    description?: string;
    image?: string;
    video?: string;
    julPrice: number;
    nglPrice: number;
    data?: any;
    created_at?: string;
    updated_at?: string;
    categoryId?: number;
}

interface ResponseGetItems {
    data: StoreItemType[];
    pagination: Pagination;
}
interface GetStoreItemsParams {
    pageSize?: number;
    _sort?: string;
    isPurchasable?: boolean;
    categoryId?: number | number[];
}
export const useGetStoreItemsQuery = (
    params?: GetStoreItemsParams
): UseInfiniteQueryResult<ResponseGetItems, unknown> => {
    return useInfiniteQuery(
        'storeItems',
        async ({ pageParam = 1 }) => {
            const result = await adminApi.get(`/gold-fever/nft-types`, {
                params: { ...params, page: pageParam },
            });
            return result.data;
        },
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.pagination.page < lastPage.pagination.pageCount) {
                    return lastPage.pagination.page + 1;
                }
                return undefined;
            },
        }
    );
};

export const useGetStoreAddressQuery = (): UseQueryResult<{ data: BaseResponse<string> }, unknown> => {
    return useQuery('storeAddress', async () => backend.get('/store/walletAddress'), {
        onSuccess: (res) => console.log(res),
        onError: (error) => console.log(error),
    });
};

export const useGetItemQuantity = (itemTypeId: string): UseQueryResult<{ data: BaseResponse<number> }, unknown> => {
    return useQuery(
        ['itemQuantity', itemTypeId],
        async () => backend.get(`/store/items/countAvailable?itemTypeId=${itemTypeId}`),
        {
            onSuccess: (res) => console.log(res),
            onError: (error) => console.log(error),
        }
    );
};
