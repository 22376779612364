import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CartProvider } from 'cart/CartContext';
import { SUBGRAPH_URL } from 'config';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './auth/account';
import './scss/style.scss';
import Loading from 'components/loading';
import { RightSharedDataProvider } from 'hooks/useRightSharedData';
import ChainProvider from 'hooks/ChainContext';
import ErrorBoundary from 'components/errorBoundary';
const history = createBrowserHistory();

// Containers
const TheLayout = lazy(() => import('./containers/TheLayout'));

// Pages
const Login = lazy(() => import('./views/pages/login/Login'));
const Register = lazy(() => import('./views/pages/register/Register'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));
const Page500 = lazy(() => import('./views/pages/page500/Page500'));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5000,
        },
    },
});

const client = new ApolloClient({
    uri: SUBGRAPH_URL,
    // uri: 'https://api.thegraph.com/subgraphs/name/cinoss/gold-fever-mumbai',
    cache: new InMemoryCache(),
});

const App: React.FC = () => {
    return (
        <Router history={history}>
            <ApolloProvider client={client}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <Suspense fallback={Loading}>
                            <Switch>
                                <Route exact path="/login" render={(props) => <Login {...props} />} />
                                <Route exact path="/register" render={(props) => <Register {...props} />} />
                                <Route exact path="/404" render={(props) => <Page404 {...props} />} />
                                <Route exact path="/500" render={(props) => <Page500 {...props} />} />
                                <RightSharedDataProvider>
                                    <CartProvider>
                                        <ChainProvider>
                                            <Route
                                                path="/"
                                                render={(props) => (
                                                    <ErrorBoundary>
                                                        <TheLayout {...props} />
                                                    </ErrorBoundary>
                                                )}
                                            />
                                        </ChainProvider>
                                    </CartProvider>
                                </RightSharedDataProvider>
                            </Switch>
                            <ToastContainer autoClose={3000} />
                        </Suspense>
                    </AuthProvider>
                </QueryClientProvider>
            </ApolloProvider>
        </Router>
    );
};

export default App;
