import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { StoreItemType, useGetStoreAddressQuery } from 'queries/storeItem';
import { NftsOfOwner } from 'views/sell/sellItemTable';
import { useGetStoreOrderHistory, OrderEntity } from 'queries/orders';
import {
    checkItemTypeLimit,
    variantCompanion,
    DEFAULT_MERCHANT_RIGHT_LIMIT,
    boatAndPlaneIds,
    companionIds,
    BUILDING_BASE,
} from '../constants';
import useRightSharedData from 'hooks/useRightSharedData';
import MerchantPopup from 'views/rights/MerchantPopup';
import { useAuth } from 'auth/account';
import {
    LeaseStatus,
    ListingStatus,
    useGetAccountLeasesQuery,
    useGetAccountListingsQuery,
    useGetRightMerchantLimitQuery,
} from 'generated/graphql';

export type CartItemType = StoreItemType & {
    quantity: number;
    identifier?: string;
};

export interface OnAddCartParams {
    id: string;
    name: string;
    nglPrice: number;
    julPrice: number;
    identifier?: string;
}

export interface BidItem {
    id: string;
    nft: NftsOfOwner;
    endDate: number;
    highestPrice: string;
    biddingStep: number;
    isJul: boolean;
}
interface Cart {
    cartList?: CartItemType[];
    onUpdateQuantity?: (id: string, quantity: number) => void;
    onAddCart?: (item: OnAddCartParams) => void;
    storeAddress?: string;
    checkoutSuccess?: () => void;
    bidItem?: BidItem;
    setBidItem?: React.Dispatch<React.SetStateAction<BidItem | undefined>>;
    openAuctionModal?: boolean;
    setOpenAuctionModal?: React.Dispatch<React.SetStateAction<boolean>>;
    isAbleToPurchase?: (typeId: string, quantity: number, checkCart?: boolean) => boolean;
    isAbleToSell?: (typeId: string, quantity: number) => boolean;
    isAbleToLease?: (typeId: string, quantity: number) => boolean;
    setIsLimitPurchase?: React.Dispatch<React.SetStateAction<boolean>>;
    showCartInfo?: boolean;
    setShowCartInfo?: React.Dispatch<React.SetStateAction<boolean>>;
}

const countItemPurchase = (data: OrderEntity[], typeId: string, slice: number) => {
    const itemPaid = data.filter((item) => item.status === 'paid');
    let totalCount = 0;
    itemPaid.forEach((order) => {
        const count = order.items.filter((item) => item.itemTypeId.slice(0, slice) === typeId.slice(0, slice));
        totalCount = totalCount + count.length;
    });
    return totalCount;
};

const countTypeItemPurchase = (data: OrderEntity[], typeId: string[]) => {
    const itemPaid = data.filter((item) => item.status === 'paid');
    let totalCount = 0;
    itemPaid.forEach((order) => {
        const count = order.items.filter((item) => typeId.includes(item.itemTypeId.slice(0, 2)));
        totalCount = totalCount + count.length;
    });
    return totalCount;
};

const countVariantBuildingItemPurchase = (data: OrderEntity[], typeId: string) => {
    const itemPaid = data.filter((item) => item.status === 'paid');
    let totalCount = 0;
    itemPaid.forEach((order) => {
        const count = order.items.filter((item) => item.itemTypeId.slice(0, 4) === typeId.slice(0, 4));
        totalCount = totalCount + count.length;
    });
    return totalCount;
};

const countBaseBuildingItemPurchase = (data: OrderEntity[], typeId: string) => {
    const itemPaid = data.filter((item) => item.status === 'paid');
    let totalCount = 0;
    itemPaid.forEach((order) => {
        const count = order.items.filter((item) => BUILDING_BASE[item.itemTypeId] === BUILDING_BASE[typeId]);
        totalCount = totalCount + count.length;
    });
    return totalCount;
};

const CartContext = createContext<Cart>({});
export const useCart = (): Cart => useContext(CartContext);

export const CartProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const { wallet } = useAuth();
    const { data: listingsData } = useGetAccountListingsQuery({
        variables: { accountId: wallet?.address.toLowerCase() ?? '', status: ListingStatus.Created },
        pollInterval: 3000,
    });
    const { data: leasesData } = useGetAccountLeasesQuery({
        variables: { accountId: wallet?.address.toLowerCase() ?? '', status: LeaseStatus.Created },
        pollInterval: 3000,
    });

    const { currentMerchantFirstTier, currentMerchantSecondTier, rightData } = useRightSharedData();
    const [storeAddress, setStoreAddress] = useState('');
    const [bidItem, setBidItem] = useState<BidItem>();
    const [openAuctionModal, setOpenAuctionModal] = useState(false);
    const [showCartInfo, setShowCartInfo] = useState(false);
    const { data } = useGetStoreAddressQuery();
    const [isLimitPurchase, setIsLimitPurchase] = useState(false);

    const { data: merchantLimits } = useGetRightMerchantLimitQuery();
    const merchantLimitsData = useMemo(() => {
        if (!merchantLimits?.merchantLimits) {
            return [];
        }
        return (merchantLimits?.merchantLimits ?? []).map((item) => {
            return {
                id: item.id,
                rightId: String(item.rightId),
                companion: Number(item.companion),
                donkey: Number(item.donkey),
                horse: Number(item.horse),
                dog: Number(item.dog),
                boatAndPlane: Number(item.boatAndPlane),
                boat: Number(item.boat),
                plane: Number(item.plane),
                building: Number(item.building),
                similarBuilding: Number(item.similarBuilding),
                sameBaseBuilding: Number(item.sameBaseBuilding),
            };
        });
    }, [merchantLimits]);
    console.log(merchantLimitsData);

    const { data: orderList } = useGetStoreOrderHistory();
    const isAbleToSell = (typeId: string, quantity: number) => {
        if (!listingsData) {
            return false;
        }
        const type = checkItemTypeLimit(typeId);
        const firstLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantFirstTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;
        const secondLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantSecondTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;

        switch (type) {
            case 'companion': {
                const name = variantCompanion(typeId);

                const variantLimit = firstLimitData[name as keyof typeof firstLimitData];
                const typeLimit = secondLimitData?.companion;
                const itemTypeListCount =
                    (listingsData?.account?.listings ?? []).filter((item) =>
                        companionIds.includes(item.token.itemTypeId.slice(0, 2))
                    ).length ?? 0;
                const itemListCount =
                    (listingsData?.account?.listings ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 4) === typeId.slice(0, 4)
                    ).length ?? 0;

                if (typeLimit && quantity + itemTypeListCount <= typeLimit) {
                    return true;
                }
                if (currentMerchantSecondTier.id === '0' && quantity + itemListCount <= variantLimit) {
                    return true;
                }
                return false;
            }
            case 'building':
                const quantityLimit = firstLimitData.building;
                const itemListCount =
                    (listingsData?.account?.listings ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 2) === typeId.slice(0, 2)
                    ).length ?? 0;

                if (itemListCount + quantity <= quantityLimit) {
                    return true;
                }

                return false;

            case 'plane':
            case 'boat': {
                const variantLimit = firstLimitData[type];
                const typeLimit = secondLimitData.boatAndPlane;
                const itemTypeListCount =
                    (listingsData?.account?.listings ?? []).filter((item) =>
                        boatAndPlaneIds.includes(item.token.itemTypeId.slice(0, 2))
                    ).length ?? 0;
                const itemListCount =
                    (listingsData?.account?.listings ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 2) === typeId.slice(0, 2)
                    ).length ?? 0;

                if (typeLimit && quantity + itemTypeListCount <= typeLimit) {
                    return true;
                }
                if (currentMerchantSecondTier.id === '0' && quantity + itemListCount <= variantLimit) {
                    return true;
                }
                return false;
            }

            default:
                return true;
        }
    };

    const isAbleToLease = (typeId: string, quantity: number) => {
        if (!leasesData) {
            return false;
        }
        const type = checkItemTypeLimit(typeId);
        const firstLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantFirstTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;
        const secondLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantSecondTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;

        switch (type) {
            case 'companion': {
                const name = variantCompanion(typeId);
                const variantLimit = firstLimitData[name as keyof typeof firstLimitData];
                const typeLimit = secondLimitData.companion;
                const itemTypeListCount =
                    (leasesData?.account?.leases ?? []).filter((item) =>
                        companionIds.includes(item.token.itemTypeId.slice(0, 2))
                    ).length ?? 0;
                const itemListCount =
                    (leasesData?.account?.leases ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 4) === typeId.slice(0, 4)
                    ).length ?? 0;

                if (typeLimit && quantity + itemTypeListCount <= typeLimit) {
                    return true;
                }
                if (currentMerchantSecondTier.id === '0' && quantity + itemListCount <= variantLimit) {
                    return true;
                }
                return false;
            }
            case 'building':
                const quantityLimit = firstLimitData.building;
                const itemListCount =
                    (leasesData?.account?.leases ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 2) === typeId.slice(0, 2)
                    ).length ?? 0;

                if (itemListCount + quantity <= quantityLimit) {
                    return true;
                }

                return false;

            case 'plane':
            case 'boat': {
                const variantLimit = firstLimitData[type];
                const typeLimit = secondLimitData.boatAndPlane;
                const itemTypeListCount =
                    (leasesData?.account?.leases ?? []).filter((item) =>
                        boatAndPlaneIds.includes(item.token.itemTypeId.slice(0, 2))
                    ).length ?? 0;
                const itemListCount =
                    (leasesData?.account?.leases ?? []).filter(
                        (item) => item.token.itemTypeId.slice(0, 2) === typeId.slice(0, 2)
                    ).length ?? 0;
                if (typeLimit && quantity + itemTypeListCount <= typeLimit) {
                    return true;
                }
                if (currentMerchantSecondTier.id === '0' && quantity + itemListCount <= variantLimit) {
                    return true;
                }
                return false;
            }

            default:
                return true;
        }
    };

    const isAbleToPurchase = (typeId: string, quantity: number, checkCart = true) => {
        if (!orderList) {
            return false;
        }

        const type = checkItemTypeLimit(typeId);
        const firstLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantFirstTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;
        const secondLimitData =
            merchantLimitsData.find((item) => item.rightId === currentMerchantSecondTier.id) ??
            DEFAULT_MERCHANT_RIGHT_LIMIT;
        const cartVariant = cartList.filter((currentItem) => currentItem.id !== typeId);

        switch (type) {
            case 'companion': {
                const name = variantCompanion(typeId);
                const variantLimit = firstLimitData[name as keyof typeof firstLimitData];
                const itemPurchaseCount = countItemPurchase(orderList, typeId, 4);
                const itemTypePurchaseCount = countTypeItemPurchase(orderList, companionIds);
                const cartType = cartList
                    .filter((item) => companionIds.includes(item.id.slice(0, 2)))
                    .filter((currentItem) => currentItem.id !== typeId);
                const cartTypeQuantity = checkCart ? R.reduce((acc, next) => acc + next.quantity, 0, cartType) : 0;

                const typeLimit = secondLimitData.companion;
                const cartVariantQuantity =
                    R.reduce(
                        (acc, next) => acc + next.quantity,
                        0,
                        cartVariant.filter((item) => item.id.slice(0, 4) === typeId.slice(0, 4))
                    ) ?? 0;

                if (typeLimit && quantity + itemTypePurchaseCount + cartTypeQuantity <= typeLimit) {
                    return true;
                }
                if (
                    currentMerchantSecondTier.id === '0' &&
                    quantity + itemPurchaseCount + cartVariantQuantity <= variantLimit
                ) {
                    return true;
                }
                return false;
            }
            case 'building':
                const itemPurchaseCount = countItemPurchase(orderList, typeId, 2);
                const quantityLimit = firstLimitData.building;
                const baseLimit = secondLimitData?.sameBaseBuilding;
                const variantLimit = secondLimitData?.similarBuilding;
                const variantPurchaseQuantity = countVariantBuildingItemPurchase(orderList, typeId);

                const temp = cartList.filter((currentItem) => currentItem.id !== typeId);
                const cartType = temp.filter((item) => item.id.slice(0, 4) === typeId.slice(0, 4));
                const cartTypeQuantity = checkCart ? R.reduce((acc, next) => acc + next.quantity, 0, cartType) : 0;

                const basePurchaseQuantity = countBaseBuildingItemPurchase(orderList, typeId);

                const baseCart = (temp ?? []).filter((item) => BUILDING_BASE[item.id] === BUILDING_BASE[typeId]);
                const baseCartQuantity = checkCart ? R.reduce((acc, next) => acc + next.quantity, 0, baseCart) : 0;
                const cartVariantQuantity =
                    R.reduce(
                        (acc, next) => acc + next.quantity,
                        0,
                        cartVariant.filter((item) => item.id.slice(0, 2) === typeId.slice(0, 2))
                    ) ?? 0;

                if (basePurchaseQuantity + baseCartQuantity + quantity > baseLimit) {
                    return false;
                }
                if (variantPurchaseQuantity + cartTypeQuantity + quantity > variantLimit) {
                    return false;
                }
                if (itemPurchaseCount + quantity + cartVariantQuantity <= quantityLimit) {
                    return true;
                }

                return false;

            case 'plane':
            case 'boat': {
                const itemPurchaseCount = countItemPurchase(orderList, typeId, 2);

                const variantLimit = firstLimitData[type as keyof typeof firstLimitData];
                const itemTypePurchaseCount = countTypeItemPurchase(orderList, boatAndPlaneIds);
                const cartType = cartList
                    .filter((item) => boatAndPlaneIds.includes(item.id.slice(0, 2)))
                    .filter((currentItem) => currentItem.id !== typeId);
                const cartTypeQuantity = checkCart ? R.reduce((acc, next) => acc + next.quantity, 0, cartType) : 0;

                const typeLimit = secondLimitData.boatAndPlane;
                const cartVariantQuantity =
                    R.reduce(
                        (acc, next) => acc + next.quantity,
                        0,
                        cartVariant.filter((item) => item.id.slice(0, 2) === typeId.slice(0, 2))
                    ) ?? 0;

                if (typeLimit && quantity + itemTypePurchaseCount + cartTypeQuantity <= typeLimit) {
                    return true;
                }
                if (
                    currentMerchantSecondTier.id === '0' &&
                    quantity + itemPurchaseCount + cartVariantQuantity <= variantLimit
                ) {
                    return true;
                }
                return false;
            }

            default:
                return true;
        }
    };
    if (data?.data.success && !storeAddress) {
        setStoreAddress(data.data.data);
    }
    const [cartList, setCartList] = useState<CartItemType[]>([]);

    const onUpdateQuantity = (id: string, quantity: number) => {
        const index = R.findIndex(R.propEq('id', id))(cartList);
        const cartListTemp = [...cartList];
        if (quantity !== 0) {
            if (isAbleToPurchase(id, quantity)) {
                cartListTemp[index].quantity = quantity;
            } else {
                setShowCartInfo(false);
                setIsLimitPurchase(true);
            }
        } else {
            cartListTemp.splice(index, 1);
        }
        setCartList(cartListTemp);
        localStorage.setItem('cartList', JSON.stringify(cartListTemp));
    };

    const onAddCart = (item: OnAddCartParams) => {
        const index = R.findIndex(R.propEq('id', item.id))(cartList);
        const cartListTemp = [...cartList];
        if (index === -1) {
            cartListTemp.push({ ...item, quantity: 1 });
            toast.success(`${item.name} has been add to cart!`);
        } else {
            cartListTemp[index].quantity = cartList[index].quantity + 1;
        }
        setCartList(cartListTemp);
        localStorage.setItem('cartList', JSON.stringify(cartListTemp));
    };

    const checkoutSuccess = () => {
        setCartList([]);
        localStorage.removeItem('cartList');
    };
    useEffect(() => {
        const localCartList = localStorage.getItem('cartList');
        if (localCartList) {
            setCartList(JSON.parse(localCartList));
        }
    }, []);

    return (
        <CartContext.Provider
            value={{
                cartList,
                onUpdateQuantity,
                onAddCart,
                storeAddress,
                checkoutSuccess,
                bidItem,
                setBidItem,
                openAuctionModal,
                setOpenAuctionModal,
                isAbleToPurchase,
                isAbleToSell,
                isAbleToLease,
                setIsLimitPurchase,
                showCartInfo,
                setShowCartInfo,
            }}
        >
            <MerchantPopup
                titleMerchant="Alert"
                onCancel={() => setIsLimitPurchase(false)}
                onSubmit={() => setIsLimitPurchase(false)}
                isOpen={isLimitPurchase}
                content={
                    <p>
                        You have reached your maximum ownership capacity for this item. To you increase your ownership
                        capacity, please acquire the proper merchant rights
                    </p>
                }
            />
            {props.children}
        </CartContext.Provider>
    );
};
