import React, { FC, createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useGetRightOptionsQuery, useGetUnexpiredRightPurchasesQuery } from 'generated/graphql';
import {
    RightData,
    RightDataExtended,
    useGetRightsQuery,
    RightCategory,
    usePurchaseRightOptionMutation,
} from 'queries/right';
import { useAuth } from 'auth/account';
import * as R from 'ramda';
import { RIGHT_DISCOUNT_IDS, RIGHT_MERCHANT_FIRST_IDS, RIGHT_MERCHANT_SECOND_IDS } from '../constants';
import { Account } from 'web3-core';

export type RightOptions = Pick<
    RightDataExtended,
    'id' | 'rightId' | 'rightType' | 'price' | 'duration' | 'stakeDuration'
>[];

interface RightSharedDataStateProps {
    myRightDataExtended: RightDataExtended[];
    rightOptions: RightOptions;
    discountPercentage: string | number;
    purchaseRightOption?: (rightOptionId: string | number, price: string | number, addressBuyer: Account) => void;
    currentDiscountTier: {
        id: string;
        type: number;
        expired: number;
        nglPrice: number;
        stakeExpiry: string;
        stakeDuration: string;
    };
    currentMerchantFirstTier: {
        id: string;
        type: number;
        expired: number;
        nglPrice: number;
        stakeExpiry: string;
        stakeDuration: string;
    };
    currentMerchantSecondTier: {
        id: string;
        type: number;
        expired: number;
        nglPrice: number;
        stakeExpiry: string;
        stakeDuration: string;
    };
    rightData: RightData[];
    isLoadingPurchase: boolean;
}
export const rightSharedDataInitialState = {
    myRightDataExtended: [],
    rightOptions: [],
    discountPercentage: 0,
    currentDiscountTier: { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' },
    currentMerchantFirstTier: { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' },
    currentMerchantSecondTier: { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' },
    rightData: [],
    isLoadingPurchase: true,
};

export const RightSharedDataContext = createContext<RightSharedDataStateProps>(rightSharedDataInitialState);

export const RightSharedDataProvider: FC = ({ children }) => {
    const { wallet } = useAuth();
    const [rightData, setRightData] = useState<RightData[]>([]);
    const [rightOptions, setRightOptions] = useState<RightOptions>([]);
    const { mutate: purchaseRightOptionMutation } = usePurchaseRightOptionMutation();

    const { data: rightDataQuery } = useGetRightsQuery({
        pageSize: 100,
        page: 1,
    });
    const { data: unexpiredRightPurchase, loading: isLoadingPurchase } = useGetUnexpiredRightPurchasesQuery({
        variables: { id: wallet?.address.toLowerCase() ?? '' },
        pollInterval: 3000,
    });

    const { data: rightOptionsData, loading } = useGetRightOptionsQuery();

    useEffect(() => {
        if (rightDataQuery) {
            setRightData(rightDataQuery);
        }
    }, [rightDataQuery]);

    useEffect(() => {
        if (rightOptionsData) {
            setRightOptions(
                R.project(
                    ['id', 'rightId', 'rightType', 'price', 'duration', 'stakeDuration'],
                    rightOptionsData.rightOptions
                )
            );
        }
    }, [rightOptionsData]);

    const myRightData = useMemo(
        () =>
            unexpiredRightPurchase?.account?.rightPurchases?.map((rightPurchase) => ({
                rightPurchaseId: rightPurchase.id,
                rightId: rightPurchase.rightOption.rightId,
                rightOptionId: rightPurchase.rightOptionId,
                price: rightPurchase.price,
                status: rightPurchase.status,
                duration: rightPurchase.duration,
                expiry: rightPurchase.expiry,
                rightType: rightPurchase.rightOption.rightType,
                stakeExpiry: rightPurchase.stakeExpiry,
                stakeDuration: rightPurchase.stakeDuration,
            })),
        [unexpiredRightPurchase]
    );

    const myRightDataExtended = useMemo(() => {
        const groupDataByRightId = R.groupBy(R.prop('rightId'), rightData || []);
        if (myRightData) {
            return R.map((i) => R.merge(i, groupDataByRightId[i.rightId][0]), myRightData);
        }
        return [];
    }, [myRightData, rightData]);

    const purchaseRightOption = (rightOptionId: string | number, price: string | number, addressBuyer?: Account) =>
        purchaseRightOptionMutation({
            rightOptionId,
            price,
            addressBuyer,
        });

    const currentDiscountTier = useMemo(() => {
        if (
            unexpiredRightPurchase?.account?.rightPurchases &&
            unexpiredRightPurchase?.account?.rightPurchases.length > 0
        ) {
            const discountOption = [
                ...unexpiredRightPurchase?.account?.rightPurchases.filter((i) =>
                    RIGHT_DISCOUNT_IDS.includes(i.rightOption.rightId)
                ),
            ];
            const sortTier = R.sort((a, b) => {
                return a.rightOption.rightId - b.rightOption.rightId;
            }, discountOption);
            let currentTire = { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
            sortTier.every((item, index) => {
                if (Math.floor(new Date().getTime() / 1000) > Number(item.expiry)) {
                    const temp = sortTier[index - 1];
                    currentTire = {
                        id: temp.rightOption.rightId,
                        type: temp.rightOption.rightType,
                        expired: temp.expiry,
                        nglPrice: temp.price,
                        stakeExpiry: temp.stakeExpiry,
                        stakeDuration: temp.stakeDuration,
                    };
                    return false;
                } else {
                    if (index === sortTier.length - 1) {
                        const temp = sortTier[sortTier.length - 1];

                        currentTire = {
                            id: temp.rightOption.rightId,
                            type: temp.rightOption.rightType,
                            expired: temp.expiry,
                            nglPrice: temp.price,
                            stakeExpiry: temp.stakeExpiry,
                            stakeDuration: temp.stakeDuration,
                        };

                        return false;
                    }
                    return true;
                }
            });
            return currentTire;
        }
        return { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
    }, [unexpiredRightPurchase]);

    const currentMerchantFirstTier: {
        id: string;
        type: number;
        expired: number;
        nglPrice: number;
        stakeExpiry: string;
        stakeDuration: string;
    } = useMemo(() => {
        if (
            unexpiredRightPurchase?.account?.rightPurchases &&
            unexpiredRightPurchase?.account?.rightPurchases.length > 0
        ) {
            const merchantOption = [
                ...unexpiredRightPurchase?.account?.rightPurchases.filter((i) =>
                    RIGHT_MERCHANT_FIRST_IDS.includes(i.rightOption.rightId)
                ),
            ];
            const sortTier = R.sort((a, b) => {
                return a.rightOption.rightId - b.rightOption.rightId;
            }, merchantOption);
            const currentTire = { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
            sortTier.every((item, index) => {
                if (Math.floor(new Date().getTime() / 1000) > Number(item.expiry)) {
                    currentTire.id = sortTier[index - 1]?.rightOption.rightId ?? '0';
                    currentTire.expired = sortTier[index - 1]?.expiry;
                    currentTire.type = sortTier[index - 1]?.rightOption.rightType ?? '0';
                    currentTire.nglPrice = sortTier[index - 1]?.price;
                    currentTire.stakeExpiry = sortTier[index - 1]?.stakeExpiry;
                    currentTire.stakeDuration = sortTier[index - 1]?.stakeDuration;
                    return false;
                } else {
                    if (index === sortTier.length - 1) {
                        currentTire.id = sortTier[sortTier.length - 1].rightOption.rightId;
                        currentTire.expired = sortTier[sortTier.length - 1]?.expiry;
                        currentTire.type = sortTier[sortTier.length - 1]?.rightOption.rightType ?? '0';
                        currentTire.nglPrice = sortTier[sortTier.length - 1]?.price;
                        currentTire.stakeExpiry = sortTier[sortTier.length - 1]?.stakeExpiry;
                        currentTire.stakeDuration = sortTier[sortTier.length - 1]?.stakeDuration;

                        return false;
                    }
                    return true;
                }
            });
            return currentTire;
        }
        return { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
    }, [unexpiredRightPurchase]);

    const currentMerchantSecondTier: {
        id: string;
        type: number;
        expired: number;
        nglPrice: number;
        stakeExpiry: string;
        stakeDuration: string;
    } = useMemo(() => {
        if (
            unexpiredRightPurchase?.account?.rightPurchases &&
            unexpiredRightPurchase?.account?.rightPurchases.length > 0
        ) {
            const merchantOption = [
                ...unexpiredRightPurchase?.account?.rightPurchases.filter((i) =>
                    RIGHT_MERCHANT_SECOND_IDS.includes(i.rightOption.rightId)
                ),
            ];
            const sortTier = R.sort((a, b) => {
                return a.rightOption.rightId - b.rightOption.rightId;
            }, merchantOption);
            const currentTire = { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
            sortTier.every((item, index) => {
                if (Math.floor(new Date().getTime() / 1000) > Number(item.expiry)) {
                    currentTire.id = sortTier[index - 1]?.rightOption.rightId ?? '0';
                    currentTire.expired = sortTier[index - 1]?.expiry;
                    currentTire.type = sortTier[index - 1]?.rightOption.rightType ?? '0';
                    currentTire.nglPrice = sortTier[index - 1]?.price;
                    currentTire.stakeExpiry = sortTier[index - 1]?.stakeExpiry;
                    currentTire.stakeDuration = sortTier[index - 1]?.stakeDuration;
                    return false;
                } else {
                    if (index === sortTier.length - 1) {
                        currentTire.id = sortTier[sortTier.length - 1].rightOption.rightId;
                        currentTire.expired = sortTier[sortTier.length - 1]?.expiry;
                        currentTire.type = sortTier[sortTier.length - 1]?.rightOption.rightType ?? '0';
                        currentTire.nglPrice = sortTier[sortTier.length - 1]?.price;
                        currentTire.stakeExpiry = sortTier[sortTier.length - 1]?.stakeExpiry;
                        currentTire.stakeDuration = sortTier[sortTier.length - 1]?.stakeDuration;
                        return false;
                    }
                    return true;
                }
            });
            return currentTire;
        }
        return { id: '0', type: 0, expired: 0, nglPrice: 0, stakeExpiry: '0', stakeDuration: '0' };
    }, [unexpiredRightPurchase]);

    const discountPercentage = useMemo(() => {
        if (rightData && currentDiscountTier.id !== '0') {
            return rightData
                .filter((item) => item.category === RightCategory.DISCOUNT)
                .filter((i) => Number(i.rightId) === Number(currentDiscountTier.id))[0]?.data.attributes[0].value;
        }
        return 0;
    }, [currentDiscountTier, rightData]);

    return (
        <RightSharedDataContext.Provider
            value={{
                myRightDataExtended,
                rightOptions,
                discountPercentage,
                currentDiscountTier,
                currentMerchantFirstTier,
                currentMerchantSecondTier,
                rightData,
                purchaseRightOption,
                isLoadingPurchase: isLoadingPurchase || loading,
            }}
        >
            {children}
        </RightSharedDataContext.Provider>
    );
};

const useRightSharedData: () => RightSharedDataStateProps = () => useContext(RightSharedDataContext);

export default useRightSharedData;
